%calendar-day-focus {
  border: 6px solid color(brand);
  line-height: 34px;
  margin: -3px 0;
  outline: none;
  position: relative;
  z-index: 1;
  @include zen-respond-to(xs) {
    line-height: 44px;
  }
  @include zen-respond-to(s) {
    line-height: 54px;
  }

  .yellow-black & {
    border-color: color(yellow);
  }
}

/* class from 'templates/blocks/block--views--calendar-block-1.tpl.php' */
.theatre-calendar {
  margin-top: 2em;
  position: relative;
  @include zen-respond-to(m) {
    margin-top: 3em;
  }
  @include zen-respond-to(xl) {
    &::before {
      top: 80px;
      height: calc(100% - 80px);
    }
  }
  @media print {
    display: none;
    visibility: hidden;
  }

  .title-wrapper {
    @include zen-respond-to(xl) {
      margin-top: 80px;
    }
  }

  .view {
    .view-header {
      color: color(black);
    }

    /* date nav wrapper */
    .date-nav {
      @extend %date-nav;
    }

    .date-year {
      @extend %date-year;
    }

    /* eof date nav wrapper */
    /* eof view header */
    /* view content */
    .view-content {
      margin: 1em auto 0;
      overflow: hidden;
      position: relative;
      @include zen-respond-to(m) {
        margin-top: 3em;
        // place for "view-footer" element (daytime navigation)
        padding-bottom: 150px;

        &::after {
          background-color: color(grey-extra-light);
          content: '';
          height: 115px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;

          .yellow-black & {
            background-color: color(text-bg-contrast-yb);
          }
        }
      }
    }

    .calendar-content {
      &__close {
        cursor: pointer;
        display: none;
        position: absolute;
        right: 20px;
        top: 10px;
      }
      @media screen and (max-width: 767px) {
        &.content-loaded {
          background-color: color(white);
          height: 100%;
          left: 0;
          overflow-y: scroll;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 99;

          .yellow-black & {
            background-color: color(text-bg-contrast-yb);
          }

          // close button
          .calendar-content__close {
            appearance: button;
            background: none;
            background-image: url('../images/close.png');
            background-position: top left;
            background-repeat: no-repeat;
            background-size: 100% 120px;
            border: 0;
            color: inherit;
            display: block;
            font: inherit;
            font-size: 0;
            height: 40px;
            overflow: visible;
            padding: 0;
            user-select: none;
            width: 40px;

            .yellow-black & {
              background-position: bottom left;
            }
          }

          .calendar-content__wrapper {
            padding: 40px 20px;
          }
        }
      }
      @include zen-respond-to(m) {
        float: left;
        min-height: 362px;
        width: 50%;
      }

      .message {
        background-color: color(brand);
        color: color(white);
        display: block;
        font-size: em(12);
        font-weight: $font-weight-regular;
        padding: .25em;
        @include zen-respond-to(m) {
          background-color: color(text-bg);
          color: color(black);
          left: 25%;
          position: absolute;
          text-align: center;
          transform: translate(-50%, -50%);
          top: 181px;
        }
        @include zen-respond-to(l) {
          font-size: em(14);
        }
        @include zen-respond-to(xxl) {
          font-size: em(16);
        }

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
          color: color(yellow);
        }
      }

      .spinner {
        left: 50%;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        @include zen-respond-to(m) {
          left: 25%;
          top: 181px;
        }

        .fa-spin {
          color: color(brand);
          display: block;
          margin: 0 auto;

          .yellow-black & {
            color: color(yellow);
          }
        }
      }

      .calendar-day {
        &__header {
          display: none;
        }

        &__result {
          .views-row {
            margin-top: 20px;
            @include zen-respond-to(m) {
              display: none;
              margin-top: 0;

              &.current {
                display: block;
              }
            }
          }

          &.multi-row {
            .views-row {
              @include zen-respond-to(s) {
                float: left;
                width: 50%;

                &.views-row-1,
                &.views-row-3 {
                  .views-row__content {
                    margin-right: 10px;
                  }
                }

                &.views-row-2,
                &.views-row-4 {
                  .views-row__content {
                    margin-left: 10px;
                  }
                }
              }
              @include zen-respond-to(m) {
                float: none;
                width: 100%;

                .views-row__content {
                  margin: 0;
                }
              }
            }
          }

          .spektakl {
            .image__wydarzenie {
              display: none;
            }
          }

          .image {
            display: inline-block;
            max-width: 360px;
            vertical-align: bottom;
            @include zen-respond-to(m) {
              margin-right: 1em;
            }

            a {
              @include block-link-focus(-2px);
            }

            img {
              display: block;
            }
          }

          .links {
            margin-top: 20px;
            @include zen-respond-to(m) {
              display: inline-block;
              vertical-align: bottom;
            }
            @include zen-respond-to(xxxl) {
              margin-top: 0;
            }
          }

          .read-more {
            display: inline-block;
            vertical-align: middle;
            @include zen-respond-to(m) {
              display: block;
            }

            a {
              @include link-button();
              font-size: em(14px);
              font-family: $inter-ui;
              text-transform: uppercase;
              @include zen-respond-to(m) {
                display: block;
              }
            }
          }

          .reservation {
            display: inline-block;
            vertical-align: middle;
            @include zen-respond-to(m) {
              display: block;
              margin-top: 20px;
            }

            a {
              @include link-button(color(white), color(brand), color(brand), color(white));
              font-size: em(14px);
              text-transform: lowercase;
              @include zen-respond-to(m) {
                font-size: em(21px);
                display: block;
              }
            }
          }

          .premiere {
            color: color(brand);
            display: block;
            font-size: .75em;
            line-height: 1;

            .yellow-black & {
              color: color(yellow);
            }
          }

          .title {
            @include fluid-type(24px, 36px);
            margin: .5em 0;
            text-transform: uppercase;
            @include zen-respond-to(m) {
              margin-bottom: 0;
              margin-top: 40px;
              margin-left: 25px;
            }

            &.smaller {
              @include fluid-type(24px, 30px);
            }

            a {
              @include link(color(black));
              @include block-link-focus(0);
              text-decoration: none;

              &:hover {
                span {
                  color: color(brand) !important;

                  .yellow-black & {
                    color: color(yellow) !important;
                  }
                }
              }
            }

            span {
              .yellow-black & {
                color: color(yellow) !important;
              }
            }
          }

          .info-label {
            color: color(brand);
            display: block;
            font-size: .75em;
            line-height: 1;

            .yellow-black & {
              color: color(yellow);
            }
          }

          .info {
            background-color: color(grey-extra-light);
            padding: 10px;
            @include zen-respond-to(m) {
              padding: 0;
              position: absolute;
              bottom: 35px;
              left: 0;
              z-index: 1;
            }

            .yellow-black & {
              background-color: color(text-bg-contrast-yb);
            }

            span {
              display: inline-block;
            }

            .date {
              @include typeface(heading);
              font-size: em(20px);
              @include zen-respond-to(m) {
                font-size: em(30px);
                margin-left: 25px;
              }
            }

            .underscore {
              background-color: color(brand);
              display: inline-block;
              height: 2px;
              width: 20px;
              @include zen-respond-to(m) {
                height: 6px;
                margin: 0 6px;
                width: 50px;
              }

              .yellow-black & {
                background-color: color(yellow);
              }
            }

            .pipe {
              background-color: color(black);
              height: 36px;
              margin: 0 6px -12px;
              width: 1px;
              @include zen-respond-to(m) {
                height: 72px;
                margin: 0 12px -24px;
              }

              .yellow-black & {
                background-color: color(yellow);
              }
            }

            .day {
              font-size: em(18px);
              @include zen-respond-to(m) {
                font-size: em(24px);
              }

              &::first-letter {
                text-transform: uppercase;
              }
            }

            .hour {
              font-size: em(18px);
              font-family: $inter-ui;
              font-weight: $font-weight-regular;
              @include zen-respond-to(m) {
                font-size: em(24px);
              }
            }

            .stage {
              font-size: em(16px);
              display: block;
              font-weight: $font-weight-bold;
              line-height: 1.2;
              margin-top: 10px;
              text-transform: uppercase;
              @include zen-respond-to(m) {
                font-size: em(18px);
                display: inline-block;
                margin-top: 0;
                max-width: 240px;
              }
            }
          }
        }

        &__footer {
          display: none;
          @include zen-respond-to(m) {
            bottom: 0;
            display: block;
            position: absolute;
            right: 0;
            z-index: 1;
          }

          .item-list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              background-color: color(white);
              cursor: pointer;
              float: left;
              height: 115px;
              margin-left: 20px;
              overflow: hidden;
              position: relative;
              width: 115px;

              .yellow-black & {
                border: 1px solid color(yellow);
              }

              &::before {
                background-color: rgba(255, 255, 255, .5);
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;

                .yellow-black & {
                  background-color: color(text-bg-contrast-yb);
                }
              }

              &:hover {
                &::before {
                  background-color: transparent;
                }

                .count {
                  background-color: color(black);
                  color: color(white);
                  padding: 5px;
                }
              }

              &.small {
                margin-left: 12px;
                width: 80px;
                @include respond-to(l) {
                  margin-left: 20px;
                  width: 115px;
                }
              }

              &.xsmall {
                margin-left: 6px;
                width: 30px;
                @include respond-to(l) {
                  margin-left: 12px;
                  width: 60px;
                }
                @include respond-to(xxxl) {
                  margin-left: 12px;
                  width: 80px;
                }
                @include respond-to(xxxxl) {
                  margin-left: 20px;
                  width: 115px;
                }
              }

              &.current {
                background-color: color(brand);
                cursor: default;

                img {
                  opacity: .7;
                }

                .count {
                  background-color: transparent;
                  color: color(white);

                  .yellow-black & {
                    color: color(text-bg-contrast-yb);
                  }
                }

                &::before {
                  background-color: rgba(0, 0, 254, .5);

                  .yellow-black & {
                    background-color: color(yellow);
                  }
                }
              }

              .count {
                @include typeface(heading);
                @include fluid-type(24px, 36px);
                pointer-events: none;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
              }

              .spektakl {
                .image__wydarzenie {
                  display: none;
                }
              }

              img {
                display: block;
                max-width: none;
              }
            }
          }
        }
      }
    }

    .calendar-calendar {
      @include zen-respond-to(m) {
        float: right;
        width: 50%;
      }

      table {
        margin: 0;

        tr {
          border-bottom: 1px solid color(black);

          .yellow-black & {
            border-color: color(yellow-darken);
          }
        }
      }

      thead {
        th {
          font-size: em(16px);
          font-family: $inter-ui;
          font-weight: $font-weight-regular;
          height: 40px;
          text-align: center;
          vertical-align: middle;
          @include zen-respond-to(xs) {
            font-size: em(18px);
            height: 50px;
          }
          @include zen-respond-to(s) {
            font-size: em(22px);
            height: 60px;
          }

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      tbody {
        td {
          font-size: em(18px);
          font-family: $inter-ui;
          font-weight: $font-weight-regular;
          height: 40px;
          text-align: center;
          width: 14.285%;
          vertical-align: middle;
          @include zen-respond-to(xs) {
            font-size: em(24px);
            height: 50px;
          }
          @include zen-respond-to(s) {
            font-size: em(28px);
            height: 60px;
          }

          .yellow-black & {
            color: color(yellow-darken);
          }

          a {
            @include link(color(link), color(link), color(white));
            box-sizing: border-box;
            display: block;
            line-height: 40px;
            text-decoration: none;
            @include zen-respond-to(xs) {
              line-height: 50px;
            }
            @include zen-respond-to(s) {
              line-height: 60px;
            }

            &:hover {
              background-color: color(brand);

              .yellow-black & {
                background-color: color(yellow);
                color: color(text-bg-contrast-yb);
              }
            }

            &:focus-visible {
              background-color: color(brand);
              outline: 2px solid color(brand);
              outline-offset: 0;

              .yellow-black & {
                background-color: color(yellow);
                color: color(text-bg-contrast-yb);
                outline-color: color(yellow);
              }
            }

            &.jquery-ajax-load-open {
              @extend %calendar-day-focus;
            }
          }
        }
      }
    }
    /* eof view content */
  }

  /* ajax progress */
  .ajax-progress .throbber,
  .ajax-progress-throbber .throbber {
    @extend %throbber;
    background-image: none;
    background-color: transparent;
    float: none;
  }

  .ajax-progress,
  .ajax-progress-throbber {
    background-color: rgba(255, 255, 255, .75);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;

    .yellow-black & {
       background-color: rgba(30, 30, 30, .75);
    }
  }
}
