.view-news {
  .view-content {
    border-top: 20px solid color(grey-extra-light);
    display: flex;
    flex-wrap: wrap;
    padding-top: 2em;

    .yellow-black & {
      border-top-color: color(yellow-darken);
    }
  }

  .views-row {
    @include respond-to(s) {
      width: 50%;

      &.views-row-odd {
        .group-content {
          margin-right: 10px;
        }
      }

      &.views-row-even {
        .group-content {
          margin-left: 10px;
        }
      }
    }

    @include respond-to(l) {
      width: 33.3333%;
      &.views-row-1,
      &.views-row-4,
      &.views-row-7,
      &.views-row-10 {
        .group-content {
          margin-right: 32px;
          margin-left: 0;
        }
      }

      &.views-row-2,
      &.views-row-5,
      &.views-row-8,
      &.views-row-11 {
        .group-content {
          margin-left: 16px;
          margin-right: 16px;
        }
      }

      &.views-row-3,
      &.views-row-6,
      &.views-row-9,
      &.views-row-12 {
        .group-content {
          margin-left: 32px;
          margin-right: 0;
        }
      }
    }
  }

  .node {
    padding-bottom: 2em;
  }

  .view-footer {
    margin-top: 2em;
  }

  .pager {
    @extend %pager;
  }

  .current-page {
    @extend %current-page;
  }
}
