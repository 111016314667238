%wcag-block-h2 {
  font-size: em(12px);
  letter-spacing: .1em;
  margin: 0 0 12px;
  text-transform: uppercase;
}

.header {
  height: 160px;
  position: relative;
  @include respond-to(l) {
    height: 180px;
  }

  .block {
    margin: 0;
  }

  // Logo - header region
  .logo {
    position: relative;

    &.sticky {
      @include respond-to(l) {
        background-color: color(white);
        height: 100px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 998;

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
        }

        .header__logo {
          margin: 0;
          width: auto;

          .yellow-black & {
            margin-top: 10px;
          }
        }

        .header__logo-image {
          padding-top: 10px;
          width: 110px;

          .yellow-black & {
            padding-top: 0;
          }
        }

        .js-header-ribbon {
          height: 90px;
          transform: translate(110px, 5px);
          width: 60px;

          @include rtl() {
            transform: translate(-110px, 5px);
          }
        }
      }

      .admin-menu & {
        @media only screen and (min-width: 1025px) {
          top: 28px;
        }
      }
    }
  }

  &__logo {
    @include block-link-focus;
    display: block;
    margin: 0 auto;
    width: 120px;
    @include respond-to(xs) {
      width: 140px;
    }
    @include respond-to(l) {
      float: left;
      margin: 10px 0 0;
    }
    @include respond-to(xl) {
      margin: 35px 0 0 -40px;
      width: 187px;
    }
    @include respond-to(xxxl) {
      margin-left: 0;
    }

    .yellow-black & {
      background-image: url('../logo-yellow.png');
      background-size: cover;
      margin-top: 50px;

      @include respond-to(l) {
        margin-top: 10px;
      }

      @include respond-to(xl) {
        margin-top: 35px;
      }
    }

    .yellow-black.i18n-yi & {
      background-image: url('../logo-jidysz-yellow.png');
    }
  }

  &__logo-image {
    @include transition(padding-top .3s ease, width .3s ease);
    padding-top: 50px;
    height: auto;
    @include respond-to(l) {
      padding-top: 0;
      width: 187px;
    }

    .yellow-black & {
      padding-top: 0;
      visibility: hidden;
    }
  }

  // Adding by 'js' if common_body_class (module) set 'black-white' class
  .js-header-ribbon {
    @include transition(height .3s ease, width .3s ease, transform .3s ease);
    background-image: url('../images/black-ribbon.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 90px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(80px, 50px);
    width: 60px;
    @include respond-to(l) {
      left: 0;
      top: 0;
      transform: translate(160px, 10px);
    }
    @include respond-to(xl) {
      transform: translate(170px, 30px);
    }
    @include respond-to(xxxl) {
      transform: translate(210px, 30px);
    }

    .yellow-black & {
      transform: translate(80px, 0);
      @include respond-to(l) {
        transform: translate(160px, 10px);
      }
      @include respond-to(xl) {
        transform: translate(170px, 30px);
      }
      @include respond-to(xxxl) {
        transform: translate(210px, 30px);
      }
    }

    @include rtl() {
      background-image: url('../images/black-ribbon-rtl.png');
      left: auto;
      right: 0;
      transform: translate(-170px, 50px);
      @include respond-to(l) {
        transform: translate(-160px, 10px);
      }
      @include respond-to(xl) {
        transform: translate(-210px, 30px);
      }
    }
  }

  // Site name
  &__site-name {
    display: none;
    @include respond-to(l) {
      background-image: url('../images/tatr-zydowski-jidysz.png');
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 55px;
      position: absolute;
      right: 50%;
      text-indent: -9999px;
      transform: translateX(50%);
      top: 35px;
      width: 290px;

      .yellow-black & {
        background-image: url('../images/tatr-zydowski-jidysz-yellow.png');
      }
    }
  }

  // Header section first
  .region-header-section-first {
    position: absolute;
    right: 0;
    top: 0;

    @include rtl() {
      left: 0;
    }
  }

  // Language switcher fallback
  .block-language-switcher-fallback {
    display: none;
    @include respond-to(l) {
      display: block;
      float: right;

      @include rtl() {
        float: left;
      }
    }
  }

  // Views exposed form
  .exposed-form-search-results-page {
    display: none;
    @include respond-to(l) {
      display: block;

      @include rtl() {
        display: none;
      }
    }
    @include respond-to(xxl) {
      float: right;
      margin-right: 5px;
    }
    /* /sass/custom/blocks/_exposed-form-search-results-page.scss */
  }

  // Header section second
  /* /sass/custom/regions/_header-section-second.scss */

  // Header section third
  /* /sass/custom/regions/_header-section-third.scss */
}
