// Dependencies.
@import 'components/clearfix/clearfix';

// Tabs
//
// The primary and secondary tabs.
//
// Markup: tabs.twig
//
// .tabs--secondary - Secondary tabs
//
// Style guide: navigation.tabs

// Tabs (stacked)
//
// When the secondary tabs appear directly after the primary tabs, the styling
// is slightly different.
//
// Markup: tabs--stacked.twig
//
// Style guide: navigation.tabs.stacked

$tab-height: 1.25;

.tabs,
%tabs {
  @extend %clearfix;
  @include line-height($tab-height);
  margin: 2em -4px 1em;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  @include zen-respond-to(m) {
    @include margin($layout-swap-height 0 (2 - $tab-height) 0);
    background-image: linear-gradient(to top, color(tabs-border) 1px, transparent 1px);
    padding: 0 2px;
  }

  .yellow-black & {
    background-image: linear-gradient(to top, color(yellow) 1px, transparent 1px);
  }

  @media print {
    display: none;
  }

  &__tab {
    float: left;
    margin: 4px;
    border: 1px solid color(tabs-border);
    // Allow the tabs gradient to appear just below the tab.
    border-bottom-color: transparent;
    // Clip the background colors on tab links.
    overflow: hidden;
    background: color(tab-bg);
    @include zen-respond-to(m) {
      margin: 0 3px;
    }

    .yellow-black & {
      border-color: color(yellow);
    }

    @include rtl() {
      float: right;
    }

    &.is-active {
      @include zen-respond-to(m) {
        // Cover the tabs gradient with the same color as the background.
        border-bottom-color: color(tabs-bg);

        .yellow-black & {
          border-bottom-color: color(text-bg-contrast-yb);
        }
      }
    }
  }

  &__tab-link {
    @include padding(0 1);
    display: block;
    text-decoration: none;
    transition: color .3s, background .3s, border .3s;
    text-shadow: color(tab-text-shadow) 0 1px 0;

    color: color(tab);
    background: color(tab-bg);
    letter-spacing: 1px;

    .yellow-black & {
      background-color: color(text-bg-contrast-yb);
      text-shadow: none;
    }

    &:focus,
    &:hover {
      background: lighten(color(tab-bg), 5%);

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
      }
    }

    &:active,
    &.is-active,
    &--is-active {
      background: color(tabs-bg);
      text-shadow: none;

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
      }
    }
  }

  // Turns off .tabs styling.
  &--off {
    @include margin(0);
    padding: 0;
    background-image: none;
  }

  &--secondary {
    @include margin-top(1);
    @include font-size(s);
    background-image: none;
  }

  // When secondary tabs appear directly after primary tabs.
  & + &--secondary {
    @include margin-top(0);
    background-image: linear-gradient(to top, color(tabs-border) 1px, transparent 1px);
  }

  &--secondary &__tab {
    @include margin((2 - $tab-height)/2 3px);
    border: 0;
    background: transparent;

    @include rtl() {
      float: right;
    }

    &.is-active {
      border-bottom-color: transparent;
    }
  }

  &--secondary &__tab-link {
    border: 1px solid color(tabs-border);
    border-radius: $base-line-height;
    color: color(tab-secondary);
    background: lighten(color(tab-bg), 8%);
    letter-spacing: normal;

    &:focus,
    &:hover {
      color: darken(color(tab-secondary), 20%);
      background: color(tab-bg);
      border-color: darken(color(tab-bg), 26.5%);
    }

    &:active,
    &.is-active,
    &--is-active {
      color: lighten(color(tab-bg), 15%);
      text-shadow: darken(color(tab-secondary), 20%) 0 1px 0;
      background: color(tab-secondary);
      border-color: darken(color(tab-secondary), 40%);
    }
  }
}

//
// Drupal selectors.
//

// Views mis-uses the theme hooks for tabs.
.views-displays .secondary {
  @extend %tabs--off;
}
