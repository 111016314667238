.field-collection-container {
  ul.action-links-field-collection-add {
    clear: both;
    list-style: none;
    margin: 0 0 1em;
    padding: 0;
  }
}

.field-collection-view {
  position: relative;

  &:hover {
    ul.field-collection-view-links {
      display: block;
    }
  }

  ul.field-collection-view-links {
    background-color: #fff;
    border: 1px solid #ccc;
    display: none;
    font-size: .875em;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }
}
