.newsletter-form {
  margin: 0 auto;
  max-width: 600px;

  .yellow-black & {
    background-color: color(yellow);
  }

  iframe {
    border: 0;
    // height: 460px;
    margin: 0;
    overflow: hidden;
    width: 100%;
    // @include respond-to(xs) {
    //   height: 400px;
    // }
    // @include respond-to(s) {
    //   height: 340px;
    // }
  }

  form {
    span {
      @include typeface(heading);
      font-size: em(24px);
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
      @include respond-to(m) {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 10px;
      }
    }

    input {
      border: 2px solid color(brand);
      border-radius: 0;
      display: block;
      height: 45px;
      margin: 10px auto 0;
      @include respond-to(m) {
        display: inline-block;
        margin: 0;
      }

      .yellow-black & {
        border-color: color(yellow);
      }
    }

    input[type='text'] {
      color: color(black);
      padding: 0 10px;

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
        color: color(yellow);
      }
    }

    input[type='submit'] {
      font-size: em(21px);
      background-color: color(brand);
      color: color(white);
      font-weight: $font-weight-bold;
      transition: background-color .3s ease-in-out, color .3s ease-in-out;
      @include respond-to(m) {
        margin-left: 10px;
      }

      .yellow-black & {
        background-color: color(yellow);
        color: color(text-bg-contrast-yb);
      }

      &:hover {
        background-color: color(white);
        color: color(brand);

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
          color: color(yellow);
        }
      }
    }
  }

  p {
    font-size: em(14);
    line-height: 1.333;
    margin-bottom: 0;
    text-align: left;
  }

  a {
    @include link(color(brand), color(brand), color(black));
  }
}

.fmForm {
  .yellow-black {
    background-color: red !important;
  }
}
