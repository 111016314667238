#sliding-popup {
  background-color: color(grey-extra-light);
  display: block;
  margin: 0;
  padding: 1em 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  .yellow-black & {
    background-color: color(text-bg-contrast-yb);
    color: color(text-contrast-yb);
  }
  .popup-content {
    background-color: color(grey-extra-light);
    border: 0 none;
    display: block;
    margin: 0 auto;
    padding: 0;
    .yellow-black & {
      background-color: color(text-bg-contrast-yb);
      color: color(text-contrast-yb);
    }
  }
}

.sliding-popup-bottom {
  position: fixed;
}

.sliding-popup-top {
  position: relative;
}

#popup-buttons {
  margin-top: 1em;
  text-align: center;
  @include respond-to(m) {
    float: right;
    margin-top: 0;
    width: 40%;
    text-align: right;
  }
  button {
    background-color: color(brand);
    border: 1px solid color(grey-light);
    color: color(white);
    cursor: pointer;
    display: inline-block;
    font-weight: $font-weight-bold;
    margin: 0 0 10px;
    padding: .35em .5em .25em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
    .yellow-black & {
      background-color: color(text-bg-contrast-yb);
      border-color: color(text-contrast-yb);
      color: color(text-contrast-yb);
    }
    &:hover {
      background-color: color(white);
      border: 1px solid color(brand);
      color: color(brand);
      .yellow-black & {
        background-color: color(text-contrast-yb);
        border-color: color(text-bg-contrast-yb);
        color: color(text-bg-contrast-yb);
      }
    }
  }
}

#popup-text {
  color: color(black);
  .yellow-black & {
    color: color(text-contrast-yb);
  }
  @include respond-to(m) {
    float: left;
    width: 60%;
  }
  h2,
  p {
    color: color(black);
    display: block;
    margin: 0;
    .yellow-black & {
      color: color(text-contrast-yb);
    }
  }
  button {
    background-color: transparent;
    border: 0;
    color: color(brand);
    cursor: pointer;
    display: inline-block;
    font-weight: $font-weight-bold;
    padding: 0;
    text-decoration: underline;
    .yellow-black & {
      color: color(text-contrast-yb);
    }
  }
}
