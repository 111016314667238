span.ext {
  display: inline-block;
  padding-left: 4px;
  &::after {
    content: '\f08e';
    display: block;
    font-family: $font-awesome;
    font-size: .875em;
  }
}
span.mailto {
  display: inline-block;
  padding-left: 4px;
  &::after {
    content: '\f003';
    display: block;
    font-family: $font-awesome;
    font-size: .875em;
  }
}
