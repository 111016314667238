%group-images {
  .images {
    text-align: center;
  }

  .image {
    display: inline-block;
    position: relative;

    a.colorbox {
      display: block;
      margin: 10px;
      @include respond-to(m) {
        margin-bottom: 25px;
        margin-top: 25px;
      }

      &::before {
        background-image: url('../images/plus.svg');
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        height: 72px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .3s ease-in-out;
        width: 72px;

        .yellow-black & {
          background-image: url('../images/plus-yellow.svg');
        }
      }

      &:hover {
        &::before {
          opacity: 1;
        }

        img {
          opacity: .1;
        }
      }
    }

    img {
      display: block;
      transition: opacity .3s ease-in-out;
      @include respond-to(s) {
        height: 150px;
        width: auto;
      }
    }
  }
}
