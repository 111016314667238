.page-galeria {}

.view-node-gallery-gallery-summaries {

  .glyph {
    @extend %glyph-eye;
  }

  .view-content {
    margin: 2em 0;
    @include respond-to(s) {
      display: flex;
      flex-wrap: wrap;
    }
    .views-row {
      @include respond-to(s) {
        margin-bottom: 2em;
        width: 50%;
        &.views-row-odd {
          .group-content {
            margin-right: 12px;
          }
        }
        &.views-row-even {
          .group-content {
            margin-left: 12px;
          }
        }
      }
      @include respond-to(l) {
        width: 33.3333%;
        &.views-row-1,
        &.views-row-4,
        &.views-row-7,
        &.views-row-10 {
          .group-content {
            margin-right: 16px;
            margin-left: 0;
          }
        }
        &.views-row-2,
        &.views-row-5,
        &.views-row-8,
        &.views-row-11 {
          .group-content {
            margin-left: 8px;
            margin-right: 8px;
          }
        }
        &.views-row-3,
        &.views-row-6,
        &.views-row-9,
        &.views-row-12 {
          .group-content {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }
    }
  }

  .pager {
    @extend %pager;
  }

  .current-page {
    @extend %current-page;
  }
}
