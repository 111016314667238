%date-year {
  border-top: 7px solid color(brand);
  color: color(brand);
  font-family: $inter-ui;
  font-weight: $font-weight-regular;
  text-align: center;

  .yellow-black & {
    border-top-color: color(yellow);
    color: color(yellow);
  }

  span {
    font-size: em(20px);
    display: block;
    margin-top: .5em;
    @include zen-respond-to(xs) {
      font-size: em(24px);
    }
  }
}
