.region-footer {
  @media print {
    display: none;
    visibility: hidden;
  }

  &__content {
    padding-bottom: 2em;
  }

  // Blocks layout
  .block-block,
  .block-views {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    @include zen-respond-to(m) {
      float: left;
      margin-bottom: 0;
      width: 50%;
    }
    @include zen-respond-to(l) {
      width: 33.3333%;
    }

    .block__title {
      border-left: 6px solid color(black);
      font-size: em(24px);
      padding-left: 14px;
      text-transform: uppercase;
      @include rtl() {
        border-left: 0;
        border-right: 6px solid color(black);
        padding-left: 0;
        padding-right: 14px;
      }

      .yellow-black & {
        border-color: color(yellow);
        @include rtl() {
          border-color: color(yellow);
        }
      }
    }
  }

  // Block content
  .block-block {
    .content {
      margin: 0 20px;

      p {
        margin: 1em 0;
      }

      a[href^='mailto:'] {
        @include block-link-focus;
        border: 2px solid color(brand);
        display: inline-block;
        font-weight: $font-weight-regular;
        margin-left: -14px;
        padding: 4px 14px;
        text-decoration: none;
        transition: background-color .3s ease-in-out, border-color .3s ease-in-out, color .3s ease-in-out;

        .yellow-black & {
          border-color: color(yellow);
          color: color(yellow);
        }

        &:hover {
          background-color: color(brand);
          border-color: color(brand);
          color: color(white);

          .yellow-black & {
            background-color: color(yellow);
            border-color: color(yellow);
            color: color(text-bg-contrast-yb);
          }
        }
      }

      a {
        &.get-ticket {
          // color, color-hover, background-color, background-color-hover, border-color, border-color-hover
          @include link-button(color(white), color(brand), color(brand), color(white));
        }
      }
    }
  }

  // Social links
  .social-links {
    border-bottom: 20px solid color(grey-extra-light);
    margin: 0 0 1.5em;

    .yellow-black & {
      border-bottom-color: color(yellow-darken);
    }

    .content {
      padding-bottom: 1em;
      padding-top: 1em;
      @include zen-respond-to(s) {
        padding-bottom: 3em;
        padding-top: 3em;
      }
    }

    .item-list {
      text-align: center;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        margin: 1em 25px;
        height: 60px;
        width: 60px;
      }

      a {
        @include font-size(30px);
        @include block-link-focus();
        background-color: color(black);
        border-radius: 50%;
        color: color(white);
        display: block;
        line-height: 60px;
        height: 60px;
        overflow: hidden;
        width: 60px;
        text-align: center;

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
          border: 2px solid color(yellow);
          color: color(yellow);
        }

        &.portal-250-na-65 {
          background-image: url('../images/250-na-65.png');
          background-repeat: no-repeat;
          background-size: 60px 60px;

          .yellow-black & {
            background-image: url('../images/250-na-65-yellow.png');
          }

          &:hover {
            background-color: color(brand);

            .yellow-black & {
              background-color: color(text-bg-contrast-yb);
            }
          }
        }

        &:hover {
          background-color: color(brand);

          .yellow-black & {
            background-color: color(text-bg-contrast-yb);
          }
        }

        span {
          font-size: 0;
        }

        .fa {
          @include font-size(30px);
          vertical-align: middle;
        }
      }
    }
  }

  // Partners
  .view-partnerzy {
    .view-content {
      h2,
      h3 {
        border-left: 6px solid color(black);
        font-weight: $font-weight-regular;
        padding-left: 14px;
        text-transform: uppercase;

        .yellow-black & {
          border-left-color: color(yellow);
        }

        @include rtl() {
          border-left: 0;
          border-right: 6px solid color(black);
          padding-left: 0;
          padding-right: 14px;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        float: left;
        margin: 0 10px 10px 0;
        @include rtl() {
          float: right;
        }
      }

      a {
        @include block-link-focus(0);
        background-color: color(white);
        display: inline-block;
      }

      img {
        display: block;
      }
    }

    .view-footer {
      .views-row {
        margin: 1.5em 0 0 10px;

        & + .views-row {
          margin-top: .5em;
        }

        p {
          margin: 0;
        }

        a {
          @include block-link-focus(0);
          background-color: color(white);
          display: inline-block;
        }

        img {
          display: block;
          margin: 10px 0;
          max-width: 140px;
        }
      }
    }
  }
}
