.view-repertoire {
  .view-content {
    table {
      @include respond-to(l) {
        display: table;
      }
    }

    thead {
      display: none;
      @include respond-to(m) {
        display: table-header-group;
      }

      tr {
        border-bottom: 1px solid color(black);

        .yellow-black & {
          border-color: color(yellow);
        }

        th {
          font-size: em(14px);
          font-weight: $font-weight-extra-light;
          padding-top: 20px;
          text-align: left;

          &:nth-child(8) {
            @media print {
              display: none;
            }
          }
        }
      }
    }

    tbody {
      display: block;
      @include respond-to(m) {
        display: table-row-group;
      }

      tr {
        border-bottom: 1px solid color(black);
        display: block;
        float: left;
        padding-bottom: 8px;
        padding-top: 8px;
        width: 100%;
        @include respond-to(m) {
          display: table-row;
          float: none;
          padding-bottom: 0;
          padding-top: 0;
        }

        .yellow-black & {
          border-color: color(yellow);
        }
      }

      td {
        display: block;
        float: left;
        @include respond-to(m) {
          display: table-cell;
          float: none;
          height: 90px;
          vertical-align: middle;
        }

        &.date-day {
          width: 160px;
          @include respond-to(l) {
            width: 190px;
          }

          .day-month {
            @include typeface(heading);
            display: inline-block;
            font-size: em(24px);
            @include respond-to(l) {
              font-size: em(30px);
            }

            span {
              display: inline-block;
              line-height: 1;
            }
          }

          .day-of-week {
            font-size: em(20px);
            display: inline-block;
            @include respond-to(l) {
              font-size: em(24px);
            }

            span {
              display: inline-block;
              line-height: 1;
            }
          }

          .underscore {
            background-color: color(brand);
            display: inline-block;
            height: 3px;
            width: 30px;
            @include respond-to(l) {
              height: 4px;
              width: 50px;
            }

            .yellow-black & {
              background-color: color(yellow);
            }
          }
        }

        &.date-hour {
          font-size: em(20px);
          font-family: $inter-ui;
          font-weight: $font-weight-regular;
          @include respond-to(l) {
            font-size: em(24px);
            width: 65px;
          }

          span {
            display: inline-block;
            margin-top: 4px;
            @include respond-to(m) {
              margin-top: 0;
            }
          }
        }

        &.stage {
          font-size: em(18px);
          clear: both;
          font-weight: $font-weight-semi-bold;
          margin-top: 10px;
          @include respond-to(m) {
            clear: none;
            margin-top: 0;
          }
          @include respond-to(l) {
            font-size: em(19px);
            min-width: 200px;
          }

          span {
            display: inline-block;
            @include respond-to(s) {
              padding-top: 7px;
            }
            @include respond-to(l) {
              line-height: 1;
            }
          }
        }

        &.title {
          @include fluid-type(18px, 24px, 320px, 767px);
          @include typeface(heading);
          clear: both;
          line-height: 1.2;
          margin-top: 10px;
          text-transform: uppercase;
          @include respond-to(m) {
            @include fluid-type(18px, 30px, 768px);
            clear: none;
            margin-top: 0;
            padding: 10px;
          }

          p {
            margin: 0;
          }

          .smaller {
            font-size: .85em;
            display: block;

            a {
              display: block;
            }

            span {
              .yellow-black & {
                color: color(yellow) !important;
              }
            }
          }

          a {
            @include link(color(black), color(black), color(brand));
            line-height: 1.25;
            text-decoration: none;

            &:hover {
              span {
                color: color(brand) !important;
              }
            }
          }

          .premiere,
          .info-label {
            display: block;
            font-size: .75em;
          }
        }

        &.reservation {
          clear: both;
          @include respond-to(m) {
            clear: none;
            width: 110px;
          }
          @include respond-to(l) {
            text-align: left;
            width: 160px;
          }

          a.get-ticket {
            @include link-button(color(white), color(brand), color(brand), color(white));
            font-size: em(14px);
            margin-top: 10px;
            @include respond-to(m) {
              display: table-cell;
              font-size: em(16px);
              margin-top: 0;
            }
            @include respond-to(l) {
              min-width: 120px;
            }
          }
          @media print {
            display: none;
          }
        }

        &.pipe {
          padding-left: 5px;
          padding-right: 5px;
          vertical-align: middle;
          width: 11px;
          @include respond-to(l) {
            padding-left: 10px;
            padding-right: 10px;
            width: 21px;
          }

          &.pipe-3 {
            display: none;
            @include respond-to(m) {
              display: table-cell;
            }
          }

          span {
            background-color: color(black);
            display: block;
            height: 24px;
            margin-top: 6px;
            overflow: hidden;
            width: 1px;
            @include respond-to(m) {
              height: 70px;
              margin-top: 0;
            }

            .yellow-black & {
              background-color: color(yellow);
            }
          }
        }

        &.premiere-col {
          clear: both;
          margin-top: 10px;
          @include respond-to(m) {
            clear: none;
            margin-top: 0;
          }
        }

        &.info-label-col {
          clear: both;
          line-height: 1.2;
          margin-top: 10px;
          @include respond-to(m) {
            clear: none;
            margin-top: 0;
            padding: 10px;
            text-align: center;
          }

          span {
            .yellow-black & {
              color: color(yellow) !important;
            }
          }
        }

        .premiere {
          color: color(brand);
          display: inline-block;
          font-size: em(22);
          font-weight: $font-weight-bold;
          text-transform: uppercase;

          .yellow-black & {
            color: color(yellow);
          }
        }

        .info-label {
          color: color(brand);
          display: inline-block;
          font-size: em(22);
          font-weight: $font-weight-bold;
          text-transform: uppercase;

          .yellow-black & {
            color: color(yellow);
          }
        }
      }
    }
  }

  .view-footer {
    @include reset-margin-first-last-element;
    font-weight: $font-weight-medium;
    margin: 2em 0 1.5em;
    @include respond-to(l) {
      font-size: em(18px);
    }
  }
}

// node edit link
.logged-in {
  .view-repertoire {
    tr {
      position: relative;

      &:hover {
        .views-field-edit-node {
          opacity: 1;
        }
      }
    }
  }

  .views-field-edit-node {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

    a {
      @include link-button(color(white), color(brand), color(brand), color(white));
      font-size: em(15);
      padding: .1em .5em .05em;
    }
  }
}
