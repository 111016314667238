// "More" link
//
// Styles the "Read more…" and "Help" links common in Drupal.
//
// Markup: more-link.twig
//
// Style guide: navigation.more-link

.more-link,
%more-link {
  text-align: right;

  @include rtl() {
    text-align: left;
  }
}

//
// Drupal selectors.
//

.more-help-link {
  @extend %more-link;
}
