.page-galeria-plakatu {}

.view-galeria-plakatu {

  .glyph {
    @extend %glyph-eye;
  }

  .view-content {
    margin: 2em 0;
    @include respond-to(s) {
      display: flex;
      flex-wrap: wrap;
    }

    .views-row {
      @include respond-to(s) {
        margin-bottom: 2em;
        width: 50%;

        &.views-row-odd {
          .content {
            margin-right: 12px;
          }
        }

        &.views-row-even {
          .content {
            margin-left: 12px;
          }
        }
      }

      @include respond-to(m) {
        width: 33.3333%;
        &.views-row-1,
        &.views-row-4,
        &.views-row-7,
        &.views-row-10,
        &.views-row-13,
        &.views-row-16,
        &.views-row-19,
        &.views-row-22 {
          .content {
            margin-right: 16px;
            margin-left: 0;
          }
        }

        &.views-row-2,
        &.views-row-5,
        &.views-row-8,
        &.views-row-11,
        &.views-row-14,
        &.views-row-17,
        &.views-row-20,
        &.views-row-23 {
          .content {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        &.views-row-3,
        &.views-row-6,
        &.views-row-9,
        &.views-row-12,
        &.views-row-15,
        &.views-row-18,
        &.views-row-21,
        &.views-row-24 {
          .content {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }

      @include respond-to(l) {
        width: 25%;
        &.views-row-1,
        &.views-row-5,
        &.views-row-9,
        &.views-row-13,
        &.views-row-17,
        &.views-row-21 {
          .content {
            margin-right: 16px;
            margin-left: 0;
          }
        }

        &.views-row-2,
        &.views-row-6,
        &.views-row-10,
        &.views-row-14,
        &.views-row-18,
        &.views-row-22 {
          .content {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        &.views-row-3,
        &.views-row-7,
        &.views-row-11,
        &.views-row-15,
        &.views-row-19,
        &.views-row-23 {
          .content {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        &.views-row-4,
        &.views-row-8,
        &.views-row-12,
        &.views-row-16,
        &.views-row-20,
        &.views-row-24 {
          .content {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }
    }

    .content {
      position: relative;
    }

    .poster {
      position: relative;

      &:hover {
        &::after {
          opacity: 1;

          .yellow-black & {
            opacity: 0;
          }
        }
      }

      a {
        @include block-link-focus(0);

        &:hover,
        &:focus {
          img {
            opacity: .5;

            .yellow-black & {
              opacity: .1;
            }
          }

          & + .glyph {
            opacity: 1;
          }
        }
      }

      img {
        display: block;
        margin: 0 auto;
        transition: opacity .3s ease-in-out;
      }

      .glyph {
        background-image: url('../images/glyph-eye-white.svg');
        background-position: center center;
        background-size: cover;
        height: 111px;
        left: 50%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .3s ease-in-out;
        width: 221px;
        z-index: 1;

        .yellow-black & {
          background-image: url('../images/glyph-eye-yellow.svg');
        }
      }

      &::after {
        background: linear-gradient(rgba(26, 25, 214, .1), rgba(26, 25, 214, .5), rgba(26, 25, 214, 1));
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity .3s ease-in-out;
        width: 100%;
      }
    }

    .title {
      @include fluid-type(19px, 30px);
      text-transform: uppercase;
    }
  }

  .pager {
    @extend %pager;
  }

  .current-page {
    @extend %current-page;
  }
}
