.page-404,
.page-403 {
  .main-content {
    padding-bottom: 4em;
    text-align: center;
  }
  .action-links {
    list-style: none;
    padding: 0;
    @include rtl() {
      padding: 0;
    }
  }
}
