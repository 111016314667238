.block-textsize {
  .content {
    h2 {
      @extend %wcag-block-h2;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        font-weight: $font-weight-bold;

        &.ts_decrease {
          font-size: em(18px);
        }

        &.ts_normal {
          font-size: em(24px);
        }

        &.ts_increase {
          font-size: em(30px);
        }

        a {
          @include link(color(black), color(black));
          text-decoration: none;
        }
      }
    }
  }
}
