.view-frontpage-slider {
  margin: 0 -20px;
  overflow: hidden;
  max-width: 1600px;
  @include zen-respond-to(s) {
    margin-left: auto;
    margin-right: auto;
  }
  @include rtl() {
    margin-bottom: 2em;
    @include zen-respond-to(m) {
      margin-bottom: 3em;
    }
  }
  @media print {
    display: none;
    visibility: hidden;
  }

  .slick {
    position: relative;
    &.unslick {

      &::before {
        @extend %gradient-layer-frontpage-slider;
      }
    }

    &__slider {
      margin: 0 auto;

      .slick-list {
        overflow: visible;
      }
    }
    &__slide {
      position: relative;

      &::before {
        @extend %gradient-layer-frontpage-slider;
      }
    }

    &__arrow {
      display: none;
      @include zen-respond-to(m) {
        display: block;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }

      button {
        background-color: color(brand);
        background-image: url('../images/arrows-slick.png');
        border: 0;
        height: 50px;
        overflow: hidden;
        pointer-events: auto;
        width: 50px;
        text-indent: -9999px;
        @include zen-respond-to(m) {
          background-color: transparent;
        }

        .yellow-black & {
          background-image: url('../images/arrows-slick-yellow.png');
        }

        &.slick-prev {
          background-position: left top;
          float: left;
        }

        &.slick-next {
          background-position: right top;
          float: right;
        }

        &:hover {
          opacity: .65;
        }
      }
    }

    .field-group-div {
      margin: 0;
    }

    .group-content {
      position: relative;

      .group-text {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        @include zen-respond-to(m) {
          bottom: 10px;
        }
        @include zen-respond-to(l) {
          bottom: 20px;
        }
      }

      .title-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        @include zen-respond-to(m) {
          display: inline-block;
          padding-left: 0;
          padding-right: 0;
          text-align: right;
          width: 50%;
        }

        .title {
          @include typeface(heading);
          @include fluid-type(18px, 72px);
          color: color(white);
          margin: 0;
          text-transform: uppercase;

          .yellow-black & {
            color: color(text-contrast-yb);
          }

          @include zen-respond-to(m) {
            border-right: 6px solid color(white);
            padding: 0 30px 0 20px;
            @include rtl() {
              border-right: 0;
            }

            .yellow-black & {
              border-color: color(yellow);
            }
          }
        }
      }

      .text {
        @include fluid-type(14px, 22px);
        color: color(white);
        font-family: $inter-ui;
        padding: 0 20px 10px;
        @include zen-respond-to(m) {
          display: inline-block;
          line-height: 1.1;
          padding: 0;
          text-align: left;
          width: 50%;
        }

        .yellow-black & {
          color: color(text-contrast-yb);
        }

        p {
          margin: 0;
          @include zen-respond-to(m) {
            margin-top: 5px;
            padding-left: 30px;
          }

          & + p {
            @include zen-respond-to(m) {
              margin-top: 10px;
            }
          }
        }

        strong {
          font-weight: $font-weight-bold;
          @include zen-respond-to(s) {
            @include fluid-type(18px, 32px);
          }
        }
      }

      .image {
        margin-top: 10px;
        @include zen-respond-to(m) {
          margin-top: 0;
        }

        img {
          display: block;
        }
      }
    }

    .read-more-link {
      @include coverer;
      @include block-link-focus;
      font-size: 0;
      z-index: 2;
    }
  }
}
