// sass-lint:disable no-misspelled-properties
.region-navigation {
  .block-superfish {
    background-color: color(black);
    margin: -160px -20px 0;
    position: relative;
    z-index: 9;
    @include zen-respond-to(l) {
      background-color: transparent;
      border-top: 7px solid color(black);
      float: right;
      margin: -($layout-swap-height) 0 0;
      width: auto;

      .yellow-black & {
        border-top-color: color(yellow);
      }

      @include rtl() {
        float: left;
      }
    }

    @include zen-respond-to(xl) {
      min-width: calc(50% + 145px); // 145px = tatr-zydowski-jidysz.png width / 2
      width: auto;
    }

    // sticky only screen width >= 1024
    &.sticky {
      @include respond-to(l) {
        background-color: color(white);
        border-top: 0;
        margin: 0;
        min-width: auto;
        position: fixed;
        top: 0;
        z-index: 998;

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
        }
      }

      .admin-menu & {
        @media only screen and (min-width: 1025px) {
          top: 29px;
        }
      }
    }

    // hamburger
    .sf-accordion-toggle {
      background-color: color(black);
      float: left;
      width: 100%;

      a {
        color: color(white);
        display: block;
        float: right;
        height: 40px;
        overflow: hidden;
        position: relative;
        width: 40px;

        .yellow-black & {
          color: color(text-contrast-yb);
        }

        &::after {
          background-color: color(black);
          content: '\f0c9';
          font-family: $font-awesome;
          font-size: 30px;
          height: 40px;
          line-height: 40px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          width: 40px;
        }

        &.sf-expanded {
          &::after {
            content: '\f00d';
            font-size: 24px;
          }
        }

        span {
          display: block;
          text-indent: -9999px;
        }
      }
    }

    .navigation-wcag {
      border-top: 1px solid color(white);
      border-bottom: 1px solid color(white);
      clear: both;
      display: none;
      padding: 20px 15px;

      .yellow-black & {
        border-color: color(yellow);
      }
    }

    .block-language-switcher-fallback {
      display: none;
    }

    .language-switcher-locale-url {
      background-color: color(black);
      padding: 20px;

      li,
      .language-link {
        border: 0;
      }

      a {
        color: color(white);
        font-weight: $font-weight-semi-bold;
        line-height: 1.5;
        padding: 10px 10px 8px;

        .yellow-black & {
          border-color: color(yellow);
          color: color(yellow);
        }

        &.active {
          background-color: color(brand);
          color: color(white);

          .yellow-black & {
            background-color: color(yellow);
            color: color(text-bg-contrast-yb);
          }
        }
      }
    }

    .sf-accordion {
      &.sf-expanded {
        & ~ .navigation-wcag {
          display: flex;
          gap: 40px;
        }

        & ~ .block-language-switcher-fallback {
          display: block;
        }
      }
    }
  }
}
