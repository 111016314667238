.node-type-a11y {
  .page-title {
    @include zen-respond-to(xl) {
      @include zen-new-row(none);
      @include zen-grid-item(14, 3);
    }
  }

  .glyph {
    @extend %glyph-oval;
    clear: both;
  }

  &.yellow-black {
    .glyph {
      @extend %glyph-oval-yellow;
    }
  }
}

.node-a11y {
  @include fluid-type(18px, 20px);

  .field-name-field-attachment {
    margin-top: 2em;

    .field-label {
      @include font-size(xl);
      border-bottom: 4px solid color(black);
      margin: 0;
      text-transform: uppercase;

      .yellow-black & {
        border-bottom-color: color(yellow);
      }
    }

    .field-items {
      list-style: none;
      margin: 4px 0 0;
      padding: 0;
    }

    .field-item {
      @include fluid-type(19px, 24px);
      overflow: hidden;
      padding: 1em 0 1em 1em;
      transition: background-color .3s ease-in-out;
      @include respond-to(m) {
        padding-left: 2em;
      }

      &.odd {
        background-color: color(grey-extra-light);

        .yellow-black & {
          background-color: transparent;
        }
      }

      &:hover {
        background-color: #e6e6e6;

        .yellow-black & {
          background-color: transparent;
        }
      }

      &:focus-within {
        outline: 2px solid color(brand);
        outline-offset: 2px;

        .yellow-black & {
          outline-color: color(yellow);
        }

        .fa {
          color: color(brand);

          .yellow-black & {
            color: color(yellow);
          }
        }
      }
    }

    .file {
      &.active {
        .fa {
          color: color(brand);

          .yellow-black & {
            color: color(yellow);
          }
        }
      }
    }

    .title {
      @include fluid-type(21px, 30px);
      font-weight: $font-weight-semi-bold;
    }

    a {
      @include link(color(black), color(black), color(brand));
      text-decoration: none;

      &:focus-visible {
        outline: none;
      }
    }

    .fa {
      color: color('grey');
      margin-right: .25em;
      margin-top: .15em;
      vertical-align: top;
      transition: color .3s ease-in-out;

      .yellow-black & {
        color: color(yellow);
      }
    }

    span.ext {
      padding-left: 8px;

      &::after {
        font-size: .75em;
        color: color('grey');

        .yellow-black & {
          color: color(yellow);
        }
      }
    }
  }
}
