%throbber {
  -webkit-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 6px solid color(grey-light);
  border-right: 6px solid color(grey-light);
  border-bottom: 6px solid color(grey-light);
  border-top: 6px solid color(brand);
  border-radius: 100%;
  box-sizing: content-box;
  height: 50px;
  left: calc(50% - 31px);
  position: absolute;
  top: calc(50% - 31px);
  width: 50px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
