.view-lista-spektakli {
  &.lista-spektakli {
    & > .glyph {
      @extend %glyph-menojre;
    }

    .view-content {
      @include respond-to(s) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .views-row {
      margin-top: 2em;
      @include respond-to(s) {
        width: 50%;

        &.views-row-odd {
          .group-content {
            margin-right: 12px;
          }
        }

        &.views-row-even {
          .group-content {
            margin-left: 12px;
          }
        }
      }

      @include respond-to(l) {
        margin-top: 3em;
        width: 33.3333%;
        &.views-row-1,
        &.views-row-4,
        &.views-row-7,
        &.views-row-10 {
          .group-content {
            margin-right: 16px;
            margin-left: 0;
          }
        }

        &.views-row-2,
        &.views-row-5,
        &.views-row-8,
        &.views-row-11 {
          .group-content {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        &.views-row-3,
        &.views-row-6,
        &.views-row-9,
        &.views-row-12 {
          .group-content {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }
    }

    .node {
      .group-content {
        position: relative;

        &.active {
          .group-image {
            img {
              opacity: .1;
            }

            .glyph {
              display: block;
            }
          }

          .title {
            color: color(brand);

            .yellow-black & {
              color: color(yellow);
            }
          }

          .read-more-label {
            span {
              background-color: color(brand);
              color: color(white);

              .yellow-black & {
                background-color: color(yellow);
                color: color(text-bg-contrast-yb);
              }
            }
          }
        }
      }

      .group-image {
        position: relative;

        a {
          display: block;
        }

        img {
          display: block;
          transition: opacity .3s ease-in-out;
        }

        .glyph {
          background-image: url('../images/glyph-eye.svg');
          background-position: center center;
          background-size: cover;
          display: none;
          height: 111px;
          left: 50%;
          pointer-events: none;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 221px;

          .yellow-black & {
            background-image: url('../images/glyph-eye-yellow.svg');
          }
        }
      }

      .title {
        @include fluid-type(24px, 30px);
        margin-top: 0;
        padding-top: .5em;
        text-transform: uppercase;

        a {
          @include link();
          text-decoration: none;
        }
      }

      .field-name-body {
        margin: 1.5rem 0;

        p {
          margin: 0;
        }
      }

      .read-more-label {
        text-align: center;

        span {
          border: 1px solid color(brand);
          color: color(brand);
          display: inline-block;
          font-family: $inter-ui;
          font-size: em(14px);
          font-weight: $font-weight-bold;
          letter-spacing: .075em;
          padding: .35em .5em .25em;
          text-transform: uppercase;
          transition: background-color .3s ease-in-out, color .3s ease-in-out;

          .yellow-black & {
            border-color: color(yellow);
            color: color(yellow);
          }
        }
      }

      .read-more-link {
        @include coverer;
        @include block-link-focus;
        font-size: 0;
      }
    }

    .view-footer {
      margin-top: 2em;
    }

    .pager {
      @extend %pager;
    }

    .current-page {
      @extend %current-page;
    }

    .attachment-after {
      padding-top: 2em;
      @include respond-to(l) {
        padding-top: 4em;
      }
      @include respond-to(xl) {
        padding-top: 8em;
      }

      h2 {
        @include fluid-type(19px, 45px);
        color: color('grey');
        margin: 0 auto .5em;
        text-transform: lowercase;
        text-align: center;
        @include respond-to(m) {
          line-height: 1;
          margin-bottom: -.5em;
        }

        .yellow-black & {
          color: color(yellow);
        }

        span {
          @include respond-to(m) {
            background-color: color(white);
            padding: 10px 45px;
          }

          .yellow-black & {
            background-color: color(text-bg-contrast-yb);
          }
        }
      }

      .item-list {
        background-color: color(grey-extra-light);
        list-style: none;
        margin: 0;
        padding: 1em;
        @include respond-to(m) {
          padding: 4em 3em;
        }

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
        }

        li {
          display: block;
          margin: .25em 0;
          @include respond-to(m) {
            display: inline-block;
          }

          &::after {
            @include respond-to(m) {
              background-color: color('grey');
              content: '';
              display: inline-block;
              height: $base-font-size;
              margin: -2px .25em 0;
              width: 2px;

              .yellow-black & {
                background-color: color(yellow);
              }
            }
          }

          &.views-row-last {
            &::after {
              content: none;
            }
          }

          a {
            @include link(color('grey'), color('grey'), color(grey-extra-dark));
            font-family: $inter-ui;
            font-size: em(18px);
            font-weight: $font-weight-bold;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
