.node-type-article {
  .glyph {
    @extend %glyph-hand;
  }

  &.yellow-black {
    .glyph {
      @extend %glyph-hand-yellow;
    }
  }
}

.node-article {
  &.ds-2col-stacked-fluid {
    .group-left {
      @include respond-to(m) {
        float: left; /* LTR */
        width: 30%;
      }
    }

    .group-right {
      @include respond-to(m) {
        float: right; /* LTR */
        width: 70%;
      }

      .field-name-body {
        @include respond-to(m) {
          @include reset-margin-first-last-element;
          padding-left: 1em;
        }
        @include respond-to(l) {
          padding-left: 2em;
        }
        @include respond-to(xl) {
          padding-left: 3em;
        }
      }
    }

    &.group-one-column {
      .group-left,
      .group-right {
        width: 100%;
        float: none;
      }

      .field-name-body {
        margin: 0 auto;
        max-width: 800px;
        padding-left: 0;
      }
    }

    .group-footer {
      clear: both;
      padding-top: 2em;
    }
  }

  &.view-mode-full {
    .title {
      @include fluid-type(24px, 60px);
      @include respond-to(m) {
        margin-top: 0;
        padding-top: .25em;
        position: relative;
        width: 60%;

        &::before {
          background-color: color(brand);
          content: '';
          height: 6px;
          left: 0;
          position: absolute;
          top: 0;
          width: 280px;

          .yellow-black & {
            background-color: color(yellow);
          }
        }
      }
    }

    .cover {
      @include reset-margin-first-last-element;

      img {
        display: block;
      }
    }

    .field-name-body {
      .image-caption {
        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          @include respond-to(m) {
            width: 80%;
          }
        }
      }

      .caption {
        @include respond-to(m) {
          width: 80%;
        }
      }
    }

    .caption {
      color: color(grey-dark);
      display: block;
      font-size: em(14px);
      line-height: 1.1;
      margin: 5px auto;
    }

    .group-images {
      @extend %group-images;
    }

    .group-attachments {
      @extend %group-attachments;
    }
  }
}
