.node-type-node-gallery-item {
  table.item-navigator {
    td {
      a {
        color: color(black);
        font-size: em(18px);
        font-weight: $font-weight-bold;
        padding: 0 10px;
        text-decoration: none;
      }
    }
  }

  .file-image {
    .content {
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .actors {
    ul {
      list-style: none;
      padding: 0;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 10px;
        a {
          color: color(black);
          font-size: em(18px);
          font-weight: $font-weight-bold;
          text-decoration: none;
        }
      }
    }
  }
}
