.node-type-spektakl {
  .layout-swap {
    &__bottom {
      @include zen-respond-to(l) {
        margin-top: -($layout-swap-height);
      }
    }
  }
}

.node-spektakl {
  &.ds-2col-stacked-fluid {
    .group-header {
      margin-bottom: 2em;
      @include respond-to(l) {
        margin-bottom: 3em;
      }
      @include respond-to(xl) {
        margin-bottom: 4em;
      }
    }

    .group-left {
      @include respond-to(m) {
        float: left; /* LTR */
        width: 30%;
      }
      text-align: center;

      .poster {
        a {
          @include block-link-focus;
          display: inline-block;
        }

        img {
          display: block;
          margin: 0 auto;
        }
      }
    }

    .group-right {
      @include respond-to(m) {
        float: right; /* LTR */
        width: 70%;
      }

      .field-name-body {
        @include respond-to(m) {
          @include reset-margin-first-last-element;
          padding-left: 1em;
        }
        @include respond-to(l) {
          padding-left: 2em;
        }
        @include respond-to(xl) {
          padding-left: 3em;
        }
      }
    }

    &.group-one-column {
      .group-left,
      .group-right {
        width: 100%;
        float: none;
      }
    }

    .group-footer {
      clear: both;
      padding-top: 2em;
    }
  }

  .group-title {
    margin: 0 auto;
    @include respond-to(xl) {
      width: 90%;

      &__wrapper {
        float: left;
        width: 50%;
      }
    }

    .title {
      font-size: em(32px);
      margin: .5em 0 0;
      text-align: center;
      text-transform: uppercase;
      @include respond-to(xs) {
        font-size: em(36px);
      }
      @include respond-to(m) {
        font-size: em(48px);
      }
      @include respond-to(xl) {
        border-right: 6px solid color(brand);
        margin-top: 0;
        padding-right: 30px;
        text-align: right;
        .yellow-black & {
          border-color: color(yellow);
        }
      }
      @include respond-to(xxl) {
        font-size: em(58px);
      }
      @include respond-to(xxxl) {
        font-size: em(68px);
      }
    }

    .glyph {
      @extend %glyph-menojre;
      margin: 0;

      &.before {
        margin: 0 auto;
        @include respond-to(xl) {
          display: none;
        }
      }
      &.after {
        display: none;
        @include respond-to(xl) {
          display: block;
          float: left;
          margin: 15px 0 0 30px;
        }
      }
    }
  }

  .group-information {
    @include respond-to(m) {
      padding-top: 1.5em;
      padding-left: 1em;
    }
    @include respond-to(l) {
      padding-left: 2em;
    }
    @include respond-to(xl) {
      padding-left: 3em;
    }

    .info-item {
      border-left: 2px solid color(brand);
      font-size: em(18px);
      margin-top: 2em;
      padding-left: .75em;
      padding-right: .5em;

      .yellow-black & {
        border-color: color(yellow);
      }

      @include respond-to(m) {
        float: left;
        width: 50%;
      }
      @include respond-to(l) {
        font-size: em(21px);
        width: 33.3333%;
      }

      .label-inline {
        font-weight: $font-weight-extra-light;

        & + span {
          margin-top: 10px;
        }
      }

      span {
        display: block;
        font-weight: $font-weight-regular;
      }
    }
  }

  .group-artists {
    &__item {
      font-family: $inter-ui;
      font-size: em(18px);
      padding: .25em 0;
      @include respond-to(l) {
        font-size: em(21px);
      }
    }

    &__job {
      display: inline-block;
      font-weight: $font-weight-regular;
      @include respond-to(m) {
        display: block;
        float: left;
        text-align: right;
        width: 49%;
      }

      span {
        &::after {
          content: ':';
          display: inline-block;
        }
      }
    }

    &__person {
      text-align: left;
      text-transform: uppercase;
      @include respond-to(m) {
        margin-left: 51%;
        width: 49%;
      }

      span {
        display: inline-block;

        strong {
          display: inline-block;
          font-weight: $font-weight-bold;

          & + strong {
            &::before {
              content: ',';
              display: inline-block;
              margin-right: 4px;
            }
          }
        }
      }

      a {
        @include link(color(black), color(black), color(brand));
        border-bottom: 3px solid color(brand);
        text-decoration: none;

        .yellow-black & {
          border-color: color(yellow);
        }

        &:hover {
          .yellow-black & {
            text-decoration: none;
          }
        }
      }
    }
  }

  .group-cast {
    .actors {
      display: flex;
      flex-wrap: wrap;
    }

    .actor {
      margin: 1em auto;
      @include respond-to(xs) {
        margin-left: 2%;
        margin-right: 2%;
        width: 46%;
      }
      @include respond-to(m) {
        width: 29.333%;
      }
      @include respond-to(l) {
        width: 21%;
      }
      @include respond-to(xxxl) {
        width: 16%;
      }

      .title {
        border-bottom: 3px solid color(brand);
        font-size: em(18px);
        line-height: 1.25;
        margin: 0;
        padding-bottom: 10px;
        text-transform: uppercase;
        transition: color .3s ease-in-out;
        @include respond-to(m) {
          font-size: em(21px);
        }

        .yellow-black & {
          border-color: color(yellow);
        }
      }

      .role {
        display: block;
        margin-top: 10px;
      }

      &.actor-image {
        .content {
          position: relative;
          max-width: 240px;
          margin: 0 auto;

          &:hover {
            .group-image {
              img {
                opacity: .1;
              }

              .glyph {
                opacity: 1;
              }
            }

            .title {
              color: color(brand);

              .yellow-black & {
                color: color(yellow);
              }
            }
          }
        }

        .node-414,
        .node-424,
        .node-5167 {
          .title {
            border: 3px solid color(black);
            padding: 5px;

            .yellow-black & {
              border-color: color(yellow);
            }
          }
        }

        .group-image {
          position: relative;
          img {
            display: block;
            margin: 0 auto;
            transition: opacity .3s ease-in-out;
          }

          .glyph {
            background-image: url('../images/glyph-eye.svg');
            background-position: center center;
            background-size: cover;
            height: 75px;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity .3s ease-in-out;
            width: 150px;

            .yellow-black & {
              background-image: url('../images/glyph-eye-yellow.svg');
            }
          }

          & + .title {
            margin-top: 1em;
          }
        }

        a {
          @include coverer;
          @include block-link-focus;
          overflow: hidden;
          text-indent: -9999px;

          & + .title {
            border-bottom: 0;
            margin-top: .5em;
            padding-bottom: 0;
            @include respond-to(m) {
              font-size: em(18px);
            }
          }
        }
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }

    .additional-cast {
      clear: both;
      margin: 0 2%;

      .label-above {
        font-size: em(21px);
        font-weight: $font-weight-semi-bold;
        margin-top: 2em;
      }

      p {
        strong {
          font-weight: $font-weight-bold;
          text-transform: uppercase;
        }
      }
    }
  }

  .group-when-we-play {
    .view-repertoire {
      td {
        &.pipe-3 {
          span {
            display: none;
          }
        }
      }
    }
  }

  .group-iframe {
    .iframe_title {
      @include respond-to(l) {
        font-size: em(45px);
      }
      text-align: center;
      margin: 0 0 1em;
    }
  }

  .group-multimedia {
    .group-video-items {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }

    .video-item {
      font-size: $base-font-size;
      padding-bottom: 25px;
      @include respond-to(s) {
        width: 50%;

        &:nth-of-type(2n+1) {
          .group-content {
            margin-right: 12px;
          }
        }

        &:nth-of-type(2n+2) {
          .group-content {
            margin-left: 12px;
          }
        }
      }

      @include respond-to(l) {
        width: 33.3333%;

        &:nth-of-type(3n+1) {
          .group-content {
            margin-right: 16px;
            margin-left: 0;
          }
        }

        &:nth-of-type(3n+2) {
          .group-content {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        &:nth-of-type(3n+3) {
          .group-content {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }

      a {
        @include block-link-focus;
        display: block;
        margin-bottom: 35px;
        position: relative;

        &::after {
          @include font-size(30px);
          background-color: color(black);
          border-radius: 50%;
          color: color(white);
          font-family: $font-awesome;
          line-height: 50px;
          height: 50px;
          width: 50px;
          position: absolute;
          bottom: 0;
          left: 50%;
          text-align: center;
          transform: translate(-50%, 50%);

          .yellow-black & {
            background-color: color(yellow);
          }
        }
      }

      img {
        display: block;
      }

      &.youtube {
        a {
          &::after {
            content: '\f167';

            .yellow-black & {
              color: color(text-bg-contrast-yb);
            }
          }
        }
      }

      &.vimeo {
        a {
          &::after {
            content: '\f27d';

            .yellow-black & {
              color: color(text-bg-contrast-yb);
            }
          }
        }
      }

      .video-embed-description {
        font-size: em(14px);
        text-align: center;
      }

      .title {
        @include fluid-type(16px, 24px);
        margin-bottom: 0;
        margin-top: 0;
        text-align: center;
      }
    }
  }

  .group-additional-information {
    border-top: 4px solid color(blue);
    margin-top: 2em;

    .yellow-black & {
      border-top-color: color(yellow);
    }

    & * {
      color: color(black);
      font-size: 1em;

      .yellow-black & {
        color: color(text-contrast-yb);
      }
    }

    .additional-info {
      &__section {
        border-bottom: 1px solid color(black);

        .yellow-black & {
          border-bottom-color: color(yellow);
        }
      }
    }

    .logotypes {
      .logo {
        display: inline-block;
        vertical-align: middle;

        a {
          @include block-link-focus(0);
        }

        img {
          display: block;
          margin: 10px;
          max-height: 60px;
          width: auto;
        }
      }
    }
  }

  .group-review,
  .group-attachments {
    .item-list {
      list-style: none;
      padding: 0;

      li {
        @include fluid-type(19px, 24px);
        overflow: hidden;
        padding: 1em 0 1em 1em;
        transition: background-color .3s ease-in-out;
        @include respond-to(m) {
          padding-left: 2em;
        }

        &:nth-of-type(2n + 2) {
          background-color: color(grey-extra-light);

          .yellow-black & {
            background-color: transparent;
          }
        }

        &:hover {
          background-color: #e6e6e6;

          .yellow-black & {
            background-color: transparent;
          }
        }
      }

      .file {
        &.active {
          .fa {
            color: color(brand);

            .yellow-black & {
              color: color(yellow);
            }
          }
        }
      }

      .title {
        @include fluid-type(16px, 24px);
        font-weight: $font-weight-semi-bold;
      }

      a {
        @include link(color(black), color(black), color(brand));
        display: inline-block;
        text-decoration: none;
      }

      .fa {
        color: color('grey');
        margin-right: .25em;
        margin-top: .15em;
        vertical-align: top;
        transition: color .3s ease-in-out;

        .yellow-black & {
          color: color(yellow);
        }
      }

      span.ext {
        padding-left: 8px;

        &::after {
          font-size: .75em;
          color: color('grey');

          .yellow-black & {
            color: color(yellow);
          }
        }
      }
    }
  }
}
