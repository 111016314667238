html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;

  .page-preloader {
    background-color: color(grey-extra-light);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;

    .throbber {
      @extend %throbber;
    }
  }

  &.sticky {
    &::after {
      @include respond-to(l) {
        background-color: color(white);
        box-shadow: 0 1px 10px 0 rgba(204, 204, 204, .5);
        content: '';
        height: 100px;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 996;
      }
    }

    &.admin-menu {
      &::after {
        @media only screen and (min-width: 1025px) {
          top: 28px;
        }
      }
    }
  }

  &.yellow-black {
    background-color: color(text-bg-contrast-yb);
    color: color(text-contrast-yb);

    &::after {
      @include respond-to(l) {
        background-color: color(text-bg-contrast-yb);
      }
    }

    .page-preloader {
      background-color: color(text-bg-contrast-yb);

      .throbber {
        border-left-color: color(yellow);
        border-right-color: color(yellow);
        border-bottom-color: color(yellow);
        border-top-color: color(text-bg-contrast-yb);
      }
    }
  }
}

.page {
  min-height: 850px;
}

.node-unpublished {
  padding-top: 8em;
  position: relative;

  &:before {
    background-color: white;
    content: 'UNPUBLISHED';
    color: red;
    font-size: 8em;
    font-weight: $font-weight-bold;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

:lang(ru) {
  font-family: $verdana, sans-serif;

  strong {
    font-weight: bold;
  }
}
