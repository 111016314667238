.exposed-form-search-results-page {
  .views-exposed-widget {
    padding: 0;

    label {
      @extend %visually-hidden;
    }

    input {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      height: 52px;
    }

    .form-item {
      .form-text {
        @include block-link-focus(-2px);
        border-bottom: 2px solid color(grey-dark);
        padding-left: 4px;
        width: 120px;
        @include respond-to(xs) {
          width: 160px;
        }

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
          border-color: color(yellow);
          color: color(yellow);
        }
      }
    }

    .form-submit {
      @include block-link-focus(-2px);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: color(white);
      background-image: url('../images/loupe.svg');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 25px 25px;
      border-bottom: 2px solid color(grey-dark);
      margin: 0;
      overflow: hidden;
      width: 50px;
      text-indent: -9999px;

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
        background-image: url('../images/loupe-yellow.svg');
        border-color: color(yellow);
      }
    }
  }
}
