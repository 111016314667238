@mixin link-button( $color: color(brand),
                    $color-hover: color(white),
                    $background-color: color(white),
                    $background-color-hover: color(brand),
                    $border-color: color(brand),
                    $border-color-hover: color(brand)) {
  display:          inline-block;
  font-weight:      $font-weight-bold;
  letter-spacing:   .075em;
  padding:          .35em .5em .25em;
  text-align:       center;
  text-decoration:  none;
  transition:       background-color .3s ease-in-out, color .3s ease-in-out;

  &:link,
  &:visited {
    background-color: $background-color;
    border:           1px solid $border-color;
    color:            $color;
  }

  &:hover,
  &:active,
  &:focus-visible {
    background-color: $background-color-hover;
    border:           1px solid $border-color-hover;
    color:            $color-hover;
  }

  &:focus-visible {
    outline:          2px solid $border-color-hover;
  }

  .yellow-black & {
    &:link,
    &:visited {
      background-color: color(text-bg-contrast-yb);
      border-color:     color(yellow);
      color:            color(yellow);
    }

    &:hover,
    &:active,
    &:focus-visible {
      background-color: color(yellow);
      border-color:     color(yellow);
      color:            color(text-bg-contrast-yb);
    }

    &:focus-visible {
      outline:          2px solid color(yellow);
    }
  }
}
