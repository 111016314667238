.view-artists {
  & > .glyph {
    @extend %glyph-lt;
  }

  .view-content {
    @include respond-to(s) {
      display: flex;
      flex-wrap: wrap;
    }

    .views-row {
      @include respond-to(s) {
        margin-left: 2%;
        margin-right: 2%;
        width: 46%;
      }
      @include respond-to(l) {
        width: 29.333%;
      }
      @include respond-to(xxxl) {
        margin-left: 1%;
        margin-right: 1%;
        width: 23%;
      }
      @include respond-to(xxxxl) {
        margin-left: 2%;
        margin-right: 2%;
        width: 21%;
      }
    }

    .content {
      margin: 0 auto 1em;
      position: relative;
      @include respond-to(m) {
        margin-bottom: 3em;
      }
      @include respond-to(l) {
        margin-bottom: 4em;
      }

      &:hover {
        .image {
          img {
            @include respond-to(s) {
              opacity: .1;
            }
          }

          .glyph {
            @include respond-to(s) {
              display: block;
            }
          }
        }
        h2.title {
          color: color(brand);

          .yellow-black & {
            color: color(yellow);
          }
        }
      }
    }

    .image {
      float: left;
      margin-right: 2%;
      position: relative;
      width: 50%;
      @include respond-to(s) {
        float: none;
        margin-right: 0;
        width: 100%;
      }

      .person-photo-fill {
        .yellow-black & {
          background-image: url('../images/person-fill-yellow.svg');
          background-size: cover;
        }

        img {
          .yellow-black & {
            opacity: 0;
          }
        }
      }

      img {
        display: block;
        transition: opacity .3s ease-in-out;
      }

      .glyph {
        background-image: url('../images/glyph-eye.svg');
        background-size: cover;
        display: none;
        height: 83px;
        left: 50%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 165px;

        .yellow-black & {
          background-image: url('../images/glyph-eye-yellow.svg');
        }
      }
    }

    h2.title {
      @include fluid-type(18px, 30px);
      float: right;
      margin: 0;
      overflow: visible;
      text-transform: uppercase;
      width: 48%;
      @include respond-to(s) {
        @include margin-top(1, xl);
        @include margin-bottom(1, xl);
        float: none;
        width: 100%;
      }

      &.node-414 {
        border: 3px solid color(black);
        padding: 0 5px;

        .yellow-black & {
          border-color: color(yellow);
        }
      }
    }

    a {
      @include coverer;
      @include block-link-focus;
      overflow: hidden;
      text-indent: -9999px;
    }
  }

  .attachment-after {
    padding-top: 2em;
    @include respond-to(l) {
      padding-top: 4em;
    }
    @include respond-to(xl) {
      padding-top: 8em;
    }

    h2 {
      @include fluid-type(19px, 45px);
      color: color('grey');
      margin: 0 auto .5em;
      text-transform: lowercase;
      text-align: center;
      @include respond-to(m) {
        line-height: 1;
        margin-bottom: -.5em;
      }

      .yellow-black & {
        color: color(yellow);
      }

      span {
        @include respond-to(m) {
          background-color: color(white);
          padding: 10px 45px;
        }

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
        }
      }
    }

    .item-list {
      background-color: color(grey-extra-light);
      list-style: none;
      margin: 0;
      padding: 1em;
      @include respond-to(m) {
        padding: 4em 3em;
      }

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
      }

      li {
        color: color('grey');
        display: block;
        font-family: $inter-ui;
        font-size: em(18px);
        font-weight: $font-weight-bold;
        margin: .25em 0;
        text-decoration: none;
        text-transform: uppercase;
        @include respond-to(m) {
          display: inline-block;
        }

        &::after {
          @include respond-to(m) {
            background-color: color('grey');
            content: '';
            display: inline-block;
            height: $base-font-size;
            margin: -2px .25em 0;
            width: 2px;

            .yellow-black & {
              background-color: color(yellow);
            }
          }
        }

        &.views-row-last {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}
