.page-repertuar {
  .page-title {
    text-align: center;
  }
  .breadcrumb {
    text-align: center;
  }
}

.view-repertoire {

  &.monthly-repertoire {

    .layout-18col--with-border {
      @include zen-respond-to(xl) {
        &::before {
          top: 80px;
          height: calc(100% - 80px);
        }
      }
    }
    .title-wrapper {
      @include zen-respond-to(xl) {
        margin-top: 80px;
      }
    }
    .glyph {
      @extend %glyph-hex;
    }
    /* view-header */
    /* date nav wrapper */
    .date-nav {
      @extend %date-nav;
    }
    .date-year {
      @extend %date-year;
    }
    /* view content */
    table {
      border-top: 4px solid color(black);
      .yellow-black & {
        border-color: color(yellow);
      }
    }
  }
}
