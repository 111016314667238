.view-theatre-team {
  &.theatre-team {
    & > .glyph {
      @extend %glyph-lt;
    }

    .view-content {
      @include respond-to(s) {
        display: flex;
        flex-wrap: wrap;
      }

      .views-row {
        @include respond-to(s) {
          margin-left: 2%;
          margin-right: 2%;
          width: 46%;
        }
        @include respond-to(l) {
          width: 29.333%;
        }
        @include respond-to(xxxl) {
          margin-left: 1%;
          margin-right: 1%;
          width: 23%;
        }
        @include respond-to(xxxxl) {
          margin-left: 2%;
          margin-right: 2%;
          width: 21%;
        }
      }

      .content {
        margin: 0 auto;
        max-width: 280px;
        position: relative;
        @include respond-to(m) {
          margin-bottom: 3em;
        }
        @include respond-to(l) {
          margin-bottom: 4em;
        }

        &:hover {
          .image {
            img {
              opacity: .1;
            }

            .glyph {
              display: block;
            }
          }

          h2.title {
            color: color(brand);

            .yellow-black & {
              color: color(yellow);
            }
          }
        }
      }

      .image {
        position: relative;

        img {
          display: block;
          transition: opacity .3s ease-in-out;
        }

        .glyph {
          background-image: url('../images/glyph-eye.svg');
          background-size: cover;
          display: none;
          height: 83px;
          left: 50%;
          pointer-events: none;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 165px;

          .yellow-black & {
            background-image: url('../images/glyph-eye-yellow.svg');
          }
        }
      }

      h2.title {
        @include fluid-type(21px, 30px);
        text-transform: uppercase;

        &.node-414,
        &.node-424,
        &.node-5167 {
          border: 3px solid color(black);
          padding: 0 5px;

          .yellow-black & {
            border-color: color(yellow);
          }
        }
      }

      a {
        @include coverer;
        @include block-link-focus;
        overflow: hidden;
        text-indent: -9999px;
      }
    }
  }
}
