.view-lista-spektakli {
  &.lista-spektakli-archiwalnych {
    & > .glyph {
      @extend %glyph-menojre;
    }

    .view-empty {
      text-align: center;
    }

    .view-content {
      @include respond-to(s) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .views-row {
      margin-top: 2em;
      @include respond-to(s) {
        width: 50%;

        &.views-row-odd {
          .content {
            margin-right: 12px;
          }
        }

        &.views-row-even {
          .content {
            margin-left: 12px;
          }
        }
      }

      @include respond-to(l) {
        margin-top: 3em;
        width: 33.3333%;
        &.views-row-1,
        &.views-row-4,
        &.views-row-7,
        &.views-row-10 {
          .content {
            margin-right: 16px;
            margin-left: 0;
          }
        }

        &.views-row-2,
        &.views-row-5,
        &.views-row-8,
        &.views-row-11 {
          .content {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        &.views-row-3,
        &.views-row-6,
        &.views-row-9,
        &.views-row-12 {
          .content {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }
    }

    .content {
      position: relative;

      &.active {
        .poster {
          img {
            opacity: .1;
          }

          .glyph {
            display: block;
          }
        }

        .title {
          color: color(brand);

          .yellow-black & {
            color: color(yellow);
          }
        }

        .read-more-label {
          span {
            background-color: color(brand);
            color: color(white);

            .yellow-black & {
              background-color: color(yellow);
              color: color(text-bg-contrast-yb);
            }
          }
        }
      }
    }

    .poster {
      position: relative;

      img {
        display: block;
        margin: 0 auto;
        transition: opacity .3s ease-in-out;
      }

      .glyph {
        background-image: url('../images/glyph-eye.svg');
        background-position: center center;
        background-size: cover;
        display: none;
        height: 111px;
        left: 50%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 221px;

        .yellow-black & {
          background-image: url('../images/glyph-eye-yellow.svg');
        }
      }
    }

    .title {
      @include fluid-type(24px, 30px);
      margin-top: 0;
      padding-top: .5em;
      text-transform: uppercase;

      a {
        @include link();
        text-decoration: none;
      }
    }

    .field-name-body {
      margin: 1.5rem 0;

      p {
        margin: 0;
      }
    }

    .read-more-label {
      text-align: center;

      span {
        border: 1px solid color(brand);
        color: color(brand);
        display: inline-block;
        font-family: $inter-ui;
        font-size: em(14px);
        font-weight: $font-weight-bold;
        letter-spacing: .075em;
        padding: .35em .5em .25em;
        text-transform: uppercase;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        .yellow-black & {
          border-color: color(yellow);
          color: color(yellow);
        }
      }
    }

    .read-more-link {
      @include coverer;
      @include block-link-focus;
      font-size: 0;
    }

    .view-footer {
      margin-top: 2em;
    }

    .pager {
      @extend %pager;
    }

    .current-page {
      @extend %current-page;
    }
  }
}
