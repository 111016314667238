.breadcrumb {
  list-style: none;
  margin: 2em 0;
  overflow-x: hidden;
  padding: 0;
  text-align: center;

  li {
    font-size: em(18px);
    color: color('grey');
    display: inline-block;
    font-weight: $font-weight-extra-light;
    padding: .125em;
    text-transform: lowercase;
    @include respond-to(m) {
      font-size: em(21px);
    }

    .yellow-black & {
      color: color(yellow);
    }
  }

  a {
    @include link(color('grey'));
    text-decoration: none;

    span {
      display: block;
      margin: .125em;
    }
  }

  .delimiter {
    background-color: color('grey');
    display: inline-block;
    font-size: 0;
    height: 2px;
    line-height: 1;
    margin: 0 4px;
    width: 16px;
    @include respond-to(s) {
      margin-left: 6px;
      margin-right: 6px;
      width: 24px;
    }
    @include respond-to(m) {
      margin-left: 10px;
      margin-right: 10px;
      width: 76px;
    }

    .yellow-black & {
      background-color: color(yellow);
    }
  }
}
