.view-node-gallery-embedded,
.view-person-photos {
  margin: 0 -20px;
  overflow: hidden;
  position: relative;
  max-width: 1640px;
  max-height: 570px;
  @include respond-to(s) {
    margin-left: auto;
    margin-right: auto;
  }

  // When one image only
  .unslick {
    text-align: center;
    &::before {
      @extend %gradient-layer-node-gallery-embedded;
    }
    .file-image {
      display: inline-block;
      position: relative;
      z-index: 1;
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  .slick {
    &__slider {
      margin: 0 auto;
      @include respond-to(m) {
        width: 600px;
      }
      @include respond-to(xl) {
        width: 850px;
      }
      .slick-list {
        overflow: visible;
      }
    }
    &__slide {
      position: relative;
      &::before {
        @extend %gradient-layer-node-gallery-embedded;
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
      &:not(.slick-current) {
        cursor: grab;
        &::before {
          opacity: 1;
        }
        &:active {
          cursor: grabbing;
        }
        a.colorbox {
          pointer-events: none;
        }
      }
    }
    &__arrow {
      display: none;
      width: 100%;
      @include zen-respond-to(m) {
        display: block;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      button {
        background-color: transparent;
        background-image: url('../images/arrows-slick.png');
        border: 0;
        height: 50px;
        overflow: hidden;
        pointer-events: auto;
        width: 50px;
        text-indent: -9999px;
        .yellow-black & {
          background-image: url('../images/arrows-slick-yellow.png');
        }
        &.slick-prev {
          background-position: left top;
          float: left;
        }
        &.slick-next {
          background-position: right top;
          float: right;
        }
        &.slick-disabled {
          cursor: default;
          opacity: .1;
          &:hover {
            opacity: .1;
          }
        }
        &:hover {
          opacity: .65;
        }
      }
    }
  }

  .slide {
    &__content {
      img {
        display: block;
      }
    }
  }

  .caption {
    background-color: rgba(0, 0, 0, .7);
    bottom: 0;
    color: color(white);
    font-size: em(14px);
    font-style: italic;
    left: 0;
    padding: 2px 5px;
    position: absolute;
    .yellow-black & {
      background-color: color(text-bg-contrast-yb);
      color: color(yellow);
    }
  }
}
