.block-tz-contrast {
  h2 {
    @extend %wcag-block-h2;
  }

  .content {
    font-size: 0;

    input {
      @include block-link-focus;
      background-image: url('../images/contrast.svg');
      border: 0;
      color: color(black);
      display: inline-block;
      height: 25px;
      margin: 5px;
      overflow: hidden;
      text-decoration: none;
      text-indent: -9999px;
      width: 25px;

      &#edit-default,
      &#edit-default--2 {
        background-position: top left;
      }

      &#edit-yellow-black,
      &#edit-yellow-black--2 {
        background-position: top right;
      }

      & + input {
        margin-left: 20px;

        @include zen-respond-to(l) {
          margin-left: 0;
        }
      }
    }
  }
}
