#backtotop {
  background-color: color(brand);
  background-image: url('../images/backtotop.png');
  background-position: center center;
  background-repeat: no-repeat;
  border: 0;
  bottom: 10px;
  cursor: pointer;
  display: none;
  height: 50px;
  overflow: hidden;
  padding: 0;
  position: fixed;
  right: 10px;
  text-indent: -9999px;
  width: 50px;
  z-index: 1;
  .yellow-black & {
    background-color: color(text-bg-contrast-yb);
    background-image: url('../images/backtotop-yellow.png');
  }
  @include zen-respond-to(xxl) {
    right: calc((50% - (#{map-get($max-width, xxl)} / 2)) / 2);
    transform: translateX(50%);
  }
  @include zen-respond-to(xxxl) {
    right: calc((50% - (#{map-get($max-width, xxxl)} / 2)) / 2);
  }
  @include zen-respond-to(xxxxl) {
    right: calc((50% - (#{map-get($max-width, xxxxl)} / 2)) / 2);
  }
  &:hover {
    background-color: color(black);
  }
}
