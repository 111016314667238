.local-navigation {
  background-image: url('../images/glyph-eye-small.svg');
  background-position: center 15px;
  background-repeat: no-repeat;
  background-size: 60px 30px;
  padding-bottom: 20px;
  padding-top: 60px;
  position: relative;
  text-align: center;
  z-index: 1;
  @include respond-to(m) {
    background-position: calc(100% - 15px) 15px;
    padding-bottom: 0;
    padding-right: 90px;
    padding-top: 0;
    text-align: right;
  }

  .yellow-black & {
    background-image: url('../images/glyph-eye-small-yellow.svg');
  }

  .sticky & {
    @include respond-to(l) {
      background-color: color(white);
      right: 0;
      position: fixed;
      top: 100px;
      z-index: 995;
    }
  }

  .admin-menu.sticky & {
    @media only screen and (min-width: 1025px) {
      top: 128px;
    }
  }

  .sticky.yellow-black & {
    background-color: color(text-bg-contrast-yb);
  }

  ul {
    list-style: none;
    margin: 2px 0 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 5px;

      a {
        @include link(color(brand), color(brand), color(black));
        @include block-link-focus(0);
        display: block;
        font-family: $inter-ui;
        font-size: em(14px);
        font-weight: $font-weight-medium;
        padding: 0 5px;
        text-decoration: none;
        text-transform: uppercase;
        @include respond-to(xs) {
          font-size: em(16px);
        }
        @include respond-to(m) {
          font-size: em(19px);
          line-height: 60px;
        }
      }
    }
  }
}
