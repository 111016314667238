// Font faces
//
// Instead of relying on the fonts that are available on a user's computer, you
// can use web fonts which, like images, are resources downloaded to the user's
// browser. Because of the bandwidth and rendering resources required, web fonts
// should be used with care.
//
// Numerous resources for web fonts can be found on Google. Here are a few
// websites where you can find Open Source fonts to download:
// - http://www.fontsquirrel.com/fontface
// - http://www.theleagueofmoveabletype.com
//
// In order to use these fonts, you will need to convert them into formats
// suitable for web fonts. We recommend the free-to-use Font Squirrel's
// Font-Face Generator:
//   http://www.fontsquirrel.com/fontface/generator
//
// The following is an example @font-face declaration. This font can then be
// used in any ruleset using a property like this:  font-family: Example, serif;
//
// Since we're using Sass, you'll need to declare your font faces here, then you
// can add them to the font variables in the _init.scss partial.

// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

@import url('https://fonts.googleapis.com/css?family=Overpass:200,200i,300,300i,400,400i,600,600i,700,700i&display=swap&subset=latin-ext');

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Inter-UI-Regular.woff2') format('woff2'),
       url('../fonts/Inter-UI-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Inter-UI-Italic.woff2') format('woff2'),
       url('../fonts/Inter-UI-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Inter-UI-Medium.woff2') format('woff2'),
       url('../fonts/Inter-UI-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Inter-UI-MediumItalic.woff2') format('woff2'),
       url('../fonts/Inter-UI-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Inter-UI-Bold.woff2') format('woff2'),
       url('../fonts/Inter-UI-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Inter-UI-BoldItalic.woff2') format('woff2'),
       url('../fonts/Inter-UI-BoldItalic.woff') format('woff');
}
