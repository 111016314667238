%glyph {
  background-position: center center;
  background-size: cover;
  display: block;
}

%glyph-eye {
  @extend %glyph;
  background-image: url('../images/glyph-eye.svg');
  height: 63px;
  margin: 16px auto 35px;
  width: 126px;
  .yellow-black & {
    background-image: url('../images/glyph-eye-yellow.svg');
  }
}

%glyph-hex {
  @extend %glyph;
  background-image: url('../images/glyph-hex.svg');
  height: 55px;
  margin: 16px auto 43px;
  width: 63px;
  .yellow-black & {
    background-image: url('../images/glyph-hex-yellow.svg');
  }
}

%glyph-lt {
  @extend %glyph;
  background-image: url('../images/glyph-lt.svg');
  height: 77px;
  margin: 16px auto 21px;
  width: 63px;
  .yellow-black & {
    background-image: url('../images/glyph-lt-yellow.svg');
  }
}

%glyph-menojre {
  @extend %glyph;
  background-image: url('../images/glyph-menojre.svg');
  height: 50px;
  margin: 16px auto 48px;
  width: 98px;
  .yellow-black & {
    background-image: url('../images/glyph-menojre-yellow.svg');
  }
}

%glyph-hand {
  @extend %glyph;
  background-image: url('../images/glyph-hand.svg');
  height: 78px;
  margin: 16px auto 20px;
  width: 64px;
}

%glyph-hand-yellow {
  @extend %glyph-hand;
  background-image: url('../images/glyph-hand-yellow.svg');
}

%glyph-oval {
  @extend %glyph;
  background-image: url('../images/glyph-oval.svg');
  height: 62px;
  margin: 16px auto 36px;
  width: 62px;
}

%glyph-oval-yellow {
  @extend %glyph-oval;
  background-image: url('../images/glyph-oval-yellow.svg');
}
