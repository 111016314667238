// Responsive 18-column grid with border
//
// Markup: layout-18col--with-border.twig
//
// Style guide: layouts.layout-18col--with-border

.layout-18col--with-border {
  @include zen-respond-to(xl) {
    min-height: 300px;
    position: relative;
    &::before {
      background-color: color(brand);
      content: '';
      display: flex;
      position: absolute;
      left: 5.35%;
      top: 0;
      height: 100%;
      width: 7px;
      .yellow-black & {
        background-color: color(yellow);
      }
      @include rtl() {
        right: 5.35%;
      }
    }
  }

  .title-wrapper {
    display: none;
    position: relative;
    text-align: center;
    @include zen-respond-to(xl) {
      display: block;
      text-align: left;
    }
    &::before {
      background-color: color(brand);
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      height: 7px;
      width: calc(100% - 40px);
      .yellow-black & {
        background-color: color(yellow);
      }
      @include zen-respond-to(xl) {
        content: none;
      }
    }
    h2 {
      @include font-size(24px);
      background-color: color(white);
      color: color(brand);
      display: inline-block;
      margin: 0;
      padding: 0 10px;
      position: relative;
      text-transform: uppercase;
      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
        color: color(yellow);
      }
      @include zen-respond-to(xl) {
        margin: 0;
        padding: 0 0 0 10px;
        position: absolute;
        left: 50%;
        top: -1px;
        transform: rotate(270deg) translateX(-100%) translateY(-50%);
        transform-origin: top left;
        white-space: nowrap;
      }
    }
  }
}
