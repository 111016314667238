.block {
  &.newsletter {
    padding-top: 2em;
    @include respond-to(l) {
      padding-top: 4em;
    }
    @include respond-to(xl) {
      padding-top: 6em;
    }
    @media print {
      display: none;
      visibility: hidden;
    }

    .block__title {
      @extend %section-title;
      margin: 0 auto -.5em;

      span {
        background-color: color(white);
        padding: 10px 20px;
        @include respond-to(l) {
          padding-left: 45px;
          padding-right: 45px;
        }

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
        }
      }
    }
    .content {
      border: 4px solid color(black);
      padding: 2em 1em;
      text-align: center;
      @include respond-to(l) {
        padding: 2em 4em 2.5em;
      }

      .yellow-black & {
        background-color: color(yellow);
        border-color: color(yellow);
      }
    }
    /* custom/forms/_newsletter.scss */
  }
}
