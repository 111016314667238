ul.sf-menu {
  @media print {
    display: none;
    visibility: hidden;
  }
  @include rtl() {
    padding: 0 !important;
  }

  ul {
    @include rtl() {
      padding: 0 !important;
    }
  }
}

.sf-menu,
.sf-menu * {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sf-menu.menu,
.sf-menu.menu ul,
.sf-menu.menu li {
  margin: 0;
}

.sf-hidden {
  left: -99999em !important;
  top: -99999em !important;
  @include rtl() {
    left: auto !important;
    right: -99999em !important;
  }
}

.sf-menu {
  // Mobile menu
  &.sf-accordion {
    background-color: color(black);
    display: none;
    float: left;
    padding-bottom: 20px;
    position: absolute;
    width: 100%;
    z-index: 1;

    ul,
    li {
      float: left;
      width: 100%;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    &.sf-expanded,
    li.sf-expanded > ul {
      left: auto !important;
      position: relative;
      top: auto !important;
    }

    .sf-hidden {
      display: none !important;
    }

    a,
    span.nolink {
      color: color(white);
      cursor: pointer;
      display: block;
      font-size: em(20px);
      font-weight: $font-weight-semi-bold;
      line-height: 1.75;
      padding: 5px 20px 0;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      &.sf-depth-1 {
        background-color: color(black);
      }

      &.sf-depth-2 {
        background-color: color(grey-extra-dark);
      }

      &.sf-depth-3 {
        background-color: color(grey-dark);
      }

      &.menuparent {
        position: relative;

        &::after {
          background-image: url('../images/arrow-nav-mobile.svg');
          background-position: left center;
          background-repeat: no-repeat;
          content: '';
          height: 15px;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;

          .yellow-black & {
            background-position: right center;
          }
        }
      }

      .yellow-black & {
        color: color(text-contrast-yb);
      }
    }

    a.search {
      background-image: url('../images/loupe-white.svg');
      background-position: calc(100% - 20px) 50%;
      background-repeat: no-repeat;
      background-size: 25px 25px;

      .yellow-black & {
        background-image: url('../images/loupe-yellow.svg');
      }
    }

    li.sf-expanded {
      & > a,
      & > span.nolink {
        &.menuparent {
          &::after {
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }
    }
  }

  // Desktop menu
  &.sf-horizontal {
    float: right;
    line-height: 1;
    z-index: 497;

    ul {
      background-color: color(black);
      left: 0;
      position: absolute;
      top: -99999em;
      width: 8em;

      .yellow-black & {
        background-color: color(yellow);
      }

      li {
        width: 100%;
      }

      @include rtl() {
        left: auto;
        right: 0;
      }
    }

    li {
      float: left;
      position: relative;
      z-index: 498;

      @include rtl() {
        float: right;
      }
    }

    li.active-trail a.sf-depth-1,
    li.active-trail span.nolink.sf-depth-1 {
      position: relative;

      &::after {
        background-color: color(brand);
        content: '';
        height: 2px;
        left: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-100%);
        width: calc(100% - 32px);

        .yellow-black & {
          background-color: color(yellow);
        }
      }
    }

    li.active-trail li.active-trail a.sf-depth-2,
    li.active-trail li.active-trail span.nolink.sf-depth-2,
    li.active-trail li.active-trail li.active-trail a.sf-depth-3,
    li.active-trail li.active-trail li.active-trail span.nolink.sf-depth-3 {
      background-color: color(grey-extra-light);
      color: color(black);
      text-decoration: line-through;

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
        color: color(yellow);
      }
    }

    li.sf-depth-1 {
      a.newsletter {
        background-color: color(black);
        color: color(white);
        line-height: 26px;
        margin: 13px 0 0 16px;
        padding: 4px 10px 0;
        text-transform: lowercase;

        .yellow-black & {
          background-color: color(yellow);
          color: color(text-bg-contrast-yb);
        }

        &:hover {
          background-color: color(brand);
        }
      }
    }

    a,
    span.nolink {
      display: block;
      font-weight: $font-weight-bold;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      &.sf-depth-1 {
        color: color(black);
        font-size: em(20px);
        line-height: $layout-swap-height;
        padding: 0 8px;
        @include zen-respond-to(xl) {
          padding: 0 16px 0 8px;
        }
        @include zen-respond-to(xxl) {
          padding: 0 16px;
        }

        &:hover {
          color: color(grey-dark);
        }

        .yellow-black & {
          color: color(yellow);
        }
      }

      &.sf-depth-2,
      &.sf-depth-3 {
        color: color(white);
        font-size: em(16px);
        line-height: 1.25;
        padding: 12px 16px 8px;

        .yellow-black & {
          color: color(text-bg-contrast-yb);
        }

        &:hover {
          background-color: color(grey-extra-light);
          color: color(black);

          .yellow-black & {
            background-color: color(text-bg-contrast-yb);
            color: color(yellow);
          }
        }
      }
    }

    a {
      @include block-link-focus(0);
    }

    span.nolink {
      @include block-link-focus(0);
      cursor: default;
    }

    a.search {
      display: none;
    }

    a.d-none {
      display: none;
    }

    a.d-xxxl-block {
      @include respond-to(xxxl) {
        display: block;
      }
    }

    a.d-xxxl-none {
      @include respond-to(xxxl) {
        display: none;
      }
    }

    li:hover,
    li.sfHover,
    li:hover ul,
    li.sfHover ul {
      z-index: 499;
    }

    li:hover > ul,
    li.sfHover > ul {
      left: 0;
      top: $layout-swap-height;
      @include rtl() {
        left: auto;
        right: 0;
      }
    }

    li li:hover > ul,
    li li.sfHover > ul {
      left: 8em;
      top: 0;

      @include rtl() {
        left: auto;
        right: 8em;
      }
    }

    a.sf-with-ul,
    span.nolink.sf-with-ul {
      min-width: 1px;
    }

    .sf-sub-indicator {
      display: none;
      @include respond-to(xl) {
        background-image: url('../images/arrow-nav-depth-1.svg');
        display: block;
        height: 15px;
        overflow: hidden;
        position: absolute;
        right: 4px;
        text-indent: -9999px;
        top: calc(50% - 3px);
        transition: transform .1s ease-in-out;
        transform: translateY(-50%);
        width: 8px;

        .yellow-black & {
          background-image: url('../images/arrow-nav-depth-1-yellow.svg');
        }
      }

      @include rtl() {
        left: 0;
        right: auto;
      }
    }

    a:focus > .sf-sub-indicator,
    a:hover > .sf-sub-indicator,
    a:active > .sf-sub-indicator,
    li:hover > a > .sf-sub-indicator,
    li.sfHover > a > .sf-sub-indicator,
    span.nolink:hover > .sf-sub-indicator,
    li:hover > span.nolink > .sf-sub-indicator,
    li.sfHover > span.nolink > .sf-sub-indicator {
      transform: translateY(-50%) rotate(90deg);
      @include rtl() {
        transform: translateY(-50%) rotate(270deg);
      }
    }

    ul .sf-sub-indicator {
      background-image: url('../images/arrow-nav-depth-2.svg');
      display: block;
      height: 8px;
      overflow: hidden;
      position: absolute;
      right: 2px;
      text-indent: -9999px;
      top: calc(50% - 3px);
      transition: none;
      transform: translateY(-50%);
      width: 15px;
      .yellow-black & {
        background-image: url('../images/arrow-nav-depth-2-yellow.svg');
      }
    }

    ul a > .sf-sub-indicator,
    ul span.nolink > .sf-sub-indicator {
      background-position: top center;
      transform: none;
    }

    ul a:focus > .sf-sub-indicator,
    ul a:hover > .sf-sub-indicator,
    ul a:active > .sf-sub-indicator,
    ul li:hover > a > .sf-sub-indicator,
    ul li.sfHover > a > .sf-sub-indicator,
    ul li.active-trail > a > .sf-sub-indicator,
    ul li.active-trail > span.nolink > .sf-sub-indicator,
    ul span.nolink:hover > .sf-sub-indicator {
      background-position: bottom center;
      transform: none;
    }

    ul li:hover > span.nolink > .sf-sub-indicator,
    ul li.sfHover > span.nolink > .sf-sub-indicator {
      transform: none;
    }
  }
}
