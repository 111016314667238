%date-nav {
  text-align: center;

  ul.pager {
    display: inline-block;
    margin: 0;
    list-style: none;
    vertical-align: middle;
  }

  .date-heading {
    display: inline-block;
    min-width: 236px;
    vertical-align: middle;
    @include zen-respond-to(xs) {
      min-width: 276px;
    }
    @include zen-respond-to(m) {
      min-width: 400px;
    }

    h3 {
      @include fluid-type(32px, 48px);
      color: color(brand);
      line-height: 1;
      margin: .333em 0;

      .yellow-black & {
        color: color(yellow);
      }

      a {
        text-decoration: none;
      }
    }
  }
  .date-prev,
  .date-next {
    a {
      background-image: url('../images/arrows-date-nav.svg');
      background-repeat: no-repeat;
      display: block;
      height: 22px;
      overflow: hidden;
      text-indent: -9999px;
      width: 18px;

      &:focus-visible {
        outline: 2px solid color(brand);
        outline-offset: 2px;
      }
    }
  }
  .date-prev {
    a {
      background-position: left top;

      .yellow-black & {
        background-position: left bottom;
      }

      &:hover {
        background-position: left -22px;

        .yellow-black & {
          background-position: left bottom;
        }
      }
    }
  }
  .date-next {
    a {
      background-position: right top;

      .yellow-black & {
        background-position: right bottom;
      }

      &:hover {
        background-position: right -22px;

        .yellow-black & {
          background-position: right bottom;
        }
      }
    }
  }
}
