// sass-lint:disable no-misspelled-properties
.node-type-page {
  &.page-node-1616 {
    .sf-menu.sf-horizontal {
      .newsletter {
        opacity: .1;
        pointer-events: none;
      }
    }
  }

  .glyph {
    @extend %glyph-oval;
  }

  &.yellow-black {
    .glyph {
      @extend %glyph-oval-yellow;
    }
  }
}

.node-page {
  &.view-mode-full {
    margin: 0 auto;
    max-width: 1000px;
  }

  .field-body {
    @include fluid-type(18px, 20px);
    @include respond-to(m) {
      @include reset-margin-first-last-element;
      margin-bottom: 2em;
    }
    @include respond-to(l) {
      margin-bottom: 3em;
    }
    @include respond-to(xl) {
      margin-bottom: 4em;
    }

    h2 {
      @include fluid-type(24px, 36px);
    }
  }

  .hscroll {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    table {
      margin: 0 0 1em;
      @include respond-to(m) {
        margin-bottom: 2em;
      }
    }

    & ~ .hscroll {
      .table-swipe-message {
        top: .75em;
      }

      table {
        margin-top: 1em;
        @include respond-to(m) {
          margin-top: 2em;
        }
      }
    }

    .table-swipe-message {
      background-color: rgba(255, 255, 255, .9);
      -webkit-box-shadow: 0 20px 40px 0 rgba(255, 255, 255, 1);
      -moz-box-shadow: 0 20px 40px 0 rgba(255, 255, 255, 1);
      box-shadow: 0 20px 40px 0 rgba(255, 255, 255, 1);
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 1;

      &__container {
        padding: 2em;

        .fa {
          color: color('grey');
          font-size: em(40);
        }
      }

      &__content {
        span {
          color: color('grey');
          display: inline-block;
          font-size: em(20);
          font-weight: $font-weight-bold;
          margin-top: 1em;
        }
      }

      &__button {
        background-color: color(brand);
        border: 1px solid color(grey-light);
        color: color(white);
        cursor: pointer;
        display: inline-block;
        font-weight: $font-weight-bold;
        margin-top: 1em;
        padding: .35em .5em .25em;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }

  table {
    &.cennik {
      width: 100%;

      caption {
        border-bottom: 4px solid color(black);
        font-size: em(24px);
        font-weight: $font-weight-bold;
        padding-bottom: 10px;
        text-align: left;
        @include respond-to(m) {
          text-align: center;
        }
      }

      th {
        border: 0;
        padding: 0 5px;
      }

      th[scope='colgroup'] {
        background-color: color(grey-extra-light);
        border: 0;
        color: color(brand);
        font-weight: $font-weight-bold;
        height: 40px;
      }

      .border-none {
        border: 0;

        th[scope='colgroup'] {
          position: relative;

          &::before {
            background-color: color(white);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 2px;
          }
        }
      }

      thead {
        tr {
          border-bottom: 0;
        }

        td {
          height: 40px;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid color(black);

          &.row-header {
            border: 0;
            height: 60px;

            th {
              background-color: color(grey-extra-light);
              border: 1px solid color(grey-extra-light);
              border-right: 1px solid color(white);
              color: color(black);
              display: inline-block;
              height: auto;
              margin: 10px 0 2px;
              padding: .35em .5em .25em;
              @include respond-to(m) {
                height: 40px;
                line-height: 40px;
                padding-bottom: 0;
                padding-top: 0;
              }

              a {
                @include link(color(white), color(white), color(white));
                text-decoration: none;
              }
            }
          }
        }

        th {
          height: 60px;
        }

        td {
          height: 60px;
          text-align: center;

          a {
            @include typeface(heading);
            @include link(color(black), color(black), color(brand));
            display: inline-block;
            font-size: em(19px);
            // text-align: left;
            text-decoration: none;
          }

          &.with-background {
            background-color: color(grey-extra-light);
            height: 40px;
          }
        }
      }
    }
  }

  .sections {
    .content-section {
      & + .content-section {
        margin-top: 2em;
        @include respond-to(l) {
          margin-top: 4em;
        }
      }

      &__title {
        @include fluid-type(24px, 32px);
        margin-top: 0;
      }

      &__left {
        @include respond-to(m) {
          float: left;
          width: 50%;
          @include rtl() {
            float: right;
          }
        }

        .content-wrapper {
          @include respond-to(m) {
            padding-right: 1em;
          }
          @include respond-to(l) {
            padding-right: 2em;
          }
        }
      }

      &__right {
        @include respond-to(m) {
          float: right;
          width: 50%;
          @include rtl() {
            float: left;
          }
        }

        .content-wrapper {
          @include respond-to(m) {
            padding-left: 1em;
          }
          @include respond-to(l) {
            padding-left: 2em;
          }
        }
      }

      &.one-column {
        .content-section__left,
        .content-section__right {
          width: 100%;
          float: none;

          .content-wrapper {
            padding: 0;
          }
        }
      }

      .content-wrapper {
        @include fluid-type(18px, 20px);
        @include respond-to(m) {
          @include reset-margin-first-last-element;
        }
      }

      h3 {
        margin: 1.5em 0 .75em;

        & + p,
        & + ul,
        & + ol {
          margin-top: 0;
        }
      }
    }
  }

  .group-attachments {
    @extend %group-attachments;
  }

  .related-content-header {
    @include fluid-type(24px, 36px);
    line-height: 1;
    margin: 2em 0 1em;
    position: relative;

    &::before {
      background-color: color(brand);
      content: '';
      display: block;
      height: 4px;
      margin: 0 0 .5em;
      width: 40%;
      @include respond-to(m) {
        height: 6px;
        left: 0;
        position: absolute;
        top: 50%;
        width: 100%;
      }

      .yellow-black & {
        background-color: color(yellow);
      }
    }

    span {
      background-color: color(white);
      line-height: 1.2;
      padding: 0 20px 0 0;
      position: relative;
      z-index: 1;

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
      }
    }

    & + .related-content {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }

  .related-content {
    border-top: 6px solid color(brand);
    margin-top: 2em;
    padding-top: 1em;
    @include respond-to(s) {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include respond-to(l) {
      gap: 48px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      margin-top: 4em;
      padding-top: 2em;
    }

    .yellow-black & {
      border-color: color(yellow);
    }

    .related-content-item {
      margin-bottom: 2em;
      @include respond-to(s) {
        margin-bottom: 0;
      }
    }
  }
}
