.block-language-switcher-fallback {
  .language-switcher-locale-url {
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-left: 2px solid color(grey-dark);
      display: inline-block;
      font-size: $base-font-size;

      .yellow-black & {
        border-color: color(yellow);

        @include rtl() {
          border-color: color(yellow);
        }
      }

      &.first {
        border-left: 0;
      }

      &.active {
        border-left: 5px solid color(white);
        margin-right: 5px;

        .yellow-black & {
          border-color: color(text-bg-contrast-yb);
        }

        .language-link {
          border-bottom: 2px solid color(brand);

          .yellow-black & {
            border-color: color(yellow);
          }
        }

        &.first {
          border-left: 0;
        }

        & + li {
          border-left: 0;
        }
      }

      @include rtl() {
        border-left: 0;
        border-right: 2px solid color(grey-dark);

        &.first {
          border-right: 0;
        }

        &.active {
          &.last {
            border-right: 0;
          }
        }
      }
    }

    .language-link {
      @include block-link-focus(-2px);
      border-bottom: 2px solid color(grey-dark);
      color: color(grey-dark);
      display: block;
      font-size: em(18px);
      font-weight: $font-weight-regular;
      line-height: 50px;
      min-width: 48px;
      padding: 0 12px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      .yellow-black & {
        border-color: color(yellow);
        color: color(yellow);
      }

      &.active {
        @include block-link-focus(2px);
        background-color: color(brand);
        color: color(white);

        .yellow-black & {
          background-color: color(yellow);
          color: color(text-bg-contrast-yb);
        }
      }

      &.locale-untranslated {
        text-decoration: line-through;
      }
    }
  }
}
