.page-views-search-results {

  .main-content {
    .exposed-form-search-results-page {
      @include zen-grid-container($context: flow);
      .content {
        @include zen-grid-item(1, 1);
        @include zen-grid-item-base();
        @include zen-respond-to(xl) {
          @include zen-grid-item(14, 3);
        }
      }

      .views-exposed-widget {
        padding: 1em 0;
        .form-item {
          .form-text {
            width: 230px;
            @include respond-to(xs) {
              width: 270px;
            }
            @include respond-to(m) {
              width: 320px;
            }
          }
        }
      }
    }
  }
}

.view-tz-search-results {
  margin-top: 2em;
  @include respond-to(m) {
    margin-top: 4em;
  }

  .view-header {
    font-size: em(18px);
    font-weight: $font-weight-regular;
  }

  .view-content {
    .views-row {
      & + .views-row {
        margin-top: 2em;
      }
    }
    h2.title {
      a {
        @include link(color(brand), color(brand), color(black));
        text-decoration: none;
      }
      strong {
        font-weight: $font-weight-bold;
        font-style: italic;
      }
    }
    .node-body {
      a {
        @include link(color(brand), color(brand), color(black));
        display: inline-block;
        margin-left: 4px;
      }
    }
  }

  .pager {
    @extend %pager;
    margin-top: 2em;
    @include respond-to(m) {
      margin-top: 4em;
    }
  }
}
