.view-ticket-thx {

  .glyph {
    @extend %glyph-hex;
  }

  .view-content {
    @include font-size(18px);
  }
}
