@mixin block-link-focus($offset: 2px, $color: color(brand)) {
  &:focus-visible {
    outline: 2px solid $color;
    outline-offset: $offset;

    .yellow-black & {
      outline-color: color(yellow);
    }
  }
}
