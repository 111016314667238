@mixin link($link: color(black), $visited: color(black), $hover: color(brand)) {
  &:link {
    color: $link;
  }

  &:visited {
    color: $visited;
  }

  &:hover {
    color: $hover;
  }

  &:active {
    color: $hover;
  }

  &:focus-visible {
    color: $hover;
    outline: 2px solid color(brand);
    outline-offset: 2px;
  }

  .yellow-black & {
    &:link {
      color: color(yellow);
    }

    &:visited {
      color: color(yellow);
    }

    &:hover {
      color: color(yellow);
      text-decoration: underline;
    }

    &:active {
      color: color(yellow);
    }

    &:focus-visible {
      color: color(yellow);
      outline: 2px solid color(yellow);
    }
  }
}
