.field-group-div {
  &.section {
    @include block-link-focus;
    clear: both;
    margin-top: 2em;
    @include respond-to(m) {
      margin-top: 3em;
    }
    @include respond-to(l) {
      margin-top: 4em;
    }
    @include respond-to(xl) {
      margin-top: 5em;
    }

    & > h2 {
      @extend %section-title;
      margin: 0 0 1em;
      position: relative;

      &::before {
        background-color: color(brand);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 4px;
        width: 100%;
        @include respond-to(m) {
          height: 6px;
        }

        .yellow-black & {
          background-color: color(yellow);
        }
      }

      span {
        background-color: color(white);
        padding: 0 20px;
        position: relative;
        z-index: 1;

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
        }
      }
    }
  }
}
