.block-tz-sign-language-interpreter {
  h2 {
    @extend %visually-hidden;
  }

  .content {
    a {
      @include block-link-focus;
      background-image: url('../images/symbol-tlumacza-white.png');
      background-size: cover;
      display: block;
      height: 42px;
      width: 50px;
      @include zen-respond-to(l) {
        background-image: url('../images/symbol-tlumacza.png');
      }

      .yellow-black & {
        background-image: url('../images/symbol-tlumacza-hc.png');
      }
    }
  }
}
