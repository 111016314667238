/* Views pager */
%pager {
  border-top: 6px solid color(brand);
  font-size: em(18px);
  font-weight: $font-weight-regular;
  margin: 0;
  padding: 15px 0;

  .yellow-black & {
    border-top-color: color(yellow);
  }

  .pager-current {
    color: color('grey');

    .yellow-black & {
      border-bottom: 2px solid color(yellow);
      color: color(yellow);
    }
  }

  a {
    @include link();
    @include block-link-focus;
    text-decoration: none;
    transition: color .3s ease-in-out;
  }
}
