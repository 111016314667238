.region-header-section-second {
  .social-links {
    display: none;
    left: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    @include rtl() {
      left: auto;
      right: 0;
      transform: translate(50%, -50%);
    }
    @include breakpoint(1366px) {
      display: block;
      left: calc((50% - (#{map-get($max-width, xl)} / 2)) / 2);
      @include rtl() {
        left: auto;
        right: calc((50% - (#{map-get($max-width, xl)} / 2)) / 2);
      }
    }
    @include zen-respond-to(xxl) {
      left: calc((50% - (#{map-get($max-width, xxl)} / 2)) / 2);
      @include rtl() {
        left: auto;
        right: calc((50% - (#{map-get($max-width, xxl)} / 2)) / 2);
      }
    }
    @include zen-respond-to(xxxl) {
      left: calc((50% - (#{map-get($max-width, xxxl)} / 2)) / 2);
      @include rtl() {
        left: auto;
        right: calc((50% - (#{map-get($max-width, xxxl)} / 2)) / 2);
      }
    }
    @include zen-respond-to(xxxxl) {
      left: calc((50% - (#{map-get($max-width, xxxxl)} / 2)) / 2);
      @include rtl() {
        left: auto;
        right: calc((50% - (#{map-get($max-width, xxxxl)} / 2)) / 2);
      }
    }

    .item-list {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &::before {
          background-color: color(black);
          content: '';
          position: absolute;
          height: 100%;
          width: 2px;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          z-index: -1;

          .yellow-black & {
            background-color: color(yellow);
          }
        }

        li {
          margin-bottom: 30px;

          &.last {
            margin-bottom: 0;
          }

          /* override modules/system/system.theme-rtl.css */
          @include rtl() {
            margin-right: 0;
          }
        }
      }

      a {
        @include block-link-focus();
        background-color: color(black);
        border-radius: 50%;
        color: color(white);
        display: block;
        height: 40px;
        overflow: hidden;
        width: 40px;
        text-align: center;

        .yellow-black & {
          background-color: color(text-bg-contrast-yb);
          border: 2px solid color(yellow);
          color: color(yellow);
        }

        &.portal-250-na-65 {
          background-image: url('../images/250-na-65.png');
          background-repeat: no-repeat;
          background-size: 40px 40px;

          .yellow-black & {
            background-image: url('../images/250-na-65-yellow.png');
          }

          &:hover {
            background-color: color(brand);
          }
        }

        &:hover {
          background-color: color(brand);

          .yellow-black & {
            background-color: color(text-bg-contrast-yb);
            color: color(yellow);
          }
        }

        span {
          font-size: 0;
        }

        .fa {
          font-size: 20px;
          line-height: 40px;
        }
      }
    }
  }
}
