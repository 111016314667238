.node-advanced-page,
.node-article,
.node-wydarzenie {
  &.view-mode-teaser {
    .group-content {
      position: relative;

      &.active {
        .group-image {
          img {
            opacity: .1;
          }

          .glyph {
            display: block;
          }
        }

        .title {
          color: color(brand);

          .yellow-black & {
            color: color(yellow);
          }
        }

        .read-more-label {
          span {
            background-color: color(brand);
            color: color(white);

            .yellow-black & {
              background-color: color(yellow);
              color: color(text-bg-contrast-yb);
            }
          }
        }
      }
    }

    .group-image {
      position: relative;

      img {
        display: block;
        transition: opacity .3s ease-in-out;
      }

      .glyph {
        background-image: url('../images/glyph-eye.svg');
        background-position: center center;
        background-size: cover;
        display: none;
        height: 111px;
        left: 50%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 221px;
        @include respond-to(s) {
          height: 100px;
          width: 200px;
        }
        @include respond-to(m) {
          height: 111px;
          width: 221px;
        }

        .yellow-black & {
          background-image: url('../images/glyph-eye-yellow.svg');
        }
      }

      & + .title {
        border-top: 0;
      }
    }

    .title {
      @include fluid-type(20px, 30px);
      border-top: 6px solid color(black);
      margin-top: 0;
      padding-top: .5em;
      transition: background-color .3s ease-in-out, color .3s ease-in-out;

      .yellow-black & {
        border-top-color: color(yellow);
      }
    }

    .field-name-body {
      margin: 1.5rem 0;

      p {
        margin: 0;
      }
    }

    .read-more-label {
      text-align: center;

      span {
        border: 1px solid color(brand);
        color: color(brand);
        display: inline-block;
        font-family: $inter-ui;
        font-size: em(14px);
        font-weight: $font-weight-bold;
        letter-spacing: .075em;
        padding: .35em .5em .25em;
        text-transform: uppercase;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;

        .yellow-black & {
          border-color: color(yellow);
          color: color(yellow);
        }
      }
    }

    .read-more-link {
      @include coverer;
      @include block-link-focus;
      font-size: 0;
    }
  }
}
