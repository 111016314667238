.node-type-node-gallery-gallery {}

.view-node-gallery-gallery-item-views {
  margin-top: 2em;

  .view-content {
    text-align: center;

    .views-row {
      display: inline-block;

      .file-image {
        position: relative;
      }

      .colorbox {
        display: block;
        margin: 10px;
        @include respond-to(m) {
          margin-bottom: 25px;
          margin-top: 25px;
        }

        &::before {
          background-image: url('../images/plus.svg');
          background-repeat: no-repeat;
          background-size: cover;
          content: '';
          height: 72px;
          left: 50%;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: opacity .3s ease-in-out;
          width: 72px;
          .yellow-black & {
            background-image: url('../images/plus-yellow.svg');
          }
        }

        &:hover {
          &::before {
            opacity: 1;
          }

          img {
            opacity: .1;
          }
        }
      }

      img {
        display: block;
        transition: opacity .3s ease-in-out;
        @include respond-to(s) {
          height: 150px;
          width: auto;
        }
      }
    }
  }
}

.node-node-gallery-gallery {
  // Full mode
  &.view-mode-full {
    margin-top: 2em;

    .glyph {
      @extend %glyph-eye;
    }

    .title {
      @extend %h1-title;
    }

    .gallery-type {
      display: none;
    }

    .photographer {
      font-style: italic;
      padding: 35px 0;
      position: relative;
      text-align: center;

      &::before,
      &::after {
        background-color: color(brand);
        content: '';
        position: absolute;
        left: 50%;
        height: 30px;
        width: 6px;
        transform: translateX(-50%);
        .yellow-black & {
          background-color: color(yellow);
        }
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      .label-inline {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }

  &.ds-2col-stacked-fluid {
    .group-header {
      @include zen-respond-to(xl) {
        margin-bottom: 2em;
      }
    }

    &.group-one-column {
      .group-left,
      .group-right {
        width: 100%;
        float: none;
      }
    }

    .group-footer {
      clear: both;
    }
  }

  // Teaser mode
  &.node-teaser {
    .group-content {
      position: relative;

      &:hover {
        .group-image {
          &::before {
            opacity: 1;

            .yellow-black & {
              opacity: 0;
            }
          }

          &::after {
            opacity: 1;
          }

          img {
            .yellow-black & {
              opacity: .1;
            }
          }
        }
        .title {
          color: color(brand);

          .yellow-black & {
            color: color(yellow);
          }
        }
      }
    }

    .group-image {
      position: relative;

      &::before {
        background: linear-gradient(rgba(26, 25, 214, .1), rgba(26, 25, 214, .5), rgba(26, 25, 214, 1));
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity .3s ease-in-out;
        width: 100%;
      }

      &::after {
        background-image: url('../images/glyph-eye-white.svg');
        background-position: center center;
        background-size: cover;
        content: '';
        height: 111px;
        left: 50%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .3s ease-in-out;
        width: 221px;

        .yellow-black & {
          background-image: url('../images/glyph-eye-yellow.svg');
        }
      }
    }

    img {
      display: block;
    }

    .photographer {
      background-color: color(white);
      font-style: italic;
      padding: 2px 20px 2px 10px;
      position: absolute;
      bottom: 0;
      left: 0;

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
      }

      .label-inline {
        display: inline-block;
        margin-right: 4px;
      }
    }

    .title {
      @include fluid-type(24px, 30px);
      text-transform: uppercase;
      transition: color .3s ease-in-out;
    }

    .view-more {
      position: absolute;
      height: calc(100% - 2px);
      left: 1px;
      overflow: hidden;
      text-indent: -9999px;
      top: 1px;
      width: calc(100% - 2px);

      &:focus-visible {
        outline: 1px dotted color(brand);

        .yellow-black & {
          outline: 1px dotted color(yellow);
        }
      }
    }
  }
}
