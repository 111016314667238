%gradient-layer {
  @include zen-respond-to(m) {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

%gradient-layer-frontpage-slider {
  background: linear-gradient(rgba(26, 25, 214, 0), rgba(26, 25, 214, 0), rgba(26, 25, 214, 1));
  content: '';
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  .yellow-black & {
    background: linear-gradient(rgba(color(text-bg-contrast-yb), 0), rgba(color(text-bg-contrast-yb), 0), rgba(color(text-bg-contrast-yb), 1));
  }
}

%gradient-layer-node-gallery-embedded {
  @extend %gradient-layer;
  @include zen-respond-to(m) {
    background: linear-gradient(rgba(255, 255, 255, .1), rgba(255, 255, 255, .5), rgba(26, 25, 214, 1));

    .yellow-black & {
      background: linear-gradient(rgba(color(text-bg-contrast-yb), .1), rgba(color(text-bg-contrast-yb), .5), rgba(color(text-bg-contrast-yb), 1));
    }
  }
}
