.node-type-podcast {
  .glyph {
    @extend %glyph-oval;
  }

  &.yellow-black {
    .glyph {
      @extend %glyph-oval-yellow;
    }
  }
}

.node-podcast {
  &.view-mode-full {
    margin: 0 auto;
    max-width: 1000px;
  }

  .field-body {
    margin-top: 2em;
    @include respond-to(m) {
      @include reset-margin-first-last-element;
    }
    @include respond-to(l) {
      margin-top: 3em;
    }
    @include respond-to(xl) {
      margin-top: 4em;
    }

    h2 {
      @include fluid-type(24px, 36px);
    }
  }
}
