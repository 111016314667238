.page-views-podcasts {}

.view-podcasts {
  & > .glyph {
    @extend %glyph-oval;
  }

  .yellow-black & {
    & > .glyph {
      @extend %glyph-oval-yellow;
    }
  }

  .view-content {
    .views-row {
      border-bottom: 1px solid color(brand);
      margin-top: 2em;
      padding-bottom: 1em;
      .yellow-black & {
        border-color: color(yellow);
      }
    }
    .group-content {
      position: relative;
      &.active {
        h3.title {
          color: color(brand);
          .yellow-black & {
            color: color(yellow);
          }
        }
        .read-more-label {
          span {
            border-bottom: 1px solid color(brand);
            .yellow-black & {
              border-color: color(yellow);
            }
          }
        }
      }
    }

    h3.title {
      @include fluid-type(24px, 30px);
      text-transform: uppercase;
      a {
        @include link();
        text-decoration: none;
      }
    }

    .field-post-date {
      display: inline-block;
    }

    .field-duration {
      display: inline-block;
      &::before {
        content: '|';
        display: inline-block;
        font-size: em(14px);
        line-height: 1;
        margin: 0 .5em;
        vertical-align: middle;
      }
      .label-inline {
        display: inline-block;
      }
    }

    .read-more-label {
      margin-top: .5em;
      span {
        color: color(brand);
        display: inline-block;
        font-family: $inter-ui;
        font-size: em(14px);
        font-weight: $font-weight-bold;
        letter-spacing: .075em;
        text-transform: uppercase;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        .yellow-black & {
          color: color(yellow);
        }
      }
    }

    .read-more-link {
      @include coverer;
      @include block-link-focus;
      font-size: 0;
    }
  }
}
