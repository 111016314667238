.node-osoba {
  &.ds-2col-stacked-fluid {
    & > .glyph {
      @extend %glyph-lt;
    }

    .group-left {
      text-align: center;
      @include respond-to(m) {
        float: left; /* LTR */
        width: 30%;
      }

      .portrait {
        a {
          @include block-link-focus;
          display: inline-block;
        }

        img {
          display: block;
          margin: 0 auto;
        }
      }
    }

    .group-right {
      @include respond-to(m) {
        float: right; /* LTR */
        width: 70%;
      }

      .field-name-body {
        @include respond-to(m) {
          @include reset-margin-first-last-element;
          padding-left: 20px;
        }
        @include respond-to(l) {
          padding-left: 32px;
        }
        @include respond-to(xl) {
          padding-left: 48px;
        }
      }
    }

    &.group-one-column {
      .group-left,
      .group-right {
        width: 100%;
        float: none;
      }
    }

    .group-footer {
      clear: both;
      padding-top: 2em;
    }
  }

  .title {
    @include respond-to(m) {
      margin-top: 0;
      padding-left: 20px;

      .page-node-414 &,
      .page-node-424 &,
      .page-node-5167 & {
        margin-left: 20px;
      }
    }

    @include respond-to(l) {
      padding-left: 32px;

      .page-node-414 &,
      .page-node-424 &,
      .page-node-5167 & {
        margin-left: 32px;
      }
    }

    @include respond-to(xl) {
      padding-left: 48px;

      .page-node-414 &,
      .page-node-424 &,
      .page-node-5167 & {
        margin-left: 48px;
      }
    }

    .page-node-414 &,
    .page-node-424 &,
    .page-node-5167 & {
      border: 3px solid color(black);
      display: inline-block;
      padding: 0 .25em;
    }

    .yellow-black & {
      border-color: color(yellow);
    }
  }

  .group-roles {
    text-align: center;

    .role-list {
      display: inline-block;
      list-style: none;
      margin: 0 auto;
      padding: 0;

      li {
        font-size: em(18px);
        padding: .25em 0;
        text-align: left;

        a {
          @include link(color(black), color(black), color(brand));
          border-bottom: 2px solid color(brand);
          font-weight: $font-weight-bold;
          text-decoration: none;

          .yellow-black & {
            border-color: color(yellow);
          }

          &:hover {
            .yellow-black & {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
