/* Views current page number */
%current-page {
  @include typeface(heading);
  font-size: em(48px);
  display: inline-block;
  margin-bottom: 15px;
  @media print {
    display: none;
    visibility: hidden;
  }

  &::after {
    background-color: color(brand);
    content: '';
    display: inline-block;
    height: 5px;
    margin-left: 10px;
    width: 50px;

    .yellow-black & {
      background-color: color(yellow);
    }
  }
}
