.node-type-wydarzenie {
  .glyph {
    @extend %glyph-hand;
  }

  &.yellow-black {
    .glyph {
      @extend %glyph-hand-yellow;
    }
  }
}

.node-wydarzenie {
  &.ds-2col-stacked-fluid {
    .group-left {
      @include respond-to(m) {
        float: left; /* LTR */
        width: 30%;
      }
    }
    .group-right {
      @include respond-to(m) {
        float: right; /* LTR */
        width: 70%;
      }

      .field-name-body {
        @include respond-to(m) {
          @include reset-margin-first-last-element;
          padding-left: 1em;
        }
        @include respond-to(l) {
          padding-left: 2em;
        }
        @include respond-to(xl) {
          padding-left: 3em;
        }
      }
    }
    &.group-one-column {
      .group-left,
      .group-right {
        width: 100%;
        float: none;
      }

      .field-name-body {
        margin: 0 auto;
        max-width: 800px;
        padding-left: 0;
      }
    }

    .group-footer {
      clear: both;
      padding-top: 2em;
    }
  }

  &.view-mode-full {
    .title {
      @include fluid-type(24px, 60px);
      @include respond-to(m) {
        margin-top: 0;
        padding-top: .25em;
        position: relative;
        width: 60%;

        &::before {
          background-color: color(brand);
          content: '';
          height: 6px;
          left: 0;
          position: absolute;
          top: 0;
          width: 280px;

          .yellow-black & {
            background-color: color(yellow);
          }
        }
      }
    }

    .cover {
      @include reset-margin-first-last-element;

      a {
        @include block-link-focus(0);
        display: inline-block;
      }

      img {
        display: block;
      }
    }

    .date {
      margin: .5em 0 1.5em;

      .date-repeat-rule {
        @include visually-hidden;
      }

      .date-display-single {
        display: inline-block;
        font-size: 1.5em;

        &:only-child {
          border-bottom: 2px solid color(black);

          .yellow-black & {
            border-color: color(yellow);
          }
        }

        & + .date-display-single {
          &::before {
            content: '/';
            display: inline-block;
            margin: 0 .5rem;
          }
        }
      }
    }

    .field-name-body {
      .image-caption {
        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          @include respond-to(m) {
            width: 80%;
          }
        }
      }

      .caption {
        @include respond-to(m) {
          width: 80%;
        }
      }
    }

    .caption {
      color: color(grey-dark);
      display: block;
      font-size: em(14px);
      line-height: 1.1;
      margin: 5px auto;
    }

    .group-images {
      @extend %group-images;
    }

    .group-gallery {
      h2 {
        border-bottom: 4px solid color(black);
        @include respond-to(l) {
          max-width: calc(33.3333% - 16px);
        }
      }

      .item-list {
        @include respond-to(s) {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .node-node-gallery-gallery {
        margin-bottom: 2em;
        @include respond-to(s) {
          width: 50%;

          &:nth-child(2n + 1) {
            .group-content {
              margin-right: 12px;
            }
          }

          &:nth-child(2n + 2) {
            .group-content {
              margin-left: 12px;
            }
          }
        }

        @include respond-to(l) {
          width: 33.3333%;

          &:nth-child(3n + 1) {
            .group-content {
              margin-right: 16px;
              margin-left: 0;
            }
          }

          &:nth-child(3n + 2) {
            .group-content {
              margin-left: 8px;
              margin-right: 8px;
            }
          }

          &:nth-child(3n + 3) {
            .group-content {
              margin-left: 16px;
              margin-right: 0;
            }
          }
        }
      }
    }

    .group-attachments {
      @extend %group-attachments;
    }
  }
}
