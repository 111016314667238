@import url("https://fonts.googleapis.com/css?family=Overpass:200,200i,300,300i,400,400i,600,600i,700,700i&display=swap&subset=latin-ext");
@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-UI-Regular.woff2") format("woff2"), url("../fonts/Inter-UI-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-UI-Italic.woff2") format("woff2"), url("../fonts/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-UI-Medium.woff2") format("woff2"), url("../fonts/Inter-UI-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-UI-MediumItalic.woff2") format("woff2"), url("../fonts/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-UI-Bold.woff2") format("woff2"), url("../fonts/Inter-UI-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-UI-BoldItalic.woff2") format("woff2"), url("../fonts/Inter-UI-BoldItalic.woff") format("woff");
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Overpass", sans-serif;
  font-weight: 200;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

body {
  color: #000;
  background-color: #fff;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

body.noscroll {
  overflow-y: hidden;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0000fe;
  -webkit-text-decoration-skip: objects;
}

.yellow-black a {
  color: #fd0;
}

:visited {
  color: #000098;
}

.yellow-black :visited {
  color: #fd0;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  /*
  a[href] {
    &::after {
      content: ' (' attr(href) ')';
      font-weight: normal;
      font-size: $base-font-size;
      text-decoration: none;
    }
  }
  */
  /*
  a[href^='javascript:'],
  a[href^='#'] {
    &::after {
      content: '';
    }
  }
  */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  line-height: 1.25em;
}

h1,
.header__site-name {
  font-size: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

strong,
b {
  font-weight: normal;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.75rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: gray;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: gray;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-18col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-18col:before {
  content: "";
  display: table;
}

.layout-18col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-18col__col-1, .layout-18col__col-2, .layout-18col__col-3 {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-18col__col-1, [dir="rtl"] .layout-18col__col-2, [dir="rtl"] .layout-18col__col-3 {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 768px) {
  .layout-18col {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-18col:before {
    content: "";
    display: table;
  }
  .layout-18col:after {
    content: "";
    display: table;
    clear: both;
  }
}

@media (min-width: 1280px) {
  .layout-18col__col-1 {
    clear: both;
    float: left;
    width: 11.11111%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-18col__col-1 {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-18col__col-2 {
    clear: none;
    float: left;
    width: 77.77778%;
    margin-left: 11.11111%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-18col__col-2 {
    float: right;
    margin-right: 11.11111%;
    margin-left: -100%;
  }
  .layout-18col__col-3 {
    clear: none;
    float: left;
    width: 11.11111%;
    margin-left: 83.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-18col__col-3 {
    float: right;
    margin-right: 83.33333%;
    margin-left: -100%;
  }
}

.layout-18col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-18col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-18col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 1280px) {
  .layout-18col--with-border {
    min-height: 300px;
    position: relative;
  }
  .layout-18col--with-border::before {
    background-color: #0000fe;
    content: '';
    display: flex;
    position: absolute;
    left: 5.35%;
    top: 0;
    height: 100%;
    width: 7px;
  }
  .yellow-black .layout-18col--with-border::before {
    background-color: #fd0;
  }
  [dir="rtl"] .layout-18col--with-border::before {
    right: 5.35%;
  }
}

.layout-18col--with-border .title-wrapper {
  display: none;
  position: relative;
  text-align: center;
}

@media (min-width: 1280px) {
  .layout-18col--with-border .title-wrapper {
    display: block;
    text-align: left;
  }
}

.layout-18col--with-border .title-wrapper::before {
  background-color: #0000fe;
  content: '';
  position: absolute;
  left: 20px;
  top: 50%;
  height: 7px;
  width: calc(100% - 40px);
}

.yellow-black .layout-18col--with-border .title-wrapper::before {
  background-color: #fd0;
}

@media (min-width: 1280px) {
  .layout-18col--with-border .title-wrapper::before {
    content: none;
  }
}

.layout-18col--with-border .title-wrapper h2 {
  font-size: 1.5rem;
  background-color: #fff;
  color: #0000fe;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  position: relative;
  text-transform: uppercase;
}

.yellow-black .layout-18col--with-border .title-wrapper h2 {
  background-color: #1e1e1e;
  color: #fd0;
}

@media (min-width: 1280px) {
  .layout-18col--with-border .title-wrapper h2 {
    margin: 0;
    padding: 0 0 0 10px;
    position: absolute;
    left: 50%;
    top: -1px;
    transform: rotate(270deg) translateX(-100%) translateY(-50%);
    transform-origin: top left;
    white-space: nowrap;
  }
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .layout-center {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1280px) {
  .layout-center {
    max-width: 1200px;
  }
}

@media (min-width: 1440px) {
  .layout-center {
    max-width: 1280px;
  }
}

@media (min-width: 1680px) {
  .layout-center {
    max-width: 1440px;
  }
}

@media (min-width: 1800px) {
  .layout-center {
    max-width: 1620px;
  }
}

.layout-center--shared-grid {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
  padding-top: 40px;
}

@media (min-width: 1024px) {
  .layout-swap {
    padding-top: 60px;
  }
}

.front .layout-swap {
  padding-top: 0;
}

@media (min-width: 1024px) {
  .front .layout-swap {
    padding-top: 60px;
  }
}

@media (min-width: 1024px) {
  .layout-swap__bottom {
    margin-top: -20px;
  }
  .front .layout-swap__bottom {
    margin-top: -40px;
  }
}

.layout-swap__top {
  position: absolute;
  top: 0;
  height: 40px;
  width: 100%;
}

@media (min-width: 1024px) {
  .layout-swap__top {
    height: 60px;
  }
}

.front .layout-swap__top {
  height: 0;
}

@media (min-width: 1024px) {
  .front .layout-swap__top {
    height: 60px;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0000fe;
}

.clearfix::before,
.header::before,
.tabs::before {
  content: '';
  display: table;
}

.clearfix::after,
.header::after,
.tabs::after {
  content: '';
  display: table;
  clear: both;
}

[dir="rtl"] .header__logo {
  float: right;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0000fe;
}

.yellow-black .messages,
.yellow-black .messages--status {
  border-color: #fd0;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0000fe;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: white;
  color: #0000fe;
}

.yellow-black .messages--ok-color,
.yellow-black .messages,
.yellow-black .messages--status,
.yellow-black .ok,
.yellow-black .revision-current {
  background-color: #1e1e1e;
  color: #fd0;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.yellow-black .messages--warning-color,
.yellow-black .messages--warning,
.yellow-black .warning {
  background-color: #1e1e1e;
  color: #fd0;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.yellow-black .messages--error-color,
.yellow-black .messages--error,
.yellow-black .error {
  background-color: #1e1e1e;
  color: #fd0;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title,
.exposed-form-search-results-page .views-exposed-widget label,
.block-tz-sign-language-interpreter h2 {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #f2f2f2;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.nav-menu__item,
.menu__item {
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-type: disc;
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager,
.view-galeria-plakatu .pager,
.view-node-gallery-gallery-summaries .pager,
.view-tz-search-results .pager,
.view-lista-spektakli.lista-spektakli .pager,
.view-lista-spektakli.lista-spektakli-archiwalnych .pager,
.view-news .pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager,
  .view-galeria-plakatu .pager,
  .view-node-gallery-gallery-summaries .pager,
  .view-tz-search-results .pager,
  .view-lista-spektakli.lista-spektakli .pager,
  .view-lista-spektakli.lista-spektakli-archiwalnych .pager,
  .view-news .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: .25em 0;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #000;
  color: #fff;
}

.skip-link:focus {
  outline: none;
}

.skip-link:focus-visible span {
  outline: 2px solid #fff;
}

.yellow-black .skip-link:focus-visible span {
  outline-color: #fd0;
}

.skip-link span {
  display: inline-block;
  font-size: em(20px);
  font-weight: 600;
  padding: .25em .5em 0;
  text-transform: uppercase;
}

.skip-link__wrapper {
  margin: 0;
}

.skip-link__wrapper:focus-within {
  background-color: #000;
  padding: .25em 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  line-height: 1.875rem;
  margin: 2em -4px 1em;
  padding: 0;
  list-style: none;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .tabs {
    margin: 3.75rem 0 1.125rem 0;
    background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
    padding: 0 2px;
  }
}

.yellow-black .tabs {
  background-image: linear-gradient(to top, #fd0 1px, transparent 1px);
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 4px;
  border: 1px solid #cccccc;
  border-bottom-color: transparent;
  overflow: hidden;
  background: #dddddd;
}

@media (min-width: 768px) {
  .tabs__tab {
    margin: 0 3px;
  }
}

.yellow-black .tabs__tab {
  border-color: #fd0;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

@media (min-width: 768px) {
  .tabs__tab.is-active {
    border-bottom-color: #fff;
  }
  .yellow-black .tabs__tab.is-active {
    border-bottom-color: #1e1e1e;
  }
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.yellow-black .tabs__tab-link {
  background-color: #1e1e1e;
  text-shadow: none;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.yellow-black .tabs__tab-link:focus, .yellow-black .tabs__tab-link:hover {
  background-color: #1e1e1e;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.yellow-black .tabs__tab-link:active, .yellow-black .tabs__tab-link.is-active, .yellow-black .tabs__tab-link--is-active {
  background-color: #1e1e1e;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass../forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0000fe;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass../forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: gray;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #f2f2f2;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #e1e1e1;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0000fe url(../sass../forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass../forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass../forms/resizable-textarea/grippie.png) no-repeat center 2px #f2f2f2;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass../forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass../forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass../forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

/* Views current page number */
.view-galeria-plakatu .current-page, .view-node-gallery-gallery-summaries .current-page, .view-lista-spektakli.lista-spektakli .current-page, .view-lista-spektakli.lista-spektakli-archiwalnych .current-page, .view-news .current-page {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  font-size: 3em;
  display: inline-block;
  margin-bottom: 15px;
}

@media print {
  .view-galeria-plakatu .current-page, .view-node-gallery-gallery-summaries .current-page, .view-lista-spektakli.lista-spektakli .current-page, .view-lista-spektakli.lista-spektakli-archiwalnych .current-page, .view-news .current-page {
    display: none;
    visibility: hidden;
  }
}

.view-galeria-plakatu .current-page::after, .view-node-gallery-gallery-summaries .current-page::after, .view-lista-spektakli.lista-spektakli .current-page::after, .view-lista-spektakli.lista-spektakli-archiwalnych .current-page::after, .view-news .current-page::after {
  background-color: #0000fe;
  content: '';
  display: inline-block;
  height: 5px;
  margin-left: 10px;
  width: 50px;
}

.yellow-black .view-galeria-plakatu .current-page::after, .view-galeria-plakatu .yellow-black .current-page::after, .yellow-black .view-node-gallery-gallery-summaries .current-page::after, .view-node-gallery-gallery-summaries .yellow-black .current-page::after, .yellow-black .view-lista-spektakli.lista-spektakli .current-page::after, .view-lista-spektakli.lista-spektakli .yellow-black .current-page::after, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .current-page::after, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .current-page::after, .yellow-black .view-news .current-page::after, .view-news .yellow-black .current-page::after {
  background-color: #fd0;
}

.theatre-calendar .view .date-nav, .view-repertoire.monthly-repertoire .date-nav {
  text-align: center;
}

.theatre-calendar .view .date-nav ul.pager, .view-repertoire.monthly-repertoire .date-nav ul.pager {
  display: inline-block;
  margin: 0;
  list-style: none;
  vertical-align: middle;
}

.theatre-calendar .view .date-nav .date-heading, .view-repertoire.monthly-repertoire .date-nav .date-heading {
  display: inline-block;
  min-width: 236px;
  vertical-align: middle;
}

@media (min-width: 360px) {
  .theatre-calendar .view .date-nav .date-heading, .view-repertoire.monthly-repertoire .date-nav .date-heading {
    min-width: 276px;
  }
}

@media (min-width: 768px) {
  .theatre-calendar .view .date-nav .date-heading, .view-repertoire.monthly-repertoire .date-nav .date-heading {
    min-width: 400px;
  }
}

.theatre-calendar .view .date-nav .date-heading h3, .view-repertoire.monthly-repertoire .date-nav .date-heading h3 {
  font-size: calc(2em + 1 * ( (100vw - 22.5em) / 97.5));
  color: #0000fe;
  line-height: 1;
  margin: .333em 0;
}

@media screen and (max-width: 22.5em) {
  .theatre-calendar .view .date-nav .date-heading h3, .view-repertoire.monthly-repertoire .date-nav .date-heading h3 {
    font-size: 2em;
  }
}

@media screen and (min-width: 120em) {
  .theatre-calendar .view .date-nav .date-heading h3, .view-repertoire.monthly-repertoire .date-nav .date-heading h3 {
    font-size: 3em;
  }
}

.yellow-black .theatre-calendar .view .date-nav .date-heading h3, .theatre-calendar .view .yellow-black .date-nav .date-heading h3, .yellow-black .view-repertoire.monthly-repertoire .date-nav .date-heading h3, .view-repertoire.monthly-repertoire .yellow-black .date-nav .date-heading h3 {
  color: #fd0;
}

.theatre-calendar .view .date-nav .date-heading h3 a, .view-repertoire.monthly-repertoire .date-nav .date-heading h3 a {
  text-decoration: none;
}

.theatre-calendar .view .date-nav .date-prev a, .view-repertoire.monthly-repertoire .date-nav .date-prev a, .theatre-calendar .view .date-nav .date-next a, .view-repertoire.monthly-repertoire .date-nav .date-next a {
  background-image: url("../images/arrows-date-nav.svg");
  background-repeat: no-repeat;
  display: block;
  height: 22px;
  overflow: hidden;
  text-indent: -9999px;
  width: 18px;
}

.theatre-calendar .view .date-nav .date-prev a:focus-visible, .view-repertoire.monthly-repertoire .date-nav .date-prev a:focus-visible, .theatre-calendar .view .date-nav .date-next a:focus-visible, .view-repertoire.monthly-repertoire .date-nav .date-next a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.theatre-calendar .view .date-nav .date-prev a, .view-repertoire.monthly-repertoire .date-nav .date-prev a {
  background-position: left top;
}

.yellow-black .theatre-calendar .view .date-nav .date-prev a, .theatre-calendar .view .yellow-black .date-nav .date-prev a, .yellow-black .view-repertoire.monthly-repertoire .date-nav .date-prev a, .view-repertoire.monthly-repertoire .yellow-black .date-nav .date-prev a {
  background-position: left bottom;
}

.theatre-calendar .view .date-nav .date-prev a:hover, .view-repertoire.monthly-repertoire .date-nav .date-prev a:hover {
  background-position: left -22px;
}

.yellow-black .theatre-calendar .view .date-nav .date-prev a:hover, .theatre-calendar .view .yellow-black .date-nav .date-prev a:hover, .yellow-black .view-repertoire.monthly-repertoire .date-nav .date-prev a:hover, .view-repertoire.monthly-repertoire .yellow-black .date-nav .date-prev a:hover {
  background-position: left bottom;
}

.theatre-calendar .view .date-nav .date-next a, .view-repertoire.monthly-repertoire .date-nav .date-next a {
  background-position: right top;
}

.yellow-black .theatre-calendar .view .date-nav .date-next a, .theatre-calendar .view .yellow-black .date-nav .date-next a, .yellow-black .view-repertoire.monthly-repertoire .date-nav .date-next a, .view-repertoire.monthly-repertoire .yellow-black .date-nav .date-next a {
  background-position: right bottom;
}

.theatre-calendar .view .date-nav .date-next a:hover, .view-repertoire.monthly-repertoire .date-nav .date-next a:hover {
  background-position: right -22px;
}

.yellow-black .theatre-calendar .view .date-nav .date-next a:hover, .theatre-calendar .view .yellow-black .date-nav .date-next a:hover, .yellow-black .view-repertoire.monthly-repertoire .date-nav .date-next a:hover, .view-repertoire.monthly-repertoire .yellow-black .date-nav .date-next a:hover {
  background-position: right bottom;
}

.theatre-calendar .view .date-year, .view-repertoire.monthly-repertoire .date-year {
  border-top: 7px solid #0000fe;
  color: #0000fe;
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
  text-align: center;
}

.yellow-black .theatre-calendar .view .date-year, .theatre-calendar .view .yellow-black .date-year, .yellow-black .view-repertoire.monthly-repertoire .date-year, .view-repertoire.monthly-repertoire .yellow-black .date-year {
  border-top-color: #fd0;
  color: #fd0;
}

.theatre-calendar .view .date-year span, .view-repertoire.monthly-repertoire .date-year span {
  font-size: 1.25em;
  display: block;
  margin-top: .5em;
}

@media (min-width: 360px) {
  .theatre-calendar .view .date-year span, .view-repertoire.monthly-repertoire .date-year span {
    font-size: 1.5em;
  }
}

.node-node-gallery-gallery.view-mode-full .glyph, .view-galeria-plakatu .glyph, .view-node-gallery-gallery-summaries .glyph, .view-repertoire.monthly-repertoire .glyph, .view-ticket-thx .glyph, .node-osoba.ds-2col-stacked-fluid > .glyph, .view-artists > .glyph, .view-theatre-team.theatre-team > .glyph, .node-spektakl .group-title .glyph, .view-lista-spektakli.lista-spektakli > .glyph, .view-lista-spektakli.lista-spektakli-archiwalnych > .glyph, .node-type-article.yellow-black .glyph, .node-type-wydarzenie.yellow-black .glyph, .node-type-article .glyph, .node-type-wydarzenie .glyph, .node-type-a11y.yellow-black .glyph, .node-type-page.yellow-black .glyph, .node-type-podcast.yellow-black .glyph, .yellow-black .view-podcasts > .glyph, .node-type-a11y .glyph, .node-type-page .glyph, .node-type-podcast .glyph, .view-podcasts > .glyph {
  background-position: center center;
  background-size: cover;
  display: block;
}

.node-node-gallery-gallery.view-mode-full .glyph, .view-galeria-plakatu .glyph, .view-node-gallery-gallery-summaries .glyph {
  background-image: url("../images/glyph-eye.svg");
  height: 63px;
  margin: 16px auto 35px;
  width: 126px;
}

.yellow-black .node-node-gallery-gallery.view-mode-full .glyph, .node-node-gallery-gallery.view-mode-full .yellow-black .glyph, .yellow-black .view-galeria-plakatu .glyph, .view-galeria-plakatu .yellow-black .glyph, .yellow-black .view-node-gallery-gallery-summaries .glyph, .view-node-gallery-gallery-summaries .yellow-black .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.view-repertoire.monthly-repertoire .glyph, .view-ticket-thx .glyph {
  background-image: url("../images/glyph-hex.svg");
  height: 55px;
  margin: 16px auto 43px;
  width: 63px;
}

.yellow-black .view-repertoire.monthly-repertoire .glyph, .view-repertoire.monthly-repertoire .yellow-black .glyph, .yellow-black .view-ticket-thx .glyph, .view-ticket-thx .yellow-black .glyph {
  background-image: url("../images/glyph-hex-yellow.svg");
}

.node-osoba.ds-2col-stacked-fluid > .glyph, .view-artists > .glyph, .view-theatre-team.theatre-team > .glyph {
  background-image: url("../images/glyph-lt.svg");
  height: 77px;
  margin: 16px auto 21px;
  width: 63px;
}

.yellow-black .node-osoba.ds-2col-stacked-fluid > .glyph, .yellow-black .view-artists > .glyph, .yellow-black .view-theatre-team.theatre-team > .glyph {
  background-image: url("../images/glyph-lt-yellow.svg");
}

.node-spektakl .group-title .glyph, .view-lista-spektakli.lista-spektakli > .glyph, .view-lista-spektakli.lista-spektakli-archiwalnych > .glyph {
  background-image: url("../images/glyph-menojre.svg");
  height: 50px;
  margin: 16px auto 48px;
  width: 98px;
}

.yellow-black .node-spektakl .group-title .glyph, .node-spektakl .group-title .yellow-black .glyph, .yellow-black .view-lista-spektakli.lista-spektakli > .glyph, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych > .glyph {
  background-image: url("../images/glyph-menojre-yellow.svg");
}

.node-type-article.yellow-black .glyph, .node-type-wydarzenie.yellow-black .glyph, .node-type-article .glyph, .node-type-wydarzenie .glyph {
  background-image: url("../images/glyph-hand.svg");
  height: 78px;
  margin: 16px auto 20px;
  width: 64px;
}

.node-type-article.yellow-black .glyph, .node-type-wydarzenie.yellow-black .glyph {
  background-image: url("../images/glyph-hand-yellow.svg");
}

.node-type-a11y.yellow-black .glyph, .node-type-page.yellow-black .glyph, .node-type-podcast.yellow-black .glyph, .yellow-black .view-podcasts > .glyph, .node-type-a11y .glyph, .node-type-page .glyph, .node-type-podcast .glyph, .view-podcasts > .glyph {
  background-image: url("../images/glyph-oval.svg");
  height: 62px;
  margin: 16px auto 36px;
  width: 62px;
}

.node-type-a11y.yellow-black .glyph, .node-type-page.yellow-black .glyph, .node-type-podcast.yellow-black .glyph, .yellow-black .view-podcasts > .glyph {
  background-image: url("../images/glyph-oval-yellow.svg");
}

@media (min-width: 768px) {
  .view-node-gallery-embedded .unslick::before,
  .view-person-photos .unslick::before, .view-node-gallery-embedded .slick__slide::before,
  .view-person-photos .slick__slide::before {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

.view-frontpage-slider .slick.unslick::before, .view-frontpage-slider .slick__slide::before {
  background: linear-gradient(rgba(26, 25, 214, 0), rgba(26, 25, 214, 0), #1a19d6);
  content: '';
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.yellow-black .view-frontpage-slider .slick.unslick::before, .view-frontpage-slider .yellow-black .slick.unslick::before, .yellow-black .view-frontpage-slider .slick__slide::before, .view-frontpage-slider .yellow-black .slick__slide::before {
  background: linear-gradient(rgba(30, 30, 30, 0), rgba(30, 30, 30, 0), #1e1e1e);
}

@media (min-width: 768px) {
  .view-node-gallery-embedded .unslick::before,
  .view-person-photos .unslick::before, .view-node-gallery-embedded .slick__slide::before,
  .view-person-photos .slick__slide::before {
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), #1a19d6);
  }
  .yellow-black .view-node-gallery-embedded .unslick::before, .view-node-gallery-embedded .yellow-black .unslick::before, .yellow-black
  .view-person-photos .unslick::before,
  .view-person-photos .yellow-black .unslick::before, .yellow-black .view-node-gallery-embedded .slick__slide::before, .view-node-gallery-embedded .yellow-black .slick__slide::before, .yellow-black
  .view-person-photos .slick__slide::before,
  .view-person-photos .yellow-black .slick__slide::before {
    background: linear-gradient(rgba(30, 30, 30, 0.1), rgba(30, 30, 30, 0.5), #1e1e1e);
  }
}

.advanced-content-section__attachments, .node-article.view-mode-full .group-attachments, .node-page .group-attachments, .node-wydarzenie.view-mode-full .group-attachments {
  margin-top: 2em;
}

.advanced-content-section__attachments h2, .node-article.view-mode-full .group-attachments h2, .node-page .group-attachments h2, .node-wydarzenie.view-mode-full .group-attachments h2, .advanced-content-section__attachments h3.label, .node-article.view-mode-full .group-attachments h3.label, .node-page .group-attachments h3.label, .node-wydarzenie.view-mode-full .group-attachments h3.label {
  font-size: 1.5rem;
  border-bottom: 4px solid #000;
  margin: 0;
  text-transform: uppercase;
}

.yellow-black .advanced-content-section__attachments h2, .yellow-black .node-article.view-mode-full .group-attachments h2, .node-article.view-mode-full .yellow-black .group-attachments h2, .yellow-black .node-page .group-attachments h2, .node-page .yellow-black .group-attachments h2, .yellow-black .node-wydarzenie.view-mode-full .group-attachments h2, .node-wydarzenie.view-mode-full .yellow-black .group-attachments h2, .yellow-black .advanced-content-section__attachments h3.label, .yellow-black .node-article.view-mode-full .group-attachments h3.label, .node-article.view-mode-full .yellow-black .group-attachments h3.label, .yellow-black .node-page .group-attachments h3.label, .node-page .yellow-black .group-attachments h3.label, .yellow-black .node-wydarzenie.view-mode-full .group-attachments h3.label, .node-wydarzenie.view-mode-full .yellow-black .group-attachments h3.label {
  border-bottom-color: #fd0;
}

.advanced-content-section__attachments .item-list, .node-article.view-mode-full .group-attachments .item-list, .node-page .group-attachments .item-list, .node-wydarzenie.view-mode-full .group-attachments .item-list {
  list-style: none;
  margin: 4px 0 0;
  padding: 0;
}

.advanced-content-section__attachments .item-list li, .node-article.view-mode-full .group-attachments .item-list li, .node-page .group-attachments .item-list li, .node-wydarzenie.view-mode-full .group-attachments .item-list li {
  font-size: calc(1.1875em + 0.3125 * ( (100vw - 22.5em) / 97.5));
  overflow: hidden;
  padding: 1em 0 1em 1em;
  transition: background-color .3s ease-in-out;
}

@media screen and (max-width: 22.5em) {
  .advanced-content-section__attachments .item-list li, .node-article.view-mode-full .group-attachments .item-list li, .node-page .group-attachments .item-list li, .node-wydarzenie.view-mode-full .group-attachments .item-list li {
    font-size: 1.1875em;
  }
}

@media screen and (min-width: 120em) {
  .advanced-content-section__attachments .item-list li, .node-article.view-mode-full .group-attachments .item-list li, .node-page .group-attachments .item-list li, .node-wydarzenie.view-mode-full .group-attachments .item-list li {
    font-size: 1.5em;
  }
}

@media (min-width: 768px) {
  .advanced-content-section__attachments .item-list li, .node-article.view-mode-full .group-attachments .item-list li, .node-page .group-attachments .item-list li, .node-wydarzenie.view-mode-full .group-attachments .item-list li {
    padding-left: 2em;
  }
}

.advanced-content-section__attachments .item-list li:nth-of-type(2n + 2), .node-article.view-mode-full .group-attachments .item-list li:nth-of-type(2n + 2), .node-page .group-attachments .item-list li:nth-of-type(2n + 2), .node-wydarzenie.view-mode-full .group-attachments .item-list li:nth-of-type(2n + 2) {
  background-color: #f2f2f2;
}

.yellow-black .advanced-content-section__attachments .item-list li:nth-of-type(2n + 2), .yellow-black .node-article.view-mode-full .group-attachments .item-list li:nth-of-type(2n + 2), .node-article.view-mode-full .yellow-black .group-attachments .item-list li:nth-of-type(2n + 2), .yellow-black .node-page .group-attachments .item-list li:nth-of-type(2n + 2), .node-page .yellow-black .group-attachments .item-list li:nth-of-type(2n + 2), .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list li:nth-of-type(2n + 2), .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list li:nth-of-type(2n + 2) {
  background-color: transparent;
}

.advanced-content-section__attachments .item-list li:hover, .node-article.view-mode-full .group-attachments .item-list li:hover, .node-page .group-attachments .item-list li:hover, .node-wydarzenie.view-mode-full .group-attachments .item-list li:hover {
  background-color: #e6e6e6;
}

.yellow-black .advanced-content-section__attachments .item-list li:hover, .yellow-black .node-article.view-mode-full .group-attachments .item-list li:hover, .node-article.view-mode-full .yellow-black .group-attachments .item-list li:hover, .yellow-black .node-page .group-attachments .item-list li:hover, .node-page .yellow-black .group-attachments .item-list li:hover, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list li:hover, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list li:hover {
  background-color: transparent;
}

.advanced-content-section__attachments .item-list li:focus-within, .node-article.view-mode-full .group-attachments .item-list li:focus-within, .node-page .group-attachments .item-list li:focus-within, .node-wydarzenie.view-mode-full .group-attachments .item-list li:focus-within {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .advanced-content-section__attachments .item-list li:focus-within, .yellow-black .node-article.view-mode-full .group-attachments .item-list li:focus-within, .node-article.view-mode-full .yellow-black .group-attachments .item-list li:focus-within, .yellow-black .node-page .group-attachments .item-list li:focus-within, .node-page .yellow-black .group-attachments .item-list li:focus-within, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list li:focus-within, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list li:focus-within {
  outline-color: #fd0;
}

.advanced-content-section__attachments .item-list li:focus-within .fa, .node-article.view-mode-full .group-attachments .item-list li:focus-within .fa, .node-page .group-attachments .item-list li:focus-within .fa, .node-wydarzenie.view-mode-full .group-attachments .item-list li:focus-within .fa {
  color: #0000fe;
}

.yellow-black .advanced-content-section__attachments .item-list li:focus-within .fa, .yellow-black .node-article.view-mode-full .group-attachments .item-list li:focus-within .fa, .node-article.view-mode-full .yellow-black .group-attachments .item-list li:focus-within .fa, .yellow-black .node-page .group-attachments .item-list li:focus-within .fa, .node-page .yellow-black .group-attachments .item-list li:focus-within .fa, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list li:focus-within .fa, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list li:focus-within .fa {
  color: #fd0;
}

.advanced-content-section__attachments .item-list .file.active .fa, .node-article.view-mode-full .group-attachments .item-list .file.active .fa, .node-page .group-attachments .item-list .file.active .fa, .node-wydarzenie.view-mode-full .group-attachments .item-list .file.active .fa {
  color: #0000fe;
}

.yellow-black .advanced-content-section__attachments .item-list .file.active .fa, .yellow-black .node-article.view-mode-full .group-attachments .item-list .file.active .fa, .node-article.view-mode-full .yellow-black .group-attachments .item-list .file.active .fa, .yellow-black .node-page .group-attachments .item-list .file.active .fa, .node-page .yellow-black .group-attachments .item-list .file.active .fa, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list .file.active .fa, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list .file.active .fa {
  color: #fd0;
}

.advanced-content-section__attachments .item-list .title, .node-article.view-mode-full .group-attachments .item-list .title, .node-page .group-attachments .item-list .title, .node-wydarzenie.view-mode-full .group-attachments .item-list .title {
  font-size: calc(1.3125em + 0.5625 * ( (100vw - 22.5em) / 97.5));
  font-weight: 600;
}

@media screen and (max-width: 22.5em) {
  .advanced-content-section__attachments .item-list .title, .node-article.view-mode-full .group-attachments .item-list .title, .node-page .group-attachments .item-list .title, .node-wydarzenie.view-mode-full .group-attachments .item-list .title {
    font-size: 1.3125em;
  }
}

@media screen and (min-width: 120em) {
  .advanced-content-section__attachments .item-list .title, .node-article.view-mode-full .group-attachments .item-list .title, .node-page .group-attachments .item-list .title, .node-wydarzenie.view-mode-full .group-attachments .item-list .title {
    font-size: 1.875em;
  }
}

.advanced-content-section__attachments .item-list a, .node-article.view-mode-full .group-attachments .item-list a, .node-page .group-attachments .item-list a, .node-wydarzenie.view-mode-full .group-attachments .item-list a {
  text-decoration: none;
}

.advanced-content-section__attachments .item-list a:link, .node-article.view-mode-full .group-attachments .item-list a:link, .node-page .group-attachments .item-list a:link, .node-wydarzenie.view-mode-full .group-attachments .item-list a:link {
  color: #000;
}

.advanced-content-section__attachments .item-list a:visited, .node-article.view-mode-full .group-attachments .item-list a:visited, .node-page .group-attachments .item-list a:visited, .node-wydarzenie.view-mode-full .group-attachments .item-list a:visited {
  color: #000;
}

.advanced-content-section__attachments .item-list a:hover, .node-article.view-mode-full .group-attachments .item-list a:hover, .node-page .group-attachments .item-list a:hover, .node-wydarzenie.view-mode-full .group-attachments .item-list a:hover {
  color: #0000fe;
}

.advanced-content-section__attachments .item-list a:active, .node-article.view-mode-full .group-attachments .item-list a:active, .node-page .group-attachments .item-list a:active, .node-wydarzenie.view-mode-full .group-attachments .item-list a:active {
  color: #0000fe;
}

.advanced-content-section__attachments .item-list a:focus-visible, .node-article.view-mode-full .group-attachments .item-list a:focus-visible, .node-page .group-attachments .item-list a:focus-visible, .node-wydarzenie.view-mode-full .group-attachments .item-list a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .advanced-content-section__attachments .item-list a:link, .yellow-black .node-article.view-mode-full .group-attachments .item-list a:link, .node-article.view-mode-full .yellow-black .group-attachments .item-list a:link, .yellow-black .node-page .group-attachments .item-list a:link, .node-page .yellow-black .group-attachments .item-list a:link, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list a:link, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list a:link {
  color: #fd0;
}

.yellow-black .advanced-content-section__attachments .item-list a:visited, .yellow-black .node-article.view-mode-full .group-attachments .item-list a:visited, .node-article.view-mode-full .yellow-black .group-attachments .item-list a:visited, .yellow-black .node-page .group-attachments .item-list a:visited, .node-page .yellow-black .group-attachments .item-list a:visited, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list a:visited, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list a:visited {
  color: #fd0;
}

.yellow-black .advanced-content-section__attachments .item-list a:hover, .yellow-black .node-article.view-mode-full .group-attachments .item-list a:hover, .node-article.view-mode-full .yellow-black .group-attachments .item-list a:hover, .yellow-black .node-page .group-attachments .item-list a:hover, .node-page .yellow-black .group-attachments .item-list a:hover, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list a:hover, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .advanced-content-section__attachments .item-list a:active, .yellow-black .node-article.view-mode-full .group-attachments .item-list a:active, .node-article.view-mode-full .yellow-black .group-attachments .item-list a:active, .yellow-black .node-page .group-attachments .item-list a:active, .node-page .yellow-black .group-attachments .item-list a:active, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list a:active, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list a:active {
  color: #fd0;
}

.yellow-black .advanced-content-section__attachments .item-list a:focus-visible, .yellow-black .node-article.view-mode-full .group-attachments .item-list a:focus-visible, .node-article.view-mode-full .yellow-black .group-attachments .item-list a:focus-visible, .yellow-black .node-page .group-attachments .item-list a:focus-visible, .node-page .yellow-black .group-attachments .item-list a:focus-visible, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list a:focus-visible, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.advanced-content-section__attachments .item-list a:focus-visible, .node-article.view-mode-full .group-attachments .item-list a:focus-visible, .node-page .group-attachments .item-list a:focus-visible, .node-wydarzenie.view-mode-full .group-attachments .item-list a:focus-visible {
  outline: none;
}

.advanced-content-section__attachments .item-list .fa, .node-article.view-mode-full .group-attachments .item-list .fa, .node-page .group-attachments .item-list .fa, .node-wydarzenie.view-mode-full .group-attachments .item-list .fa {
  color: gray;
  margin-right: .25em;
  margin-top: .15em;
  vertical-align: top;
  transition: color .3s ease-in-out;
}

.yellow-black .advanced-content-section__attachments .item-list .fa, .yellow-black .node-article.view-mode-full .group-attachments .item-list .fa, .node-article.view-mode-full .yellow-black .group-attachments .item-list .fa, .yellow-black .node-page .group-attachments .item-list .fa, .node-page .yellow-black .group-attachments .item-list .fa, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list .fa, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list .fa {
  color: #fd0;
}

.advanced-content-section__attachments .item-list span.ext, .node-article.view-mode-full .group-attachments .item-list span.ext, .node-page .group-attachments .item-list span.ext, .node-wydarzenie.view-mode-full .group-attachments .item-list span.ext {
  padding-left: 8px;
}

.advanced-content-section__attachments .item-list span.ext::after, .node-article.view-mode-full .group-attachments .item-list span.ext::after, .node-page .group-attachments .item-list span.ext::after, .node-wydarzenie.view-mode-full .group-attachments .item-list span.ext::after {
  font-size: .75em;
  color: gray;
}

.yellow-black .advanced-content-section__attachments .item-list span.ext::after, .yellow-black .node-article.view-mode-full .group-attachments .item-list span.ext::after, .node-article.view-mode-full .yellow-black .group-attachments .item-list span.ext::after, .yellow-black .node-page .group-attachments .item-list span.ext::after, .node-page .yellow-black .group-attachments .item-list span.ext::after, .yellow-black .node-wydarzenie.view-mode-full .group-attachments .item-list span.ext::after, .node-wydarzenie.view-mode-full .yellow-black .group-attachments .item-list span.ext::after {
  color: #fd0;
}

.node-article.view-mode-full .group-images .images, .node-wydarzenie.view-mode-full .group-images .images {
  text-align: center;
}

.node-article.view-mode-full .group-images .image, .node-wydarzenie.view-mode-full .group-images .image {
  display: inline-block;
  position: relative;
}

.node-article.view-mode-full .group-images .image a.colorbox, .node-wydarzenie.view-mode-full .group-images .image a.colorbox {
  display: block;
  margin: 10px;
}

@media (min-width: 768px) {
  .node-article.view-mode-full .group-images .image a.colorbox, .node-wydarzenie.view-mode-full .group-images .image a.colorbox {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

.node-article.view-mode-full .group-images .image a.colorbox::before, .node-wydarzenie.view-mode-full .group-images .image a.colorbox::before {
  background-image: url("../images/plus.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  height: 72px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease-in-out;
  width: 72px;
}

.yellow-black .node-article.view-mode-full .group-images .image a.colorbox::before, .node-article.view-mode-full .yellow-black .group-images .image a.colorbox::before, .yellow-black .node-wydarzenie.view-mode-full .group-images .image a.colorbox::before, .node-wydarzenie.view-mode-full .yellow-black .group-images .image a.colorbox::before {
  background-image: url("../images/plus-yellow.svg");
}

.node-article.view-mode-full .group-images .image a.colorbox:hover::before, .node-wydarzenie.view-mode-full .group-images .image a.colorbox:hover::before {
  opacity: 1;
}

.node-article.view-mode-full .group-images .image a.colorbox:hover img, .node-wydarzenie.view-mode-full .group-images .image a.colorbox:hover img {
  opacity: .1;
}

.node-article.view-mode-full .group-images .image img, .node-wydarzenie.view-mode-full .group-images .image img {
  display: block;
  transition: opacity .3s ease-in-out;
}

@media (min-width: 480px) {
  .node-article.view-mode-full .group-images .image img, .node-wydarzenie.view-mode-full .group-images .image img {
    height: 150px;
    width: auto;
  }
}

.node-node-gallery-gallery.view-mode-full .title {
  font-size: calc(3em + 0.75 * ( (100vw - 22.5em) / 97.5));
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .node-node-gallery-gallery.view-mode-full .title {
    font-size: 3em;
  }
}

@media screen and (min-width: 120em) {
  .node-node-gallery-gallery.view-mode-full .title {
    font-size: 3.75em;
  }
}

/* Views pager */
.view-galeria-plakatu .pager, .view-node-gallery-gallery-summaries .pager, .view-tz-search-results .pager, .view-lista-spektakli.lista-spektakli .pager, .view-lista-spektakli.lista-spektakli-archiwalnych .pager, .view-news .pager {
  border-top: 6px solid #0000fe;
  font-size: 1.125em;
  font-weight: 400;
  margin: 0;
  padding: 15px 0;
}

.yellow-black .view-galeria-plakatu .pager, .view-galeria-plakatu .yellow-black .pager, .yellow-black .view-node-gallery-gallery-summaries .pager, .view-node-gallery-gallery-summaries .yellow-black .pager, .yellow-black .view-tz-search-results .pager, .view-tz-search-results .yellow-black .pager, .yellow-black .view-lista-spektakli.lista-spektakli .pager, .view-lista-spektakli.lista-spektakli .yellow-black .pager, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager, .yellow-black .view-news .pager, .view-news .yellow-black .pager {
  border-top-color: #fd0;
}

.view-galeria-plakatu .pager .pager-current, .view-node-gallery-gallery-summaries .pager .pager-current, .view-tz-search-results .pager .pager-current, .view-lista-spektakli.lista-spektakli .pager .pager-current, .view-lista-spektakli.lista-spektakli-archiwalnych .pager .pager-current, .view-news .pager .pager-current {
  color: gray;
}

.yellow-black .view-galeria-plakatu .pager .pager-current, .view-galeria-plakatu .yellow-black .pager .pager-current, .yellow-black .view-node-gallery-gallery-summaries .pager .pager-current, .view-node-gallery-gallery-summaries .yellow-black .pager .pager-current, .yellow-black .view-tz-search-results .pager .pager-current, .view-tz-search-results .yellow-black .pager .pager-current, .yellow-black .view-lista-spektakli.lista-spektakli .pager .pager-current, .view-lista-spektakli.lista-spektakli .yellow-black .pager .pager-current, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager .pager-current, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager .pager-current, .yellow-black .view-news .pager .pager-current, .view-news .yellow-black .pager .pager-current {
  border-bottom: 2px solid #fd0;
  color: #fd0;
}

.view-galeria-plakatu .pager a, .view-node-gallery-gallery-summaries .pager a, .view-tz-search-results .pager a, .view-lista-spektakli.lista-spektakli .pager a, .view-lista-spektakli.lista-spektakli-archiwalnych .pager a, .view-news .pager a {
  text-decoration: none;
  transition: color .3s ease-in-out;
}

.view-galeria-plakatu .pager a:link, .view-node-gallery-gallery-summaries .pager a:link, .view-tz-search-results .pager a:link, .view-lista-spektakli.lista-spektakli .pager a:link, .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:link, .view-news .pager a:link {
  color: #000;
}

.view-galeria-plakatu .pager a:visited, .view-node-gallery-gallery-summaries .pager a:visited, .view-tz-search-results .pager a:visited, .view-lista-spektakli.lista-spektakli .pager a:visited, .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:visited, .view-news .pager a:visited {
  color: #000;
}

.view-galeria-plakatu .pager a:hover, .view-node-gallery-gallery-summaries .pager a:hover, .view-tz-search-results .pager a:hover, .view-lista-spektakli.lista-spektakli .pager a:hover, .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:hover, .view-news .pager a:hover {
  color: #0000fe;
}

.view-galeria-plakatu .pager a:active, .view-node-gallery-gallery-summaries .pager a:active, .view-tz-search-results .pager a:active, .view-lista-spektakli.lista-spektakli .pager a:active, .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:active, .view-news .pager a:active {
  color: #0000fe;
}

.view-galeria-plakatu .pager a:focus-visible, .view-node-gallery-gallery-summaries .pager a:focus-visible, .view-tz-search-results .pager a:focus-visible, .view-lista-spektakli.lista-spektakli .pager a:focus-visible, .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:focus-visible, .view-news .pager a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-galeria-plakatu .pager a:link, .view-galeria-plakatu .yellow-black .pager a:link, .yellow-black .view-node-gallery-gallery-summaries .pager a:link, .view-node-gallery-gallery-summaries .yellow-black .pager a:link, .yellow-black .view-tz-search-results .pager a:link, .view-tz-search-results .yellow-black .pager a:link, .yellow-black .view-lista-spektakli.lista-spektakli .pager a:link, .view-lista-spektakli.lista-spektakli .yellow-black .pager a:link, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:link, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager a:link, .yellow-black .view-news .pager a:link, .view-news .yellow-black .pager a:link {
  color: #fd0;
}

.yellow-black .view-galeria-plakatu .pager a:visited, .view-galeria-plakatu .yellow-black .pager a:visited, .yellow-black .view-node-gallery-gallery-summaries .pager a:visited, .view-node-gallery-gallery-summaries .yellow-black .pager a:visited, .yellow-black .view-tz-search-results .pager a:visited, .view-tz-search-results .yellow-black .pager a:visited, .yellow-black .view-lista-spektakli.lista-spektakli .pager a:visited, .view-lista-spektakli.lista-spektakli .yellow-black .pager a:visited, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:visited, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager a:visited, .yellow-black .view-news .pager a:visited, .view-news .yellow-black .pager a:visited {
  color: #fd0;
}

.yellow-black .view-galeria-plakatu .pager a:hover, .view-galeria-plakatu .yellow-black .pager a:hover, .yellow-black .view-node-gallery-gallery-summaries .pager a:hover, .view-node-gallery-gallery-summaries .yellow-black .pager a:hover, .yellow-black .view-tz-search-results .pager a:hover, .view-tz-search-results .yellow-black .pager a:hover, .yellow-black .view-lista-spektakli.lista-spektakli .pager a:hover, .view-lista-spektakli.lista-spektakli .yellow-black .pager a:hover, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:hover, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager a:hover, .yellow-black .view-news .pager a:hover, .view-news .yellow-black .pager a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-galeria-plakatu .pager a:active, .view-galeria-plakatu .yellow-black .pager a:active, .yellow-black .view-node-gallery-gallery-summaries .pager a:active, .view-node-gallery-gallery-summaries .yellow-black .pager a:active, .yellow-black .view-tz-search-results .pager a:active, .view-tz-search-results .yellow-black .pager a:active, .yellow-black .view-lista-spektakli.lista-spektakli .pager a:active, .view-lista-spektakli.lista-spektakli .yellow-black .pager a:active, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:active, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager a:active, .yellow-black .view-news .pager a:active, .view-news .yellow-black .pager a:active {
  color: #fd0;
}

.yellow-black .view-galeria-plakatu .pager a:focus-visible, .view-galeria-plakatu .yellow-black .pager a:focus-visible, .yellow-black .view-node-gallery-gallery-summaries .pager a:focus-visible, .view-node-gallery-gallery-summaries .yellow-black .pager a:focus-visible, .yellow-black .view-tz-search-results .pager a:focus-visible, .view-tz-search-results .yellow-black .pager a:focus-visible, .yellow-black .view-lista-spektakli.lista-spektakli .pager a:focus-visible, .view-lista-spektakli.lista-spektakli .yellow-black .pager a:focus-visible, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:focus-visible, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager a:focus-visible, .yellow-black .view-news .pager a:focus-visible, .view-news .yellow-black .pager a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-galeria-plakatu .pager a:focus-visible, .view-node-gallery-gallery-summaries .pager a:focus-visible, .view-tz-search-results .pager a:focus-visible, .view-lista-spektakli.lista-spektakli .pager a:focus-visible, .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:focus-visible, .view-news .pager a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-galeria-plakatu .pager a:focus-visible, .view-galeria-plakatu .yellow-black .pager a:focus-visible, .yellow-black .view-node-gallery-gallery-summaries .pager a:focus-visible, .view-node-gallery-gallery-summaries .yellow-black .pager a:focus-visible, .yellow-black .view-tz-search-results .pager a:focus-visible, .view-tz-search-results .yellow-black .pager a:focus-visible, .yellow-black .view-lista-spektakli.lista-spektakli .pager a:focus-visible, .view-lista-spektakli.lista-spektakli .yellow-black .pager a:focus-visible, .yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .pager a:focus-visible, .view-lista-spektakli.lista-spektakli-archiwalnych .yellow-black .pager a:focus-visible, .yellow-black .view-news .pager a:focus-visible, .view-news .yellow-black .pager a:focus-visible {
  outline-color: #fd0;
}

body .page-preloader .throbber, .theatre-calendar .ajax-progress .throbber,
.theatre-calendar .ajax-progress-throbber .throbber {
  -webkit-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 6px solid #cccccc;
  border-right: 6px solid #cccccc;
  border-bottom: 6px solid #cccccc;
  border-top: 6px solid #0000fe;
  border-radius: 100%;
  box-sizing: content-box;
  height: 50px;
  left: calc(50% - 31px);
  position: absolute;
  top: calc(50% - 31px);
  width: 50px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

body .page-preloader {
  background-color: #f2f2f2;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

@media (min-width: 1024px) {
  body.sticky::after {
    background-color: #fff;
    box-shadow: 0 1px 10px 0 rgba(204, 204, 204, 0.5);
    content: '';
    height: 100px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 996;
  }
}

@media only screen and (min-width: 1025px) {
  body.sticky.admin-menu::after {
    top: 28px;
  }
}

body.yellow-black {
  background-color: #1e1e1e;
  color: #fd0;
}

@media (min-width: 1024px) {
  body.yellow-black::after {
    background-color: #1e1e1e;
  }
}

body.yellow-black .page-preloader {
  background-color: #1e1e1e;
}

body.yellow-black .page-preloader .throbber {
  border-left-color: #fd0;
  border-right-color: #fd0;
  border-bottom-color: #fd0;
  border-top-color: #1e1e1e;
}

.page {
  min-height: 850px;
}

.node-unpublished {
  padding-top: 8em;
  position: relative;
}

.node-unpublished:before {
  background-color: white;
  content: 'UNPUBLISHED';
  color: red;
  font-size: 8em;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

:lang(ru) {
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif, sans-serif;
}

:lang(ru) strong {
  font-weight: bold;
}

.exposed-form-search-results-page .views-exposed-widget {
  padding: 0;
}

.exposed-form-search-results-page .views-exposed-widget input {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 52px;
}

.exposed-form-search-results-page .views-exposed-widget .form-item .form-text {
  border-bottom: 2px solid #666666;
  padding-left: 4px;
  width: 120px;
}

.exposed-form-search-results-page .views-exposed-widget .form-item .form-text:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: -2px;
}

.yellow-black .exposed-form-search-results-page .views-exposed-widget .form-item .form-text:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 360px) {
  .exposed-form-search-results-page .views-exposed-widget .form-item .form-text {
    width: 160px;
  }
}

.yellow-black .exposed-form-search-results-page .views-exposed-widget .form-item .form-text {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.exposed-form-search-results-page .views-exposed-widget .form-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("../images/loupe.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  border-bottom: 2px solid #666666;
  margin: 0;
  overflow: hidden;
  width: 50px;
  text-indent: -9999px;
}

.exposed-form-search-results-page .views-exposed-widget .form-submit:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: -2px;
}

.yellow-black .exposed-form-search-results-page .views-exposed-widget .form-submit:focus-visible {
  outline-color: #fd0;
}

.yellow-black .exposed-form-search-results-page .views-exposed-widget .form-submit {
  background-color: #1e1e1e;
  background-image: url("../images/loupe-yellow.svg");
  border-color: #fd0;
}

.block-language-switcher-fallback .language-switcher-locale-url {
  font-size: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.block-language-switcher-fallback .language-switcher-locale-url li {
  border-left: 2px solid #666666;
  display: inline-block;
  font-size: 16px;
}

.yellow-black .block-language-switcher-fallback .language-switcher-locale-url li {
  border-color: #fd0;
}

[dir="rtl"] .yellow-black .block-language-switcher-fallback .language-switcher-locale-url li {
  border-color: #fd0;
}

.block-language-switcher-fallback .language-switcher-locale-url li.first {
  border-left: 0;
}

.block-language-switcher-fallback .language-switcher-locale-url li.active {
  border-left: 5px solid #fff;
  margin-right: 5px;
}

.yellow-black .block-language-switcher-fallback .language-switcher-locale-url li.active {
  border-color: #1e1e1e;
}

.block-language-switcher-fallback .language-switcher-locale-url li.active .language-link {
  border-bottom: 2px solid #0000fe;
}

.yellow-black .block-language-switcher-fallback .language-switcher-locale-url li.active .language-link {
  border-color: #fd0;
}

.block-language-switcher-fallback .language-switcher-locale-url li.active.first {
  border-left: 0;
}

.block-language-switcher-fallback .language-switcher-locale-url li.active + li {
  border-left: 0;
}

[dir="rtl"] .block-language-switcher-fallback .language-switcher-locale-url li {
  border-left: 0;
  border-right: 2px solid #666666;
}

[dir="rtl"] .block-language-switcher-fallback .language-switcher-locale-url li.first {
  border-right: 0;
}

[dir="rtl"] .block-language-switcher-fallback .language-switcher-locale-url li.active.last {
  border-right: 0;
}

.block-language-switcher-fallback .language-switcher-locale-url .language-link {
  border-bottom: 2px solid #666666;
  color: #666666;
  display: block;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 50px;
  min-width: 48px;
  padding: 0 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.block-language-switcher-fallback .language-switcher-locale-url .language-link:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: -2px;
}

.yellow-black .block-language-switcher-fallback .language-switcher-locale-url .language-link:focus-visible {
  outline-color: #fd0;
}

.yellow-black .block-language-switcher-fallback .language-switcher-locale-url .language-link {
  border-color: #fd0;
  color: #fd0;
}

.block-language-switcher-fallback .language-switcher-locale-url .language-link.active {
  background-color: #0000fe;
  color: #fff;
}

.block-language-switcher-fallback .language-switcher-locale-url .language-link.active:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .block-language-switcher-fallback .language-switcher-locale-url .language-link.active:focus-visible {
  outline-color: #fd0;
}

.yellow-black .block-language-switcher-fallback .language-switcher-locale-url .language-link.active {
  background-color: #fd0;
  color: #1e1e1e;
}

.block-language-switcher-fallback .language-switcher-locale-url .language-link.locale-untranslated {
  text-decoration: line-through;
}

/* class from 'templates/block--views--news-block.tpl.php' */
.news {
  margin-top: 2em;
}

@media (min-width: 768px) {
  .news {
    margin-top: 3em;
  }
}

.block.newsletter {
  padding-top: 2em;
  /* custom/forms/_newsletter.scss */
}

@media (min-width: 1024px) {
  .block.newsletter {
    padding-top: 4em;
  }
}

@media (min-width: 1280px) {
  .block.newsletter {
    padding-top: 6em;
  }
}

@media print {
  .block.newsletter {
    display: none;
    visibility: hidden;
  }
}

.block.newsletter .block__title {
  margin: 0 auto -.5em;
}

.block.newsletter .block__title span {
  background-color: #fff;
  padding: 10px 20px;
}

@media (min-width: 1024px) {
  .block.newsletter .block__title span {
    padding-left: 45px;
    padding-right: 45px;
  }
}

.yellow-black .block.newsletter .block__title span {
  background-color: #1e1e1e;
}

.block.newsletter .content {
  border: 4px solid #000;
  padding: 2em 1em;
  text-align: center;
}

@media (min-width: 1024px) {
  .block.newsletter .content {
    padding: 2em 4em 2.5em;
  }
}

.yellow-black .block.newsletter .content {
  background-color: #fd0;
  border-color: #fd0;
}

.block-textsize .content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.block-textsize .content ul li {
  display: inline-block;
  font-weight: 700;
}

.block-textsize .content ul li.ts_decrease {
  font-size: 1.125em;
}

.block-textsize .content ul li.ts_normal {
  font-size: 1.5em;
}

.block-textsize .content ul li.ts_increase {
  font-size: 1.875em;
}

.block-textsize .content ul li a {
  text-decoration: none;
}

.block-textsize .content ul li a:link {
  color: #000;
}

.block-textsize .content ul li a:visited {
  color: #000;
}

.block-textsize .content ul li a:hover {
  color: #0000fe;
}

.block-textsize .content ul li a:active {
  color: #0000fe;
}

.block-textsize .content ul li a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .block-textsize .content ul li a:link {
  color: #fd0;
}

.yellow-black .block-textsize .content ul li a:visited {
  color: #fd0;
}

.yellow-black .block-textsize .content ul li a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .block-textsize .content ul li a:active {
  color: #fd0;
}

.yellow-black .block-textsize .content ul li a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.theatre-calendar .view .calendar-calendar tbody td a.jquery-ajax-load-open {
  border: 6px solid #0000fe;
  line-height: 34px;
  margin: -3px 0;
  outline: none;
  position: relative;
  z-index: 1;
}

@media (min-width: 360px) {
  .theatre-calendar .view .calendar-calendar tbody td a.jquery-ajax-load-open {
    line-height: 44px;
  }
}

@media (min-width: 480px) {
  .theatre-calendar .view .calendar-calendar tbody td a.jquery-ajax-load-open {
    line-height: 54px;
  }
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a.jquery-ajax-load-open, .theatre-calendar .view .calendar-calendar tbody td .yellow-black a.jquery-ajax-load-open {
  border-color: #fd0;
}

/* class from 'templates/blocks/block--views--calendar-block-1.tpl.php' */
.theatre-calendar {
  margin-top: 2em;
  position: relative;
  /* ajax progress */
}

@media (min-width: 768px) {
  .theatre-calendar {
    margin-top: 3em;
  }
}

@media (min-width: 1280px) {
  .theatre-calendar::before {
    top: 80px;
    height: calc(100% - 80px);
  }
}

@media print {
  .theatre-calendar {
    display: none;
    visibility: hidden;
  }
}

@media (min-width: 1280px) {
  .theatre-calendar .title-wrapper {
    margin-top: 80px;
  }
}

.theatre-calendar .view {
  /* date nav wrapper */
  /* eof date nav wrapper */
  /* eof view header */
  /* view content */
  /* eof view content */
}

.theatre-calendar .view .view-header {
  color: #000;
}

.theatre-calendar .view .view-content {
  margin: 1em auto 0;
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  .theatre-calendar .view .view-content {
    margin-top: 3em;
    padding-bottom: 150px;
  }
  .theatre-calendar .view .view-content::after {
    background-color: #f2f2f2;
    content: '';
    height: 115px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .yellow-black .theatre-calendar .view .view-content::after {
    background-color: #1e1e1e;
  }
}

.theatre-calendar .view .calendar-content__close {
  cursor: pointer;
  display: none;
  position: absolute;
  right: 20px;
  top: 10px;
}

@media screen and (max-width: 767px) {
  .theatre-calendar .view .calendar-content.content-loaded {
    background-color: #fff;
    height: 100%;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
  }
  .yellow-black .theatre-calendar .view .calendar-content.content-loaded {
    background-color: #1e1e1e;
  }
  .theatre-calendar .view .calendar-content.content-loaded .calendar-content__close {
    appearance: button;
    background: none;
    background-image: url("../images/close.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% 120px;
    border: 0;
    color: inherit;
    display: block;
    font: inherit;
    font-size: 0;
    height: 40px;
    overflow: visible;
    padding: 0;
    user-select: none;
    width: 40px;
  }
  .yellow-black .theatre-calendar .view .calendar-content.content-loaded .calendar-content__close {
    background-position: bottom left;
  }
  .theatre-calendar .view .calendar-content.content-loaded .calendar-content__wrapper {
    padding: 40px 20px;
  }
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content {
    float: left;
    min-height: 362px;
    width: 50%;
  }
}

.theatre-calendar .view .calendar-content .message {
  background-color: #0000fe;
  color: #fff;
  display: block;
  font-size: 0.75em;
  font-weight: 400;
  padding: .25em;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .message {
    background-color: #fff;
    color: #000;
    left: 25%;
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 181px;
  }
}

@media (min-width: 1024px) {
  .theatre-calendar .view .calendar-content .message {
    font-size: 0.875em;
  }
}

@media (min-width: 1440px) {
  .theatre-calendar .view .calendar-content .message {
    font-size: 1em;
  }
}

.yellow-black .theatre-calendar .view .calendar-content .message {
  background-color: #1e1e1e;
  color: #fd0;
}

.theatre-calendar .view .calendar-content .spinner {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .spinner {
    left: 25%;
    top: 181px;
  }
}

.theatre-calendar .view .calendar-content .spinner .fa-spin {
  color: #0000fe;
  display: block;
  margin: 0 auto;
}

.yellow-black .theatre-calendar .view .calendar-content .spinner .fa-spin {
  color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__header {
  display: none;
}

.theatre-calendar .view .calendar-content .calendar-day__result .views-row {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .views-row {
    display: none;
    margin-top: 0;
  }
  .theatre-calendar .view .calendar-content .calendar-day__result .views-row.current {
    display: block;
  }
}

@media (min-width: 480px) {
  .theatre-calendar .view .calendar-content .calendar-day__result.multi-row .views-row {
    float: left;
    width: 50%;
  }
  .theatre-calendar .view .calendar-content .calendar-day__result.multi-row .views-row.views-row-1 .views-row__content, .theatre-calendar .view .calendar-content .calendar-day__result.multi-row .views-row.views-row-3 .views-row__content {
    margin-right: 10px;
  }
  .theatre-calendar .view .calendar-content .calendar-day__result.multi-row .views-row.views-row-2 .views-row__content, .theatre-calendar .view .calendar-content .calendar-day__result.multi-row .views-row.views-row-4 .views-row__content {
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result.multi-row .views-row {
    float: none;
    width: 100%;
  }
  .theatre-calendar .view .calendar-content .calendar-day__result.multi-row .views-row .views-row__content {
    margin: 0;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .spektakl .image__wydarzenie {
  display: none;
}

.theatre-calendar .view .calendar-content .calendar-day__result .image {
  display: inline-block;
  max-width: 360px;
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .image {
    margin-right: 1em;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .image a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: -2px;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .image a:focus-visible {
  outline-color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__result .image img {
  display: block;
}

.theatre-calendar .view .calendar-content .calendar-day__result .links {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .links {
    display: inline-block;
    vertical-align: bottom;
  }
}

@media (min-width: 1680px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .links {
    margin-top: 0;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .read-more {
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .read-more {
    display: block;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .read-more a {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  font-size: 0.875em;
  font-family: "Inter UI", sans-serif;
  text-transform: uppercase;
}

.theatre-calendar .view .calendar-content .calendar-day__result .read-more a:link, .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:visited {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.theatre-calendar .view .calendar-content .calendar-day__result .read-more a:hover, .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:active, .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:focus-visible {
  background-color: #0000fe;
  border: 1px solid #0000fe;
  color: #fff;
}

.theatre-calendar .view .calendar-content .calendar-day__result .read-more a:focus-visible {
  outline: 2px solid #0000fe;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:link, .yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:hover, .yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:active, .yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .read-more a:focus-visible {
  outline: 2px solid #fd0;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .read-more a {
    display: block;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .reservation {
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .reservation {
    display: block;
    margin-top: 20px;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .reservation a {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  font-size: 0.875em;
  text-transform: lowercase;
}

.theatre-calendar .view .calendar-content .calendar-day__result .reservation a:link, .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:visited {
  background-color: #0000fe;
  border: 1px solid #0000fe;
  color: #fff;
}

.theatre-calendar .view .calendar-content .calendar-day__result .reservation a:hover, .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:active, .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:focus-visible {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.theatre-calendar .view .calendar-content .calendar-day__result .reservation a:focus-visible {
  outline: 2px solid #0000fe;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:link, .yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:hover, .yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:active, .yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .reservation a:focus-visible {
  outline: 2px solid #fd0;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .reservation a {
    font-size: 1.3125em;
    display: block;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .premiere {
  color: #0000fe;
  display: block;
  font-size: .75em;
  line-height: 1;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .premiere {
  color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title {
  font-size: calc(1.5em + 0.75 * ( (100vw - 22.5em) / 97.5));
  margin: .5em 0;
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .theatre-calendar .view .calendar-content .calendar-day__result .title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .theatre-calendar .view .calendar-content .calendar-day__result .title {
    font-size: 2.25em;
  }
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .title {
    margin-bottom: 0;
    margin-top: 40px;
    margin-left: 25px;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .title.smaller {
  font-size: calc(1.5em + 0.375 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .theatre-calendar .view .calendar-content .calendar-day__result .title.smaller {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .theatre-calendar .view .calendar-content .calendar-day__result .title.smaller {
    font-size: 1.875em;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a {
  text-decoration: none;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a:link {
  color: #000;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a:visited {
  color: #000;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a:hover {
  color: #0000fe;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a:active {
  color: #0000fe;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title a:link {
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title a:visited {
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title a:active {
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title a:focus-visible {
  outline-color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__result .title a:hover span {
  color: #0000fe !important;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title a:hover span {
  color: #fd0 !important;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .title span {
  color: #fd0 !important;
}

.theatre-calendar .view .calendar-content .calendar-day__result .info-label {
  color: #0000fe;
  display: block;
  font-size: .75em;
  line-height: 1;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .info-label {
  color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__result .info {
  background-color: #f2f2f2;
  padding: 10px;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .info {
    padding: 0;
    position: absolute;
    bottom: 35px;
    left: 0;
    z-index: 1;
  }
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .info {
  background-color: #1e1e1e;
}

.theatre-calendar .view .calendar-content .calendar-day__result .info span {
  display: inline-block;
}

.theatre-calendar .view .calendar-content .calendar-day__result .info .date {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  font-size: 1.25em;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .info .date {
    font-size: 1.875em;
    margin-left: 25px;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .info .underscore {
  background-color: #0000fe;
  display: inline-block;
  height: 2px;
  width: 20px;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .info .underscore {
    height: 6px;
    margin: 0 6px;
    width: 50px;
  }
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .info .underscore {
  background-color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__result .info .pipe {
  background-color: #000;
  height: 36px;
  margin: 0 6px -12px;
  width: 1px;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .info .pipe {
    height: 72px;
    margin: 0 12px -24px;
  }
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__result .info .pipe {
  background-color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__result .info .day {
  font-size: 1.125em;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .info .day {
    font-size: 1.5em;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .info .day::first-letter {
  text-transform: uppercase;
}

.theatre-calendar .view .calendar-content .calendar-day__result .info .hour {
  font-size: 1.125em;
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .info .hour {
    font-size: 1.5em;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__result .info .stage {
  font-size: 1em;
  display: block;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 10px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__result .info .stage {
    font-size: 1.125em;
    display: inline-block;
    margin-top: 0;
    max-width: 240px;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__footer {
  display: none;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-content .calendar-day__footer {
    bottom: 0;
    display: block;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li {
  background-color: #fff;
  cursor: pointer;
  float: left;
  height: 115px;
  margin-left: 20px;
  overflow: hidden;
  position: relative;
  width: 115px;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li {
  border: 1px solid #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li::before {
  background-color: rgba(255, 255, 255, 0.5);
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li::before {
  background-color: #1e1e1e;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li:hover::before {
  background-color: transparent;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li:hover .count {
  background-color: #000;
  color: #fff;
  padding: 5px;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.small {
  margin-left: 12px;
  width: 80px;
}

@media (min-width: 1024px) {
  .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.small {
    margin-left: 20px;
    width: 115px;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.xsmall {
  margin-left: 6px;
  width: 30px;
}

@media (min-width: 1024px) {
  .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.xsmall {
    margin-left: 12px;
    width: 60px;
  }
}

@media (min-width: 1680px) {
  .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.xsmall {
    margin-left: 12px;
    width: 80px;
  }
}

@media (min-width: 1800px) {
  .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.xsmall {
    margin-left: 20px;
    width: 115px;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.current {
  background-color: #0000fe;
  cursor: default;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.current img {
  opacity: .7;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.current .count {
  background-color: transparent;
  color: #fff;
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.current .count {
  color: #1e1e1e;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.current::before {
  background-color: rgba(0, 0, 254, 0.5);
}

.yellow-black .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li.current::before {
  background-color: #fd0;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li .count {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  font-size: calc(1.5em + 0.75 * ( (100vw - 22.5em) / 97.5));
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media screen and (max-width: 22.5em) {
  .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li .count {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .theatre-calendar .view .calendar-content .calendar-day__footer .item-list li .count {
    font-size: 2.25em;
  }
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li .spektakl .image__wydarzenie {
  display: none;
}

.theatre-calendar .view .calendar-content .calendar-day__footer .item-list li img {
  display: block;
  max-width: none;
}

@media (min-width: 768px) {
  .theatre-calendar .view .calendar-calendar {
    float: right;
    width: 50%;
  }
}

.theatre-calendar .view .calendar-calendar table {
  margin: 0;
}

.theatre-calendar .view .calendar-calendar table tr {
  border-bottom: 1px solid #000;
}

.yellow-black .theatre-calendar .view .calendar-calendar table tr {
  border-color: #665800;
}

.theatre-calendar .view .calendar-calendar thead th {
  font-size: 1em;
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
  height: 40px;
  text-align: center;
  vertical-align: middle;
}

@media (min-width: 360px) {
  .theatre-calendar .view .calendar-calendar thead th {
    font-size: 1.125em;
    height: 50px;
  }
}

@media (min-width: 480px) {
  .theatre-calendar .view .calendar-calendar thead th {
    font-size: 1.375em;
    height: 60px;
  }
}

.theatre-calendar .view .calendar-calendar thead th::first-letter {
  text-transform: uppercase;
}

.theatre-calendar .view .calendar-calendar tbody td {
  font-size: 1.125em;
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
  height: 40px;
  text-align: center;
  width: 14.285%;
  vertical-align: middle;
}

@media (min-width: 360px) {
  .theatre-calendar .view .calendar-calendar tbody td {
    font-size: 1.5em;
    height: 50px;
  }
}

@media (min-width: 480px) {
  .theatre-calendar .view .calendar-calendar tbody td {
    font-size: 1.75em;
    height: 60px;
  }
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td {
  color: #665800;
}

.theatre-calendar .view .calendar-calendar tbody td a {
  box-sizing: border-box;
  display: block;
  line-height: 40px;
  text-decoration: none;
}

.theatre-calendar .view .calendar-calendar tbody td a:link {
  color: #0000fe;
}

.theatre-calendar .view .calendar-calendar tbody td a:visited {
  color: #0000fe;
}

.theatre-calendar .view .calendar-calendar tbody td a:hover {
  color: #fff;
}

.theatre-calendar .view .calendar-calendar tbody td a:active {
  color: #fff;
}

.theatre-calendar .view .calendar-calendar tbody td a:focus-visible {
  color: #fff;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a:link {
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a:visited {
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a:active {
  color: #fd0;
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

@media (min-width: 360px) {
  .theatre-calendar .view .calendar-calendar tbody td a {
    line-height: 50px;
  }
}

@media (min-width: 480px) {
  .theatre-calendar .view .calendar-calendar tbody td a {
    line-height: 60px;
  }
}

.theatre-calendar .view .calendar-calendar tbody td a:hover {
  background-color: #0000fe;
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a:hover {
  background-color: #fd0;
  color: #1e1e1e;
}

.theatre-calendar .view .calendar-calendar tbody td a:focus-visible {
  background-color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .theatre-calendar .view .calendar-calendar tbody td a:focus-visible {
  background-color: #fd0;
  color: #1e1e1e;
  outline-color: #fd0;
}

.theatre-calendar .ajax-progress .throbber,
.theatre-calendar .ajax-progress-throbber .throbber {
  background-image: none;
  background-color: transparent;
  float: none;
}

.theatre-calendar .ajax-progress,
.theatre-calendar .ajax-progress-throbber {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
}

.yellow-black .theatre-calendar .ajax-progress, .yellow-black
.theatre-calendar .ajax-progress-throbber {
  background-color: rgba(30, 30, 30, 0.75);
}

.block-tz-contrast .content {
  font-size: 0;
}

.block-tz-contrast .content input {
  background-image: url("../images/contrast.svg");
  border: 0;
  color: #000;
  display: inline-block;
  height: 25px;
  margin: 5px;
  overflow: hidden;
  text-decoration: none;
  text-indent: -9999px;
  width: 25px;
}

.block-tz-contrast .content input:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .block-tz-contrast .content input:focus-visible {
  outline-color: #fd0;
}

.block-tz-contrast .content input#edit-default, .block-tz-contrast .content input#edit-default--2 {
  background-position: top left;
}

.block-tz-contrast .content input#edit-yellow-black, .block-tz-contrast .content input#edit-yellow-black--2 {
  background-position: top right;
}

.block-tz-contrast .content input + input {
  margin-left: 20px;
}

@media (min-width: 1024px) {
  .block-tz-contrast .content input + input {
    margin-left: 0;
  }
}

.block-tz-sign-language-interpreter .content a {
  background-image: url("../images/symbol-tlumacza-white.png");
  background-size: cover;
  display: block;
  height: 42px;
  width: 50px;
}

.block-tz-sign-language-interpreter .content a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .block-tz-sign-language-interpreter .content a:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 1024px) {
  .block-tz-sign-language-interpreter .content a {
    background-image: url("../images/symbol-tlumacza.png");
  }
}

.yellow-black .block-tz-sign-language-interpreter .content a {
  background-image: url("../images/symbol-tlumacza-hc.png");
}

.breadcrumb {
  list-style: none;
  margin: 2em 0;
  overflow-x: hidden;
  padding: 0;
  text-align: center;
}

.breadcrumb li {
  font-size: 1.125em;
  color: gray;
  display: inline-block;
  font-weight: 200;
  padding: .125em;
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .breadcrumb li {
    font-size: 1.3125em;
  }
}

.yellow-black .breadcrumb li {
  color: #fd0;
}

.breadcrumb a {
  text-decoration: none;
}

.breadcrumb a:link {
  color: gray;
}

.breadcrumb a:visited {
  color: #000;
}

.breadcrumb a:hover {
  color: #0000fe;
}

.breadcrumb a:active {
  color: #0000fe;
}

.breadcrumb a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .breadcrumb a:link {
  color: #fd0;
}

.yellow-black .breadcrumb a:visited {
  color: #fd0;
}

.yellow-black .breadcrumb a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .breadcrumb a:active {
  color: #fd0;
}

.yellow-black .breadcrumb a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.breadcrumb a span {
  display: block;
  margin: .125em;
}

.breadcrumb .delimiter {
  background-color: gray;
  display: inline-block;
  font-size: 0;
  height: 2px;
  line-height: 1;
  margin: 0 4px;
  width: 16px;
}

@media (min-width: 480px) {
  .breadcrumb .delimiter {
    margin-left: 6px;
    margin-right: 6px;
    width: 24px;
  }
}

@media (min-width: 768px) {
  .breadcrumb .delimiter {
    margin-left: 10px;
    margin-right: 10px;
    width: 76px;
  }
}

.yellow-black .breadcrumb .delimiter {
  background-color: #fd0;
}

.go-to-list {
  margin-top: 2em;
  text-align: right;
}

.go-to-list a {
  font-size: 1.125em;
  border-bottom: 4px solid #0000fe;
  font-weight: 600;
  padding-bottom: 2px;
  text-decoration: none;
}

.go-to-list a:link {
  color: #0000fe;
}

.go-to-list a:visited {
  color: #0000fe;
}

.go-to-list a:hover {
  color: #000;
}

.go-to-list a:active {
  color: #000;
}

.go-to-list a:focus-visible {
  color: #000;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .go-to-list a:link {
  color: #fd0;
}

.yellow-black .go-to-list a:visited {
  color: #fd0;
}

.yellow-black .go-to-list a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .go-to-list a:active {
  color: #fd0;
}

.yellow-black .go-to-list a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.yellow-black .go-to-list a {
  border-color: #fd0;
}

.go-to-list a:hover {
  border-color: #000;
}

.yellow-black .go-to-list a:hover {
  border-color: #fd0;
  text-decoration: none;
}

.field-name-body a,
.field-body a,
.sections a,
.advanced-content-section__body a,
.node-a11y a {
  font-weight: 400;
  text-decoration: underline;
}

.field-name-body a:link,
.field-body a:link,
.sections a:link,
.advanced-content-section__body a:link,
.node-a11y a:link {
  color: #0000fe;
}

.field-name-body a:visited,
.field-body a:visited,
.sections a:visited,
.advanced-content-section__body a:visited,
.node-a11y a:visited {
  color: #0000fe;
}

.field-name-body a:hover,
.field-body a:hover,
.sections a:hover,
.advanced-content-section__body a:hover,
.node-a11y a:hover {
  color: #000;
}

.field-name-body a:active,
.field-body a:active,
.sections a:active,
.advanced-content-section__body a:active,
.node-a11y a:active {
  color: #000;
}

.field-name-body a:focus-visible,
.field-body a:focus-visible,
.sections a:focus-visible,
.advanced-content-section__body a:focus-visible,
.node-a11y a:focus-visible {
  color: #000;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .field-name-body a:link, .yellow-black
.field-body a:link, .yellow-black
.sections a:link, .yellow-black
.advanced-content-section__body a:link, .yellow-black
.node-a11y a:link {
  color: #fd0;
}

.yellow-black .field-name-body a:visited, .yellow-black
.field-body a:visited, .yellow-black
.sections a:visited, .yellow-black
.advanced-content-section__body a:visited, .yellow-black
.node-a11y a:visited {
  color: #fd0;
}

.yellow-black .field-name-body a:hover, .yellow-black
.field-body a:hover, .yellow-black
.sections a:hover, .yellow-black
.advanced-content-section__body a:hover, .yellow-black
.node-a11y a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .field-name-body a:active, .yellow-black
.field-body a:active, .yellow-black
.sections a:active, .yellow-black
.advanced-content-section__body a:active, .yellow-black
.node-a11y a:active {
  color: #fd0;
}

.yellow-black .field-name-body a:focus-visible, .yellow-black
.field-body a:focus-visible, .yellow-black
.sections a:focus-visible, .yellow-black
.advanced-content-section__body a:focus-visible, .yellow-black
.node-a11y a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.field-name-body a:hover,
.field-body a:hover,
.sections a:hover,
.advanced-content-section__body a:hover,
.node-a11y a:hover {
  text-decoration: none;
}

.local-navigation {
  background-image: url("../images/glyph-eye-small.svg");
  background-position: center 15px;
  background-repeat: no-repeat;
  background-size: 60px 30px;
  padding-bottom: 20px;
  padding-top: 60px;
  position: relative;
  text-align: center;
  z-index: 1;
}

@media (min-width: 768px) {
  .local-navigation {
    background-position: calc(100% - 15px) 15px;
    padding-bottom: 0;
    padding-right: 90px;
    padding-top: 0;
    text-align: right;
  }
}

.yellow-black .local-navigation {
  background-image: url("../images/glyph-eye-small-yellow.svg");
}

@media (min-width: 1024px) {
  .sticky .local-navigation {
    background-color: #fff;
    right: 0;
    position: fixed;
    top: 100px;
    z-index: 995;
  }
}

@media only screen and (min-width: 1025px) {
  .admin-menu.sticky .local-navigation {
    top: 128px;
  }
}

.sticky.yellow-black .local-navigation {
  background-color: #1e1e1e;
}

.local-navigation ul {
  list-style: none;
  margin: 2px 0 0;
  padding: 0;
}

.local-navigation ul li {
  display: inline-block;
  margin: 0 5px;
}

.local-navigation ul li a {
  display: block;
  font-family: "Inter UI", sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  padding: 0 5px;
  text-decoration: none;
  text-transform: uppercase;
}

.local-navigation ul li a:link {
  color: #0000fe;
}

.local-navigation ul li a:visited {
  color: #0000fe;
}

.local-navigation ul li a:hover {
  color: #000;
}

.local-navigation ul li a:active {
  color: #000;
}

.local-navigation ul li a:focus-visible {
  color: #000;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .local-navigation ul li a:link {
  color: #fd0;
}

.yellow-black .local-navigation ul li a:visited {
  color: #fd0;
}

.yellow-black .local-navigation ul li a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .local-navigation ul li a:active {
  color: #fd0;
}

.yellow-black .local-navigation ul li a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.local-navigation ul li a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .local-navigation ul li a:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 360px) {
  .local-navigation ul li a {
    font-size: 1em;
  }
}

@media (min-width: 768px) {
  .local-navigation ul li a {
    font-size: 1.1875em;
    line-height: 60px;
  }
}

.noscript {
  background-color: #000;
  color: #fff;
  font-weight: 700;
  padding: .5em;
  text-align: center;
}

.page-title {
  font-size: calc(1.5em + 1.5 * ( (100vw - 22.5em) / 97.5));
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  border-top: 7px solid #0000fe;
  color: #0000fe;
  margin: 0;
  overflow-wrap: break-word;
  padding-top: .25em;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .page-title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .page-title {
    font-size: 3em;
  }
}

@media (min-width: 768px) {
  .page-title {
    border-top: 10px solid #0000fe;
  }
}

.yellow-black .page-title {
  border-top-color: #fd0;
  color: #fd0;
}

.page-title + .contextual-links-wrapper {
  margin-top: 36px;
}

.section-title,
.block.newsletter .block__title,
.field-group-div.section > h2 {
  line-height: 1;
  text-transform: lowercase;
  text-align: center;
}

@media (min-width: 1024px) {
  .section-title,
  .block.newsletter .block__title,
  .field-group-div.section > h2 {
    font-size: 2.8125em;
  }
}

.field-name-body {
  font-size: calc(1.125em + 0.125 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .field-name-body {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 120em) {
  .field-name-body {
    font-size: 1.25em;
  }
}

.field-collection-container ul.action-links-field-collection-add {
  clear: both;
  list-style: none;
  margin: 0 0 1em;
  padding: 0;
}

.field-collection-view {
  position: relative;
}

.field-collection-view:hover ul.field-collection-view-links {
  display: block;
}

.field-collection-view ul.field-collection-view-links {
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  font-size: .875em;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

.field-group-div.section {
  clear: both;
  margin-top: 2em;
}

.field-group-div.section:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .field-group-div.section:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 768px) {
  .field-group-div.section {
    margin-top: 3em;
  }
}

@media (min-width: 1024px) {
  .field-group-div.section {
    margin-top: 4em;
  }
}

@media (min-width: 1280px) {
  .field-group-div.section {
    margin-top: 5em;
  }
}

.field-group-div.section > h2 {
  margin: 0 0 1em;
  position: relative;
}

.field-group-div.section > h2::before {
  background-color: #0000fe;
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  height: 4px;
  width: 100%;
}

@media (min-width: 768px) {
  .field-group-div.section > h2::before {
    height: 6px;
  }
}

.yellow-black .field-group-div.section > h2::before {
  background-color: #fd0;
}

.field-group-div.section > h2 span {
  background-color: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.yellow-black .field-group-div.section > h2 span {
  background-color: #1e1e1e;
}

.newsletter-form {
  margin: 0 auto;
  max-width: 600px;
}

.yellow-black .newsletter-form {
  background-color: #fd0;
}

.newsletter-form iframe {
  border: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.newsletter-form form span {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .newsletter-form form span {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.newsletter-form form input {
  border: 2px solid #0000fe;
  border-radius: 0;
  display: block;
  height: 45px;
  margin: 10px auto 0;
}

@media (min-width: 768px) {
  .newsletter-form form input {
    display: inline-block;
    margin: 0;
  }
}

.yellow-black .newsletter-form form input {
  border-color: #fd0;
}

.newsletter-form form input[type='text'] {
  color: #000;
  padding: 0 10px;
}

.yellow-black .newsletter-form form input[type='text'] {
  background-color: #1e1e1e;
  color: #fd0;
}

.newsletter-form form input[type='submit'] {
  font-size: 1.3125em;
  background-color: #0000fe;
  color: #fff;
  font-weight: 700;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

@media (min-width: 768px) {
  .newsletter-form form input[type='submit'] {
    margin-left: 10px;
  }
}

.yellow-black .newsletter-form form input[type='submit'] {
  background-color: #fd0;
  color: #1e1e1e;
}

.newsletter-form form input[type='submit']:hover {
  background-color: #fff;
  color: #0000fe;
}

.yellow-black .newsletter-form form input[type='submit']:hover {
  background-color: #1e1e1e;
  color: #fd0;
}

.newsletter-form p {
  font-size: 0.875em;
  line-height: 1.333;
  margin-bottom: 0;
  text-align: left;
}

.newsletter-form a:link {
  color: #0000fe;
}

.newsletter-form a:visited {
  color: #0000fe;
}

.newsletter-form a:hover {
  color: #000;
}

.newsletter-form a:active {
  color: #000;
}

.newsletter-form a:focus-visible {
  color: #000;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .newsletter-form a:link {
  color: #fd0;
}

.yellow-black .newsletter-form a:visited {
  color: #fd0;
}

.yellow-black .newsletter-form a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .newsletter-form a:active {
  color: #fd0;
}

.yellow-black .newsletter-form a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.fmForm .yellow-black {
  background-color: red !important;
}

.node-advanced-page.view-mode-teaser .group-content,
.node-article.view-mode-teaser .group-content,
.node-wydarzenie.view-mode-teaser .group-content {
  position: relative;
}

.node-advanced-page.view-mode-teaser .group-content.active .group-image img,
.node-article.view-mode-teaser .group-content.active .group-image img,
.node-wydarzenie.view-mode-teaser .group-content.active .group-image img {
  opacity: .1;
}

.node-advanced-page.view-mode-teaser .group-content.active .group-image .glyph,
.node-article.view-mode-teaser .group-content.active .group-image .glyph,
.node-wydarzenie.view-mode-teaser .group-content.active .group-image .glyph {
  display: block;
}

.node-advanced-page.view-mode-teaser .group-content.active .title,
.node-article.view-mode-teaser .group-content.active .title,
.node-wydarzenie.view-mode-teaser .group-content.active .title {
  color: #0000fe;
}

.yellow-black .node-advanced-page.view-mode-teaser .group-content.active .title, .yellow-black
.node-article.view-mode-teaser .group-content.active .title, .yellow-black
.node-wydarzenie.view-mode-teaser .group-content.active .title {
  color: #fd0;
}

.node-advanced-page.view-mode-teaser .group-content.active .read-more-label span,
.node-article.view-mode-teaser .group-content.active .read-more-label span,
.node-wydarzenie.view-mode-teaser .group-content.active .read-more-label span {
  background-color: #0000fe;
  color: #fff;
}

.yellow-black .node-advanced-page.view-mode-teaser .group-content.active .read-more-label span, .yellow-black
.node-article.view-mode-teaser .group-content.active .read-more-label span, .yellow-black
.node-wydarzenie.view-mode-teaser .group-content.active .read-more-label span {
  background-color: #fd0;
  color: #1e1e1e;
}

.node-advanced-page.view-mode-teaser .group-image,
.node-article.view-mode-teaser .group-image,
.node-wydarzenie.view-mode-teaser .group-image {
  position: relative;
}

.node-advanced-page.view-mode-teaser .group-image img,
.node-article.view-mode-teaser .group-image img,
.node-wydarzenie.view-mode-teaser .group-image img {
  display: block;
  transition: opacity .3s ease-in-out;
}

.node-advanced-page.view-mode-teaser .group-image .glyph,
.node-article.view-mode-teaser .group-image .glyph,
.node-wydarzenie.view-mode-teaser .group-image .glyph {
  background-image: url("../images/glyph-eye.svg");
  background-position: center center;
  background-size: cover;
  display: none;
  height: 111px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 221px;
}

@media (min-width: 480px) {
  .node-advanced-page.view-mode-teaser .group-image .glyph,
  .node-article.view-mode-teaser .group-image .glyph,
  .node-wydarzenie.view-mode-teaser .group-image .glyph {
    height: 100px;
    width: 200px;
  }
}

@media (min-width: 768px) {
  .node-advanced-page.view-mode-teaser .group-image .glyph,
  .node-article.view-mode-teaser .group-image .glyph,
  .node-wydarzenie.view-mode-teaser .group-image .glyph {
    height: 111px;
    width: 221px;
  }
}

.yellow-black .node-advanced-page.view-mode-teaser .group-image .glyph, .yellow-black
.node-article.view-mode-teaser .group-image .glyph, .yellow-black
.node-wydarzenie.view-mode-teaser .group-image .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.node-advanced-page.view-mode-teaser .group-image + .title,
.node-article.view-mode-teaser .group-image + .title,
.node-wydarzenie.view-mode-teaser .group-image + .title {
  border-top: 0;
}

.node-advanced-page.view-mode-teaser .title,
.node-article.view-mode-teaser .title,
.node-wydarzenie.view-mode-teaser .title {
  font-size: calc(1.25em + 0.625 * ( (100vw - 22.5em) / 97.5));
  border-top: 6px solid #000;
  margin-top: 0;
  padding-top: .5em;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

@media screen and (max-width: 22.5em) {
  .node-advanced-page.view-mode-teaser .title,
  .node-article.view-mode-teaser .title,
  .node-wydarzenie.view-mode-teaser .title {
    font-size: 1.25em;
  }
}

@media screen and (min-width: 120em) {
  .node-advanced-page.view-mode-teaser .title,
  .node-article.view-mode-teaser .title,
  .node-wydarzenie.view-mode-teaser .title {
    font-size: 1.875em;
  }
}

.yellow-black .node-advanced-page.view-mode-teaser .title, .yellow-black
.node-article.view-mode-teaser .title, .yellow-black
.node-wydarzenie.view-mode-teaser .title {
  border-top-color: #fd0;
}

.node-advanced-page.view-mode-teaser .field-name-body,
.node-article.view-mode-teaser .field-name-body,
.node-wydarzenie.view-mode-teaser .field-name-body {
  margin: 1.5rem 0;
}

.node-advanced-page.view-mode-teaser .field-name-body p,
.node-article.view-mode-teaser .field-name-body p,
.node-wydarzenie.view-mode-teaser .field-name-body p {
  margin: 0;
}

.node-advanced-page.view-mode-teaser .read-more-label,
.node-article.view-mode-teaser .read-more-label,
.node-wydarzenie.view-mode-teaser .read-more-label {
  text-align: center;
}

.node-advanced-page.view-mode-teaser .read-more-label span,
.node-article.view-mode-teaser .read-more-label span,
.node-wydarzenie.view-mode-teaser .read-more-label span {
  border: 1px solid #0000fe;
  color: #0000fe;
  display: inline-block;
  font-family: "Inter UI", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-transform: uppercase;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.yellow-black .node-advanced-page.view-mode-teaser .read-more-label span, .yellow-black
.node-article.view-mode-teaser .read-more-label span, .yellow-black
.node-wydarzenie.view-mode-teaser .read-more-label span {
  border-color: #fd0;
  color: #fd0;
}

.node-advanced-page.view-mode-teaser .read-more-link,
.node-article.view-mode-teaser .read-more-link,
.node-wydarzenie.view-mode-teaser .read-more-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 0;
}

.node-advanced-page.view-mode-teaser .read-more-link:focus-visible,
.node-article.view-mode-teaser .read-more-link:focus-visible,
.node-wydarzenie.view-mode-teaser .read-more-link:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-advanced-page.view-mode-teaser .read-more-link:focus-visible, .yellow-black
.node-article.view-mode-teaser .read-more-link:focus-visible, .yellow-black
.node-wydarzenie.view-mode-teaser .read-more-link:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 1280px) {
  .node-type-a11y .page-title {
    clear: none;
    float: left;
    width: 77.77778%;
    margin-left: 11.11111%;
    margin-right: -100%;
  }
  [dir="rtl"] .node-type-a11y .page-title {
    float: right;
    margin-right: 11.11111%;
    margin-left: -100%;
  }
}

.node-type-a11y .glyph {
  clear: both;
}

.node-a11y {
  font-size: calc(1.125em + 0.125 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-a11y {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 120em) {
  .node-a11y {
    font-size: 1.25em;
  }
}

.node-a11y .field-name-field-attachment {
  margin-top: 2em;
}

.node-a11y .field-name-field-attachment .field-label {
  font-size: 1.5rem;
  border-bottom: 4px solid #000;
  margin: 0;
  text-transform: uppercase;
}

.yellow-black .node-a11y .field-name-field-attachment .field-label {
  border-bottom-color: #fd0;
}

.node-a11y .field-name-field-attachment .field-items {
  list-style: none;
  margin: 4px 0 0;
  padding: 0;
}

.node-a11y .field-name-field-attachment .field-item {
  font-size: calc(1.1875em + 0.3125 * ( (100vw - 22.5em) / 97.5));
  overflow: hidden;
  padding: 1em 0 1em 1em;
  transition: background-color .3s ease-in-out;
}

@media screen and (max-width: 22.5em) {
  .node-a11y .field-name-field-attachment .field-item {
    font-size: 1.1875em;
  }
}

@media screen and (min-width: 120em) {
  .node-a11y .field-name-field-attachment .field-item {
    font-size: 1.5em;
  }
}

@media (min-width: 768px) {
  .node-a11y .field-name-field-attachment .field-item {
    padding-left: 2em;
  }
}

.node-a11y .field-name-field-attachment .field-item.odd {
  background-color: #f2f2f2;
}

.yellow-black .node-a11y .field-name-field-attachment .field-item.odd {
  background-color: transparent;
}

.node-a11y .field-name-field-attachment .field-item:hover {
  background-color: #e6e6e6;
}

.yellow-black .node-a11y .field-name-field-attachment .field-item:hover {
  background-color: transparent;
}

.node-a11y .field-name-field-attachment .field-item:focus-within {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-a11y .field-name-field-attachment .field-item:focus-within {
  outline-color: #fd0;
}

.node-a11y .field-name-field-attachment .field-item:focus-within .fa {
  color: #0000fe;
}

.yellow-black .node-a11y .field-name-field-attachment .field-item:focus-within .fa {
  color: #fd0;
}

.node-a11y .field-name-field-attachment .file.active .fa {
  color: #0000fe;
}

.yellow-black .node-a11y .field-name-field-attachment .file.active .fa {
  color: #fd0;
}

.node-a11y .field-name-field-attachment .title {
  font-size: calc(1.3125em + 0.5625 * ( (100vw - 22.5em) / 97.5));
  font-weight: 600;
}

@media screen and (max-width: 22.5em) {
  .node-a11y .field-name-field-attachment .title {
    font-size: 1.3125em;
  }
}

@media screen and (min-width: 120em) {
  .node-a11y .field-name-field-attachment .title {
    font-size: 1.875em;
  }
}

.node-a11y .field-name-field-attachment a {
  text-decoration: none;
}

.node-a11y .field-name-field-attachment a:link {
  color: #000;
}

.node-a11y .field-name-field-attachment a:visited {
  color: #000;
}

.node-a11y .field-name-field-attachment a:hover {
  color: #0000fe;
}

.node-a11y .field-name-field-attachment a:active {
  color: #0000fe;
}

.node-a11y .field-name-field-attachment a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-a11y .field-name-field-attachment a:link {
  color: #fd0;
}

.yellow-black .node-a11y .field-name-field-attachment a:visited {
  color: #fd0;
}

.yellow-black .node-a11y .field-name-field-attachment a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .node-a11y .field-name-field-attachment a:active {
  color: #fd0;
}

.yellow-black .node-a11y .field-name-field-attachment a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.node-a11y .field-name-field-attachment a:focus-visible {
  outline: none;
}

.node-a11y .field-name-field-attachment .fa {
  color: gray;
  margin-right: .25em;
  margin-top: .15em;
  vertical-align: top;
  transition: color .3s ease-in-out;
}

.yellow-black .node-a11y .field-name-field-attachment .fa {
  color: #fd0;
}

.node-a11y .field-name-field-attachment span.ext {
  padding-left: 8px;
}

.node-a11y .field-name-field-attachment span.ext::after {
  font-size: .75em;
  color: gray;
}

.yellow-black .node-a11y .field-name-field-attachment span.ext::after {
  color: #fd0;
}

.node-advanced-page.view-mode-full .title {
  font-size: calc(1.75em + 2 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-advanced-page.view-mode-full .title {
    font-size: 1.75em;
  }
}

@media screen and (min-width: 120em) {
  .node-advanced-page.view-mode-full .title {
    font-size: 3.75em;
  }
}

@media (min-width: 768px) {
  .node-advanced-page.view-mode-full .title {
    margin-top: 0;
    padding-top: .25em;
    position: relative;
    width: 60%;
  }
  .node-advanced-page.view-mode-full .title::before {
    background-color: #0000fe;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    width: 280px;
  }
  .yellow-black .node-advanced-page.view-mode-full .title::before {
    background-color: #fd0;
  }
}

.node-advanced-page__content {
  margin: 0 auto;
  max-width: 800px;
}

.node-advanced-page__translation-links .item-list {
  list-style: none;
  margin: 1.5em 0;
  padding: 0;
  text-align: center;
}

.node-advanced-page__translation-links .item-list li {
  display: inline-block;
  margin: 0 5px;
}

.node-advanced-page__translation-links .item-list li a {
  display: block;
  font-family: "Inter UI", sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  padding: 0 5px;
  text-decoration: underline;
  text-transform: uppercase;
}

.node-advanced-page__translation-links .item-list li a:link {
  color: #0000fe;
}

.node-advanced-page__translation-links .item-list li a:visited {
  color: #0000fe;
}

.node-advanced-page__translation-links .item-list li a:hover {
  color: #000;
}

.node-advanced-page__translation-links .item-list li a:active {
  color: #000;
}

.node-advanced-page__translation-links .item-list li a:focus-visible {
  color: #000;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-advanced-page__translation-links .item-list li a:link {
  color: #fd0;
}

.yellow-black .node-advanced-page__translation-links .item-list li a:visited {
  color: #fd0;
}

.yellow-black .node-advanced-page__translation-links .item-list li a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .node-advanced-page__translation-links .item-list li a:active {
  color: #fd0;
}

.yellow-black .node-advanced-page__translation-links .item-list li a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.node-advanced-page__translation-links .item-list li a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .node-advanced-page__translation-links .item-list li a:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 360px) {
  .node-advanced-page__translation-links .item-list li a {
    font-size: 1em;
  }
}

.advanced-content-section {
  padding-top: 2em;
}

@media (min-width: 1024px) {
  .advanced-content-section {
    padding-top: 4em;
  }
}

.advanced-content-section__menu-link:focus-visible {
  outline: none;
}

.advanced-content-section__title {
  line-height: 1;
  margin: 0 0 1em;
  position: relative;
}

@media (min-width: 1024px) {
  .advanced-content-section__title {
    font-size: 2.8125em;
  }
}

.advanced-content-section__title::before {
  background-color: #0000fe;
  content: '';
  display: block;
  height: 4px;
  margin: 0 0 .5em;
  width: 40%;
}

@media (min-width: 768px) {
  .advanced-content-section__title::before {
    height: 6px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

.yellow-black .advanced-content-section__title::before {
  background-color: #fd0;
}

.advanced-content-section__title span {
  background-color: #fff;
  line-height: 1.2;
  padding: 0 20px 0 0;
  position: relative;
  z-index: 1;
}

.yellow-black .advanced-content-section__title span {
  background-color: #1e1e1e;
}

.advanced-content-section__body {
  font-size: calc(1.125em + 0.125 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .advanced-content-section__body {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 120em) {
  .advanced-content-section__body {
    font-size: 1.25em;
  }
}

.advanced-content-section__logos-section .logo {
  display: inline-block;
  margin: 0 0 20px;
  vertical-align: middle;
}

.advanced-content-section__logos-section .logo img {
  display: block;
  margin: 10px;
  max-height: 60px;
  width: auto;
}

@media (min-width: 768px) {
  .node-article.ds-2col-stacked-fluid .group-left {
    float: left;
    /* LTR */
    width: 30%;
  }
}

@media (min-width: 768px) {
  .node-article.ds-2col-stacked-fluid .group-right {
    float: right;
    /* LTR */
    width: 70%;
  }
}

@media (min-width: 768px) {
  .node-article.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 1em;
  }
  .node-article.ds-2col-stacked-fluid .group-right .field-name-body > *:first-child {
    margin-top: 0;
  }
  .node-article.ds-2col-stacked-fluid .group-right .field-name-body > *:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .node-article.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 2em;
  }
}

@media (min-width: 1280px) {
  .node-article.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 3em;
  }
}

.node-article.ds-2col-stacked-fluid.group-one-column .group-left,
.node-article.ds-2col-stacked-fluid.group-one-column .group-right {
  width: 100%;
  float: none;
}

.node-article.ds-2col-stacked-fluid.group-one-column .field-name-body {
  margin: 0 auto;
  max-width: 800px;
  padding-left: 0;
}

.node-article.ds-2col-stacked-fluid .group-footer {
  clear: both;
  padding-top: 2em;
}

.node-article.view-mode-full .title {
  font-size: calc(1.5em + 2.25 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-article.view-mode-full .title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .node-article.view-mode-full .title {
    font-size: 3.75em;
  }
}

@media (min-width: 768px) {
  .node-article.view-mode-full .title {
    margin-top: 0;
    padding-top: .25em;
    position: relative;
    width: 60%;
  }
  .node-article.view-mode-full .title::before {
    background-color: #0000fe;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    width: 280px;
  }
  .yellow-black .node-article.view-mode-full .title::before {
    background-color: #fd0;
  }
}

.node-article.view-mode-full .cover > *:first-child {
  margin-top: 0;
}

.node-article.view-mode-full .cover > *:last-child {
  margin-bottom: 0;
}

.node-article.view-mode-full .cover img {
  display: block;
}

.node-article.view-mode-full .field-name-body .image-caption img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .node-article.view-mode-full .field-name-body .image-caption img {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .node-article.view-mode-full .field-name-body .caption {
    width: 80%;
  }
}

.node-article.view-mode-full .caption {
  color: #666666;
  display: block;
  font-size: 0.875em;
  line-height: 1.1;
  margin: 5px auto;
}

.node-frontpage .field-name-body {
  border: 2px solid #0000fe;
  margin-bottom: 1em;
  padding: 0 1em;
}

.yellow-black .node-frontpage .field-name-body {
  border-color: #fd0;
}

.view-node-gallery-gallery-item-views {
  margin-top: 2em;
}

.view-node-gallery-gallery-item-views .view-content {
  text-align: center;
}

.view-node-gallery-gallery-item-views .view-content .views-row {
  display: inline-block;
}

.view-node-gallery-gallery-item-views .view-content .views-row .file-image {
  position: relative;
}

.view-node-gallery-gallery-item-views .view-content .views-row .colorbox {
  display: block;
  margin: 10px;
}

@media (min-width: 768px) {
  .view-node-gallery-gallery-item-views .view-content .views-row .colorbox {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

.view-node-gallery-gallery-item-views .view-content .views-row .colorbox::before {
  background-image: url("../images/plus.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  height: 72px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease-in-out;
  width: 72px;
}

.yellow-black .view-node-gallery-gallery-item-views .view-content .views-row .colorbox::before {
  background-image: url("../images/plus-yellow.svg");
}

.view-node-gallery-gallery-item-views .view-content .views-row .colorbox:hover::before {
  opacity: 1;
}

.view-node-gallery-gallery-item-views .view-content .views-row .colorbox:hover img {
  opacity: .1;
}

.view-node-gallery-gallery-item-views .view-content .views-row img {
  display: block;
  transition: opacity .3s ease-in-out;
}

@media (min-width: 480px) {
  .view-node-gallery-gallery-item-views .view-content .views-row img {
    height: 150px;
    width: auto;
  }
}

.node-node-gallery-gallery.view-mode-full {
  margin-top: 2em;
}

.node-node-gallery-gallery.view-mode-full .gallery-type {
  display: none;
}

.node-node-gallery-gallery.view-mode-full .photographer {
  font-style: italic;
  padding: 35px 0;
  position: relative;
  text-align: center;
}

.node-node-gallery-gallery.view-mode-full .photographer::before, .node-node-gallery-gallery.view-mode-full .photographer::after {
  background-color: #0000fe;
  content: '';
  position: absolute;
  left: 50%;
  height: 30px;
  width: 6px;
  transform: translateX(-50%);
}

.yellow-black .node-node-gallery-gallery.view-mode-full .photographer::before, .yellow-black .node-node-gallery-gallery.view-mode-full .photographer::after {
  background-color: #fd0;
}

.node-node-gallery-gallery.view-mode-full .photographer::before {
  top: 0;
}

.node-node-gallery-gallery.view-mode-full .photographer::after {
  bottom: 0;
}

.node-node-gallery-gallery.view-mode-full .photographer .label-inline {
  display: inline-block;
  margin-right: 4px;
}

@media (min-width: 1280px) {
  .node-node-gallery-gallery.ds-2col-stacked-fluid .group-header {
    margin-bottom: 2em;
  }
}

.node-node-gallery-gallery.ds-2col-stacked-fluid.group-one-column .group-left,
.node-node-gallery-gallery.ds-2col-stacked-fluid.group-one-column .group-right {
  width: 100%;
  float: none;
}

.node-node-gallery-gallery.ds-2col-stacked-fluid .group-footer {
  clear: both;
}

.node-node-gallery-gallery.node-teaser .group-content {
  position: relative;
}

.node-node-gallery-gallery.node-teaser .group-content:hover .group-image::before {
  opacity: 1;
}

.yellow-black .node-node-gallery-gallery.node-teaser .group-content:hover .group-image::before {
  opacity: 0;
}

.node-node-gallery-gallery.node-teaser .group-content:hover .group-image::after {
  opacity: 1;
}

.yellow-black .node-node-gallery-gallery.node-teaser .group-content:hover .group-image img {
  opacity: .1;
}

.node-node-gallery-gallery.node-teaser .group-content:hover .title {
  color: #0000fe;
}

.yellow-black .node-node-gallery-gallery.node-teaser .group-content:hover .title {
  color: #fd0;
}

.node-node-gallery-gallery.node-teaser .group-image {
  position: relative;
}

.node-node-gallery-gallery.node-teaser .group-image::before {
  background: linear-gradient(rgba(26, 25, 214, 0.1), rgba(26, 25, 214, 0.5), #1a19d6);
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .3s ease-in-out;
  width: 100%;
}

.node-node-gallery-gallery.node-teaser .group-image::after {
  background-image: url("../images/glyph-eye-white.svg");
  background-position: center center;
  background-size: cover;
  content: '';
  height: 111px;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease-in-out;
  width: 221px;
}

.yellow-black .node-node-gallery-gallery.node-teaser .group-image::after {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.node-node-gallery-gallery.node-teaser img {
  display: block;
}

.node-node-gallery-gallery.node-teaser .photographer {
  background-color: #fff;
  font-style: italic;
  padding: 2px 20px 2px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.yellow-black .node-node-gallery-gallery.node-teaser .photographer {
  background-color: #1e1e1e;
}

.node-node-gallery-gallery.node-teaser .photographer .label-inline {
  display: inline-block;
  margin-right: 4px;
}

.node-node-gallery-gallery.node-teaser .title {
  font-size: calc(1.5em + 0.375 * ( (100vw - 22.5em) / 97.5));
  text-transform: uppercase;
  transition: color .3s ease-in-out;
}

@media screen and (max-width: 22.5em) {
  .node-node-gallery-gallery.node-teaser .title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .node-node-gallery-gallery.node-teaser .title {
    font-size: 1.875em;
  }
}

.node-node-gallery-gallery.node-teaser .view-more {
  position: absolute;
  height: calc(100% - 2px);
  left: 1px;
  overflow: hidden;
  text-indent: -9999px;
  top: 1px;
  width: calc(100% - 2px);
}

.node-node-gallery-gallery.node-teaser .view-more:focus-visible {
  outline: 1px dotted #0000fe;
}

.yellow-black .node-node-gallery-gallery.node-teaser .view-more:focus-visible {
  outline: 1px dotted #fd0;
}

.node-type-node-gallery-item table.item-navigator td a {
  color: #000;
  font-size: 1.125em;
  font-weight: 700;
  padding: 0 10px;
  text-decoration: none;
}

.node-type-node-gallery-item .file-image .content img {
  display: block;
  margin: 0 auto;
}

.node-type-node-gallery-item .actors ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.node-type-node-gallery-item .actors ul li {
  display: inline-block;
  margin: 0 10px;
}

.node-type-node-gallery-item .actors ul li a {
  color: #000;
  font-size: 1.125em;
  font-weight: 700;
  text-decoration: none;
}

.node-osoba.ds-2col-stacked-fluid .group-left {
  text-align: center;
}

@media (min-width: 768px) {
  .node-osoba.ds-2col-stacked-fluid .group-left {
    float: left;
    /* LTR */
    width: 30%;
  }
}

.node-osoba.ds-2col-stacked-fluid .group-left .portrait a {
  display: inline-block;
}

.node-osoba.ds-2col-stacked-fluid .group-left .portrait a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-osoba.ds-2col-stacked-fluid .group-left .portrait a:focus-visible {
  outline-color: #fd0;
}

.node-osoba.ds-2col-stacked-fluid .group-left .portrait img {
  display: block;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .node-osoba.ds-2col-stacked-fluid .group-right {
    float: right;
    /* LTR */
    width: 70%;
  }
}

@media (min-width: 768px) {
  .node-osoba.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 20px;
  }
  .node-osoba.ds-2col-stacked-fluid .group-right .field-name-body > *:first-child {
    margin-top: 0;
  }
  .node-osoba.ds-2col-stacked-fluid .group-right .field-name-body > *:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .node-osoba.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 32px;
  }
}

@media (min-width: 1280px) {
  .node-osoba.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 48px;
  }
}

.node-osoba.ds-2col-stacked-fluid.group-one-column .group-left,
.node-osoba.ds-2col-stacked-fluid.group-one-column .group-right {
  width: 100%;
  float: none;
}

.node-osoba.ds-2col-stacked-fluid .group-footer {
  clear: both;
  padding-top: 2em;
}

@media (min-width: 768px) {
  .node-osoba .title {
    margin-top: 0;
    padding-left: 20px;
  }
  .page-node-414 .node-osoba .title,
  .page-node-424 .node-osoba .title,
  .page-node-5167 .node-osoba .title {
    margin-left: 20px;
  }
}

@media (min-width: 1024px) {
  .node-osoba .title {
    padding-left: 32px;
  }
  .page-node-414 .node-osoba .title,
  .page-node-424 .node-osoba .title,
  .page-node-5167 .node-osoba .title {
    margin-left: 32px;
  }
}

@media (min-width: 1280px) {
  .node-osoba .title {
    padding-left: 48px;
  }
  .page-node-414 .node-osoba .title,
  .page-node-424 .node-osoba .title,
  .page-node-5167 .node-osoba .title {
    margin-left: 48px;
  }
}

.page-node-414 .node-osoba .title,
.page-node-424 .node-osoba .title,
.page-node-5167 .node-osoba .title {
  border: 3px solid #000;
  display: inline-block;
  padding: 0 .25em;
}

.yellow-black .node-osoba .title {
  border-color: #fd0;
}

.node-osoba .group-roles {
  text-align: center;
}

.node-osoba .group-roles .role-list {
  display: inline-block;
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.node-osoba .group-roles .role-list li {
  font-size: 1.125em;
  padding: .25em 0;
  text-align: left;
}

.node-osoba .group-roles .role-list li a {
  border-bottom: 2px solid #0000fe;
  font-weight: 700;
  text-decoration: none;
}

.node-osoba .group-roles .role-list li a:link {
  color: #000;
}

.node-osoba .group-roles .role-list li a:visited {
  color: #000;
}

.node-osoba .group-roles .role-list li a:hover {
  color: #0000fe;
}

.node-osoba .group-roles .role-list li a:active {
  color: #0000fe;
}

.node-osoba .group-roles .role-list li a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-osoba .group-roles .role-list li a:link {
  color: #fd0;
}

.yellow-black .node-osoba .group-roles .role-list li a:visited {
  color: #fd0;
}

.yellow-black .node-osoba .group-roles .role-list li a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .node-osoba .group-roles .role-list li a:active {
  color: #fd0;
}

.yellow-black .node-osoba .group-roles .role-list li a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.yellow-black .node-osoba .group-roles .role-list li a {
  border-color: #fd0;
}

.yellow-black .node-osoba .group-roles .role-list li a:hover {
  text-decoration: none;
}

.node-type-page.page-node-1616 .sf-menu.sf-horizontal .newsletter {
  opacity: .1;
  pointer-events: none;
}

.node-page.view-mode-full {
  margin: 0 auto;
  max-width: 1000px;
}

.node-page .field-body {
  font-size: calc(1.125em + 0.125 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-page .field-body {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 120em) {
  .node-page .field-body {
    font-size: 1.25em;
  }
}

@media (min-width: 768px) {
  .node-page .field-body {
    margin-bottom: 2em;
  }
  .node-page .field-body > *:first-child {
    margin-top: 0;
  }
  .node-page .field-body > *:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .node-page .field-body {
    margin-bottom: 3em;
  }
}

@media (min-width: 1280px) {
  .node-page .field-body {
    margin-bottom: 4em;
  }
}

.node-page .field-body h2 {
  font-size: calc(1.5em + 0.75 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-page .field-body h2 {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .node-page .field-body h2 {
    font-size: 2.25em;
  }
}

.node-page .hscroll {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.node-page .hscroll table {
  margin: 0 0 1em;
}

@media (min-width: 768px) {
  .node-page .hscroll table {
    margin-bottom: 2em;
  }
}

.node-page .hscroll ~ .hscroll .table-swipe-message {
  top: .75em;
}

.node-page .hscroll ~ .hscroll table {
  margin-top: 1em;
}

@media (min-width: 768px) {
  .node-page .hscroll ~ .hscroll table {
    margin-top: 2em;
  }
}

.node-page .hscroll .table-swipe-message {
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0 20px 40px 0 white;
  -moz-box-shadow: 0 20px 40px 0 white;
  box-shadow: 0 20px 40px 0 white;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1;
}

.node-page .hscroll .table-swipe-message__container {
  padding: 2em;
}

.node-page .hscroll .table-swipe-message__container .fa {
  color: gray;
  font-size: 2.5em;
}

.node-page .hscroll .table-swipe-message__content span {
  color: gray;
  display: inline-block;
  font-size: 1.25em;
  font-weight: 700;
  margin-top: 1em;
}

.node-page .hscroll .table-swipe-message__button {
  background-color: #0000fe;
  border: 1px solid #cccccc;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-top: 1em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.node-page table.cennik {
  width: 100%;
}

.node-page table.cennik caption {
  border-bottom: 4px solid #000;
  font-size: 1.5em;
  font-weight: 700;
  padding-bottom: 10px;
  text-align: left;
}

@media (min-width: 768px) {
  .node-page table.cennik caption {
    text-align: center;
  }
}

.node-page table.cennik th {
  border: 0;
  padding: 0 5px;
}

.node-page table.cennik th[scope='colgroup'] {
  background-color: #f2f2f2;
  border: 0;
  color: #0000fe;
  font-weight: 700;
  height: 40px;
}

.node-page table.cennik .border-none {
  border: 0;
}

.node-page table.cennik .border-none th[scope='colgroup'] {
  position: relative;
}

.node-page table.cennik .border-none th[scope='colgroup']::before {
  background-color: #fff;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.node-page table.cennik thead tr {
  border-bottom: 0;
}

.node-page table.cennik thead td {
  height: 40px;
}

.node-page table.cennik tbody tr {
  border-bottom: 1px solid #000;
}

.node-page table.cennik tbody tr.row-header {
  border: 0;
  height: 60px;
}

.node-page table.cennik tbody tr.row-header th {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-right: 1px solid #fff;
  color: #000;
  display: inline-block;
  height: auto;
  margin: 10px 0 2px;
  padding: .35em .5em .25em;
}

@media (min-width: 768px) {
  .node-page table.cennik tbody tr.row-header th {
    height: 40px;
    line-height: 40px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.node-page table.cennik tbody tr.row-header th a {
  text-decoration: none;
}

.node-page table.cennik tbody tr.row-header th a:link {
  color: #fff;
}

.node-page table.cennik tbody tr.row-header th a:visited {
  color: #fff;
}

.node-page table.cennik tbody tr.row-header th a:hover {
  color: #fff;
}

.node-page table.cennik tbody tr.row-header th a:active {
  color: #fff;
}

.node-page table.cennik tbody tr.row-header th a:focus-visible {
  color: #fff;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-page table.cennik tbody tr.row-header th a:link {
  color: #fd0;
}

.yellow-black .node-page table.cennik tbody tr.row-header th a:visited {
  color: #fd0;
}

.yellow-black .node-page table.cennik tbody tr.row-header th a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .node-page table.cennik tbody tr.row-header th a:active {
  color: #fd0;
}

.yellow-black .node-page table.cennik tbody tr.row-header th a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.node-page table.cennik tbody th {
  height: 60px;
}

.node-page table.cennik tbody td {
  height: 60px;
  text-align: center;
}

.node-page table.cennik tbody td a {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  display: inline-block;
  font-size: 1.1875em;
  text-decoration: none;
}

.node-page table.cennik tbody td a:link {
  color: #000;
}

.node-page table.cennik tbody td a:visited {
  color: #000;
}

.node-page table.cennik tbody td a:hover {
  color: #0000fe;
}

.node-page table.cennik tbody td a:active {
  color: #0000fe;
}

.node-page table.cennik tbody td a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-page table.cennik tbody td a:link {
  color: #fd0;
}

.yellow-black .node-page table.cennik tbody td a:visited {
  color: #fd0;
}

.yellow-black .node-page table.cennik tbody td a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .node-page table.cennik tbody td a:active {
  color: #fd0;
}

.yellow-black .node-page table.cennik tbody td a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.node-page table.cennik tbody td.with-background {
  background-color: #f2f2f2;
  height: 40px;
}

.node-page .sections .content-section + .content-section {
  margin-top: 2em;
}

@media (min-width: 1024px) {
  .node-page .sections .content-section + .content-section {
    margin-top: 4em;
  }
}

.node-page .sections .content-section__title {
  font-size: calc(1.5em + 0.5 * ( (100vw - 22.5em) / 97.5));
  margin-top: 0;
}

@media screen and (max-width: 22.5em) {
  .node-page .sections .content-section__title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .node-page .sections .content-section__title {
    font-size: 2em;
  }
}

@media (min-width: 768px) {
  .node-page .sections .content-section__left {
    float: left;
    width: 50%;
  }
  [dir="rtl"] .node-page .sections .content-section__left {
    float: right;
  }
}

@media (min-width: 768px) {
  .node-page .sections .content-section__left .content-wrapper {
    padding-right: 1em;
  }
}

@media (min-width: 1024px) {
  .node-page .sections .content-section__left .content-wrapper {
    padding-right: 2em;
  }
}

@media (min-width: 768px) {
  .node-page .sections .content-section__right {
    float: right;
    width: 50%;
  }
  [dir="rtl"] .node-page .sections .content-section__right {
    float: left;
  }
}

@media (min-width: 768px) {
  .node-page .sections .content-section__right .content-wrapper {
    padding-left: 1em;
  }
}

@media (min-width: 1024px) {
  .node-page .sections .content-section__right .content-wrapper {
    padding-left: 2em;
  }
}

.node-page .sections .content-section.one-column .content-section__left,
.node-page .sections .content-section.one-column .content-section__right {
  width: 100%;
  float: none;
}

.node-page .sections .content-section.one-column .content-section__left .content-wrapper,
.node-page .sections .content-section.one-column .content-section__right .content-wrapper {
  padding: 0;
}

.node-page .sections .content-section .content-wrapper {
  font-size: calc(1.125em + 0.125 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-page .sections .content-section .content-wrapper {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 120em) {
  .node-page .sections .content-section .content-wrapper {
    font-size: 1.25em;
  }
}

@media (min-width: 768px) {
  .node-page .sections .content-section .content-wrapper > *:first-child {
    margin-top: 0;
  }
  .node-page .sections .content-section .content-wrapper > *:last-child {
    margin-bottom: 0;
  }
}

.node-page .sections .content-section h3 {
  margin: 1.5em 0 .75em;
}

.node-page .sections .content-section h3 + p,
.node-page .sections .content-section h3 + ul,
.node-page .sections .content-section h3 + ol {
  margin-top: 0;
}

.node-page .related-content-header {
  font-size: calc(1.5em + 0.75 * ( (100vw - 22.5em) / 97.5));
  line-height: 1;
  margin: 2em 0 1em;
  position: relative;
}

@media screen and (max-width: 22.5em) {
  .node-page .related-content-header {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .node-page .related-content-header {
    font-size: 2.25em;
  }
}

.node-page .related-content-header::before {
  background-color: #0000fe;
  content: '';
  display: block;
  height: 4px;
  margin: 0 0 .5em;
  width: 40%;
}

@media (min-width: 768px) {
  .node-page .related-content-header::before {
    height: 6px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

.yellow-black .node-page .related-content-header::before {
  background-color: #fd0;
}

.node-page .related-content-header span {
  background-color: #fff;
  line-height: 1.2;
  padding: 0 20px 0 0;
  position: relative;
  z-index: 1;
}

.yellow-black .node-page .related-content-header span {
  background-color: #1e1e1e;
}

.node-page .related-content-header + .related-content {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.node-page .related-content {
  border-top: 6px solid #0000fe;
  margin-top: 2em;
  padding-top: 1em;
}

@media (min-width: 480px) {
  .node-page .related-content {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .node-page .related-content {
    gap: 48px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 4em;
    padding-top: 2em;
  }
}

.yellow-black .node-page .related-content {
  border-color: #fd0;
}

.node-page .related-content .related-content-item {
  margin-bottom: 2em;
}

@media (min-width: 480px) {
  .node-page .related-content .related-content-item {
    margin-bottom: 0;
  }
}

.node-podcast.view-mode-full {
  margin: 0 auto;
  max-width: 1000px;
}

.node-podcast .field-body {
  margin-top: 2em;
}

@media (min-width: 768px) {
  .node-podcast .field-body > *:first-child {
    margin-top: 0;
  }
  .node-podcast .field-body > *:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .node-podcast .field-body {
    margin-top: 3em;
  }
}

@media (min-width: 1280px) {
  .node-podcast .field-body {
    margin-top: 4em;
  }
}

.node-podcast .field-body h2 {
  font-size: calc(1.5em + 0.75 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-podcast .field-body h2 {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .node-podcast .field-body h2 {
    font-size: 2.25em;
  }
}

.node-popup {
  padding-bottom: 8em;
}

.node-promotion {
  padding-bottom: 8em;
}

@media (min-width: 1024px) {
  .node-type-spektakl .layout-swap__bottom {
    margin-top: -60px;
  }
}

.node-spektakl.ds-2col-stacked-fluid .group-header {
  margin-bottom: 2em;
}

@media (min-width: 1024px) {
  .node-spektakl.ds-2col-stacked-fluid .group-header {
    margin-bottom: 3em;
  }
}

@media (min-width: 1280px) {
  .node-spektakl.ds-2col-stacked-fluid .group-header {
    margin-bottom: 4em;
  }
}

.node-spektakl.ds-2col-stacked-fluid .group-left {
  text-align: center;
}

@media (min-width: 768px) {
  .node-spektakl.ds-2col-stacked-fluid .group-left {
    float: left;
    /* LTR */
    width: 30%;
  }
}

.node-spektakl.ds-2col-stacked-fluid .group-left .poster a {
  display: inline-block;
}

.node-spektakl.ds-2col-stacked-fluid .group-left .poster a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-spektakl.ds-2col-stacked-fluid .group-left .poster a:focus-visible {
  outline-color: #fd0;
}

.node-spektakl.ds-2col-stacked-fluid .group-left .poster img {
  display: block;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .node-spektakl.ds-2col-stacked-fluid .group-right {
    float: right;
    /* LTR */
    width: 70%;
  }
}

@media (min-width: 768px) {
  .node-spektakl.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 1em;
  }
  .node-spektakl.ds-2col-stacked-fluid .group-right .field-name-body > *:first-child {
    margin-top: 0;
  }
  .node-spektakl.ds-2col-stacked-fluid .group-right .field-name-body > *:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .node-spektakl.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 2em;
  }
}

@media (min-width: 1280px) {
  .node-spektakl.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 3em;
  }
}

.node-spektakl.ds-2col-stacked-fluid.group-one-column .group-left,
.node-spektakl.ds-2col-stacked-fluid.group-one-column .group-right {
  width: 100%;
  float: none;
}

.node-spektakl.ds-2col-stacked-fluid .group-footer {
  clear: both;
  padding-top: 2em;
}

.node-spektakl .group-title {
  margin: 0 auto;
}

@media (min-width: 1280px) {
  .node-spektakl .group-title {
    width: 90%;
  }
  .node-spektakl .group-title__wrapper {
    float: left;
    width: 50%;
  }
}

.node-spektakl .group-title .title {
  font-size: 2em;
  margin: .5em 0 0;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 360px) {
  .node-spektakl .group-title .title {
    font-size: 2.25em;
  }
}

@media (min-width: 768px) {
  .node-spektakl .group-title .title {
    font-size: 3em;
  }
}

@media (min-width: 1280px) {
  .node-spektakl .group-title .title {
    border-right: 6px solid #0000fe;
    margin-top: 0;
    padding-right: 30px;
    text-align: right;
  }
  .yellow-black .node-spektakl .group-title .title {
    border-color: #fd0;
  }
}

@media (min-width: 1440px) {
  .node-spektakl .group-title .title {
    font-size: 3.625em;
  }
}

@media (min-width: 1680px) {
  .node-spektakl .group-title .title {
    font-size: 4.25em;
  }
}

.node-spektakl .group-title .glyph {
  margin: 0;
}

.node-spektakl .group-title .glyph.before {
  margin: 0 auto;
}

@media (min-width: 1280px) {
  .node-spektakl .group-title .glyph.before {
    display: none;
  }
}

.node-spektakl .group-title .glyph.after {
  display: none;
}

@media (min-width: 1280px) {
  .node-spektakl .group-title .glyph.after {
    display: block;
    float: left;
    margin: 15px 0 0 30px;
  }
}

@media (min-width: 768px) {
  .node-spektakl .group-information {
    padding-top: 1.5em;
    padding-left: 1em;
  }
}

@media (min-width: 1024px) {
  .node-spektakl .group-information {
    padding-left: 2em;
  }
}

@media (min-width: 1280px) {
  .node-spektakl .group-information {
    padding-left: 3em;
  }
}

.node-spektakl .group-information .info-item {
  border-left: 2px solid #0000fe;
  font-size: 1.125em;
  margin-top: 2em;
  padding-left: .75em;
  padding-right: .5em;
}

.yellow-black .node-spektakl .group-information .info-item {
  border-color: #fd0;
}

@media (min-width: 768px) {
  .node-spektakl .group-information .info-item {
    float: left;
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .node-spektakl .group-information .info-item {
    font-size: 1.3125em;
    width: 33.3333%;
  }
}

.node-spektakl .group-information .info-item .label-inline {
  font-weight: 200;
}

.node-spektakl .group-information .info-item .label-inline + span {
  margin-top: 10px;
}

.node-spektakl .group-information .info-item span {
  display: block;
  font-weight: 400;
}

.node-spektakl .group-artists__item {
  font-family: "Inter UI", sans-serif;
  font-size: 1.125em;
  padding: .25em 0;
}

@media (min-width: 1024px) {
  .node-spektakl .group-artists__item {
    font-size: 1.3125em;
  }
}

.node-spektakl .group-artists__job {
  display: inline-block;
  font-weight: 400;
}

@media (min-width: 768px) {
  .node-spektakl .group-artists__job {
    display: block;
    float: left;
    text-align: right;
    width: 49%;
  }
}

.node-spektakl .group-artists__job span::after {
  content: ':';
  display: inline-block;
}

.node-spektakl .group-artists__person {
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .node-spektakl .group-artists__person {
    margin-left: 51%;
    width: 49%;
  }
}

.node-spektakl .group-artists__person span {
  display: inline-block;
}

.node-spektakl .group-artists__person span strong {
  display: inline-block;
  font-weight: 700;
}

.node-spektakl .group-artists__person span strong + strong::before {
  content: ',';
  display: inline-block;
  margin-right: 4px;
}

.node-spektakl .group-artists__person a {
  border-bottom: 3px solid #0000fe;
  text-decoration: none;
}

.node-spektakl .group-artists__person a:link {
  color: #000;
}

.node-spektakl .group-artists__person a:visited {
  color: #000;
}

.node-spektakl .group-artists__person a:hover {
  color: #0000fe;
}

.node-spektakl .group-artists__person a:active {
  color: #0000fe;
}

.node-spektakl .group-artists__person a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-spektakl .group-artists__person a:link {
  color: #fd0;
}

.yellow-black .node-spektakl .group-artists__person a:visited {
  color: #fd0;
}

.yellow-black .node-spektakl .group-artists__person a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .node-spektakl .group-artists__person a:active {
  color: #fd0;
}

.yellow-black .node-spektakl .group-artists__person a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.yellow-black .node-spektakl .group-artists__person a {
  border-color: #fd0;
}

.yellow-black .node-spektakl .group-artists__person a:hover {
  text-decoration: none;
}

.node-spektakl .group-cast .actors {
  display: flex;
  flex-wrap: wrap;
}

.node-spektakl .group-cast .actor {
  margin: 1em auto;
}

@media (min-width: 360px) {
  .node-spektakl .group-cast .actor {
    margin-left: 2%;
    margin-right: 2%;
    width: 46%;
  }
}

@media (min-width: 768px) {
  .node-spektakl .group-cast .actor {
    width: 29.333%;
  }
}

@media (min-width: 1024px) {
  .node-spektakl .group-cast .actor {
    width: 21%;
  }
}

@media (min-width: 1680px) {
  .node-spektakl .group-cast .actor {
    width: 16%;
  }
}

.node-spektakl .group-cast .actor .title {
  border-bottom: 3px solid #0000fe;
  font-size: 1.125em;
  line-height: 1.25;
  margin: 0;
  padding-bottom: 10px;
  text-transform: uppercase;
  transition: color .3s ease-in-out;
}

@media (min-width: 768px) {
  .node-spektakl .group-cast .actor .title {
    font-size: 1.3125em;
  }
}

.yellow-black .node-spektakl .group-cast .actor .title {
  border-color: #fd0;
}

.node-spektakl .group-cast .actor .role {
  display: block;
  margin-top: 10px;
}

.node-spektakl .group-cast .actor.actor-image .content {
  position: relative;
  max-width: 240px;
  margin: 0 auto;
}

.node-spektakl .group-cast .actor.actor-image .content:hover .group-image img {
  opacity: .1;
}

.node-spektakl .group-cast .actor.actor-image .content:hover .group-image .glyph {
  opacity: 1;
}

.node-spektakl .group-cast .actor.actor-image .content:hover .title {
  color: #0000fe;
}

.yellow-black .node-spektakl .group-cast .actor.actor-image .content:hover .title {
  color: #fd0;
}

.node-spektakl .group-cast .actor.actor-image .node-414 .title,
.node-spektakl .group-cast .actor.actor-image .node-424 .title,
.node-spektakl .group-cast .actor.actor-image .node-5167 .title {
  border: 3px solid #000;
  padding: 5px;
}

.yellow-black .node-spektakl .group-cast .actor.actor-image .node-414 .title, .yellow-black
.node-spektakl .group-cast .actor.actor-image .node-424 .title, .yellow-black
.node-spektakl .group-cast .actor.actor-image .node-5167 .title {
  border-color: #fd0;
}

.node-spektakl .group-cast .actor.actor-image .group-image {
  position: relative;
}

.node-spektakl .group-cast .actor.actor-image .group-image img {
  display: block;
  margin: 0 auto;
  transition: opacity .3s ease-in-out;
}

.node-spektakl .group-cast .actor.actor-image .group-image .glyph {
  background-image: url("../images/glyph-eye.svg");
  background-position: center center;
  background-size: cover;
  height: 75px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease-in-out;
  width: 150px;
}

.yellow-black .node-spektakl .group-cast .actor.actor-image .group-image .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.node-spektakl .group-cast .actor.actor-image .group-image + .title {
  margin-top: 1em;
}

.node-spektakl .group-cast .actor.actor-image a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  text-indent: -9999px;
}

.node-spektakl .group-cast .actor.actor-image a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-spektakl .group-cast .actor.actor-image a:focus-visible {
  outline-color: #fd0;
}

.node-spektakl .group-cast .actor.actor-image a + .title {
  border-bottom: 0;
  margin-top: .5em;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .node-spektakl .group-cast .actor.actor-image a + .title {
    font-size: 1.125em;
  }
}

.node-spektakl .group-cast .break {
  flex-basis: 100%;
  height: 0;
}

.node-spektakl .group-cast .additional-cast {
  clear: both;
  margin: 0 2%;
}

.node-spektakl .group-cast .additional-cast .label-above {
  font-size: 1.3125em;
  font-weight: 600;
  margin-top: 2em;
}

.node-spektakl .group-cast .additional-cast p strong {
  font-weight: 700;
  text-transform: uppercase;
}

.node-spektakl .group-when-we-play .view-repertoire td.pipe-3 span {
  display: none;
}

.node-spektakl .group-iframe .iframe_title {
  text-align: center;
  margin: 0 0 1em;
}

@media (min-width: 1024px) {
  .node-spektakl .group-iframe .iframe_title {
    font-size: 2.8125em;
  }
}

.node-spektakl .group-multimedia .group-video-items {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.node-spektakl .group-multimedia .video-item {
  font-size: 16px;
  padding-bottom: 25px;
}

@media (min-width: 480px) {
  .node-spektakl .group-multimedia .video-item {
    width: 50%;
  }
  .node-spektakl .group-multimedia .video-item:nth-of-type(2n+1) .group-content {
    margin-right: 12px;
  }
  .node-spektakl .group-multimedia .video-item:nth-of-type(2n+2) .group-content {
    margin-left: 12px;
  }
}

@media (min-width: 1024px) {
  .node-spektakl .group-multimedia .video-item {
    width: 33.3333%;
  }
  .node-spektakl .group-multimedia .video-item:nth-of-type(3n+1) .group-content {
    margin-right: 16px;
    margin-left: 0;
  }
  .node-spektakl .group-multimedia .video-item:nth-of-type(3n+2) .group-content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .node-spektakl .group-multimedia .video-item:nth-of-type(3n+3) .group-content {
    margin-left: 16px;
    margin-right: 0;
  }
}

.node-spektakl .group-multimedia .video-item a {
  display: block;
  margin-bottom: 35px;
  position: relative;
}

.node-spektakl .group-multimedia .video-item a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-spektakl .group-multimedia .video-item a:focus-visible {
  outline-color: #fd0;
}

.node-spektakl .group-multimedia .video-item a::after {
  font-size: 1.875rem;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  font-family: "FontAwesome";
  line-height: 50px;
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  text-align: center;
  transform: translate(-50%, 50%);
}

.yellow-black .node-spektakl .group-multimedia .video-item a::after {
  background-color: #fd0;
}

.node-spektakl .group-multimedia .video-item img {
  display: block;
}

.node-spektakl .group-multimedia .video-item.youtube a::after {
  content: '\f167';
}

.yellow-black .node-spektakl .group-multimedia .video-item.youtube a::after {
  color: #1e1e1e;
}

.node-spektakl .group-multimedia .video-item.vimeo a::after {
  content: '\f27d';
}

.yellow-black .node-spektakl .group-multimedia .video-item.vimeo a::after {
  color: #1e1e1e;
}

.node-spektakl .group-multimedia .video-item .video-embed-description {
  font-size: 0.875em;
  text-align: center;
}

.node-spektakl .group-multimedia .video-item .title {
  font-size: calc(1em + 0.5 * ( (100vw - 22.5em) / 97.5));
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

@media screen and (max-width: 22.5em) {
  .node-spektakl .group-multimedia .video-item .title {
    font-size: 1em;
  }
}

@media screen and (min-width: 120em) {
  .node-spektakl .group-multimedia .video-item .title {
    font-size: 1.5em;
  }
}

.node-spektakl .group-additional-information {
  border-top: 4px solid #0000fe;
  margin-top: 2em;
}

.yellow-black .node-spektakl .group-additional-information {
  border-top-color: #fd0;
}

.node-spektakl .group-additional-information * {
  color: #000;
  font-size: 1em;
}

.yellow-black .node-spektakl .group-additional-information * {
  color: #fd0;
}

.node-spektakl .group-additional-information .additional-info__section {
  border-bottom: 1px solid #000;
}

.yellow-black .node-spektakl .group-additional-information .additional-info__section {
  border-bottom-color: #fd0;
}

.node-spektakl .group-additional-information .logotypes .logo {
  display: inline-block;
  vertical-align: middle;
}

.node-spektakl .group-additional-information .logotypes .logo a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .node-spektakl .group-additional-information .logotypes .logo a:focus-visible {
  outline-color: #fd0;
}

.node-spektakl .group-additional-information .logotypes .logo img {
  display: block;
  margin: 10px;
  max-height: 60px;
  width: auto;
}

.node-spektakl .group-review .item-list,
.node-spektakl .group-attachments .item-list {
  list-style: none;
  padding: 0;
}

.node-spektakl .group-review .item-list li,
.node-spektakl .group-attachments .item-list li {
  font-size: calc(1.1875em + 0.3125 * ( (100vw - 22.5em) / 97.5));
  overflow: hidden;
  padding: 1em 0 1em 1em;
  transition: background-color .3s ease-in-out;
}

@media screen and (max-width: 22.5em) {
  .node-spektakl .group-review .item-list li,
  .node-spektakl .group-attachments .item-list li {
    font-size: 1.1875em;
  }
}

@media screen and (min-width: 120em) {
  .node-spektakl .group-review .item-list li,
  .node-spektakl .group-attachments .item-list li {
    font-size: 1.5em;
  }
}

@media (min-width: 768px) {
  .node-spektakl .group-review .item-list li,
  .node-spektakl .group-attachments .item-list li {
    padding-left: 2em;
  }
}

.node-spektakl .group-review .item-list li:nth-of-type(2n + 2),
.node-spektakl .group-attachments .item-list li:nth-of-type(2n + 2) {
  background-color: #f2f2f2;
}

.yellow-black .node-spektakl .group-review .item-list li:nth-of-type(2n + 2), .yellow-black
.node-spektakl .group-attachments .item-list li:nth-of-type(2n + 2) {
  background-color: transparent;
}

.node-spektakl .group-review .item-list li:hover,
.node-spektakl .group-attachments .item-list li:hover {
  background-color: #e6e6e6;
}

.yellow-black .node-spektakl .group-review .item-list li:hover, .yellow-black
.node-spektakl .group-attachments .item-list li:hover {
  background-color: transparent;
}

.node-spektakl .group-review .item-list .file.active .fa,
.node-spektakl .group-attachments .item-list .file.active .fa {
  color: #0000fe;
}

.yellow-black .node-spektakl .group-review .item-list .file.active .fa, .yellow-black
.node-spektakl .group-attachments .item-list .file.active .fa {
  color: #fd0;
}

.node-spektakl .group-review .item-list .title,
.node-spektakl .group-attachments .item-list .title {
  font-size: calc(1em + 0.5 * ( (100vw - 22.5em) / 97.5));
  font-weight: 600;
}

@media screen and (max-width: 22.5em) {
  .node-spektakl .group-review .item-list .title,
  .node-spektakl .group-attachments .item-list .title {
    font-size: 1em;
  }
}

@media screen and (min-width: 120em) {
  .node-spektakl .group-review .item-list .title,
  .node-spektakl .group-attachments .item-list .title {
    font-size: 1.5em;
  }
}

.node-spektakl .group-review .item-list a,
.node-spektakl .group-attachments .item-list a {
  display: inline-block;
  text-decoration: none;
}

.node-spektakl .group-review .item-list a:link,
.node-spektakl .group-attachments .item-list a:link {
  color: #000;
}

.node-spektakl .group-review .item-list a:visited,
.node-spektakl .group-attachments .item-list a:visited {
  color: #000;
}

.node-spektakl .group-review .item-list a:hover,
.node-spektakl .group-attachments .item-list a:hover {
  color: #0000fe;
}

.node-spektakl .group-review .item-list a:active,
.node-spektakl .group-attachments .item-list a:active {
  color: #0000fe;
}

.node-spektakl .group-review .item-list a:focus-visible,
.node-spektakl .group-attachments .item-list a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .node-spektakl .group-review .item-list a:link, .yellow-black
.node-spektakl .group-attachments .item-list a:link {
  color: #fd0;
}

.yellow-black .node-spektakl .group-review .item-list a:visited, .yellow-black
.node-spektakl .group-attachments .item-list a:visited {
  color: #fd0;
}

.yellow-black .node-spektakl .group-review .item-list a:hover, .yellow-black
.node-spektakl .group-attachments .item-list a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .node-spektakl .group-review .item-list a:active, .yellow-black
.node-spektakl .group-attachments .item-list a:active {
  color: #fd0;
}

.yellow-black .node-spektakl .group-review .item-list a:focus-visible, .yellow-black
.node-spektakl .group-attachments .item-list a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.node-spektakl .group-review .item-list .fa,
.node-spektakl .group-attachments .item-list .fa {
  color: gray;
  margin-right: .25em;
  margin-top: .15em;
  vertical-align: top;
  transition: color .3s ease-in-out;
}

.yellow-black .node-spektakl .group-review .item-list .fa, .yellow-black
.node-spektakl .group-attachments .item-list .fa {
  color: #fd0;
}

.node-spektakl .group-review .item-list span.ext,
.node-spektakl .group-attachments .item-list span.ext {
  padding-left: 8px;
}

.node-spektakl .group-review .item-list span.ext::after,
.node-spektakl .group-attachments .item-list span.ext::after {
  font-size: .75em;
  color: gray;
}

.yellow-black .node-spektakl .group-review .item-list span.ext::after, .yellow-black
.node-spektakl .group-attachments .item-list span.ext::after {
  color: #fd0;
}

@media (min-width: 768px) {
  .node-wydarzenie.ds-2col-stacked-fluid .group-left {
    float: left;
    /* LTR */
    width: 30%;
  }
}

@media (min-width: 768px) {
  .node-wydarzenie.ds-2col-stacked-fluid .group-right {
    float: right;
    /* LTR */
    width: 70%;
  }
}

@media (min-width: 768px) {
  .node-wydarzenie.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 1em;
  }
  .node-wydarzenie.ds-2col-stacked-fluid .group-right .field-name-body > *:first-child {
    margin-top: 0;
  }
  .node-wydarzenie.ds-2col-stacked-fluid .group-right .field-name-body > *:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .node-wydarzenie.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 2em;
  }
}

@media (min-width: 1280px) {
  .node-wydarzenie.ds-2col-stacked-fluid .group-right .field-name-body {
    padding-left: 3em;
  }
}

.node-wydarzenie.ds-2col-stacked-fluid.group-one-column .group-left,
.node-wydarzenie.ds-2col-stacked-fluid.group-one-column .group-right {
  width: 100%;
  float: none;
}

.node-wydarzenie.ds-2col-stacked-fluid.group-one-column .field-name-body {
  margin: 0 auto;
  max-width: 800px;
  padding-left: 0;
}

.node-wydarzenie.ds-2col-stacked-fluid .group-footer {
  clear: both;
  padding-top: 2em;
}

.node-wydarzenie.view-mode-full .title {
  font-size: calc(1.5em + 2.25 * ( (100vw - 22.5em) / 97.5));
}

@media screen and (max-width: 22.5em) {
  .node-wydarzenie.view-mode-full .title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .node-wydarzenie.view-mode-full .title {
    font-size: 3.75em;
  }
}

@media (min-width: 768px) {
  .node-wydarzenie.view-mode-full .title {
    margin-top: 0;
    padding-top: .25em;
    position: relative;
    width: 60%;
  }
  .node-wydarzenie.view-mode-full .title::before {
    background-color: #0000fe;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    width: 280px;
  }
  .yellow-black .node-wydarzenie.view-mode-full .title::before {
    background-color: #fd0;
  }
}

.node-wydarzenie.view-mode-full .cover > *:first-child {
  margin-top: 0;
}

.node-wydarzenie.view-mode-full .cover > *:last-child {
  margin-bottom: 0;
}

.node-wydarzenie.view-mode-full .cover a {
  display: inline-block;
}

.node-wydarzenie.view-mode-full .cover a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .node-wydarzenie.view-mode-full .cover a:focus-visible {
  outline-color: #fd0;
}

.node-wydarzenie.view-mode-full .cover img {
  display: block;
}

.node-wydarzenie.view-mode-full .date {
  margin: .5em 0 1.5em;
}

.node-wydarzenie.view-mode-full .date .date-repeat-rule {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.node-wydarzenie.view-mode-full .date .date-display-single {
  display: inline-block;
  font-size: 1.5em;
}

.node-wydarzenie.view-mode-full .date .date-display-single:only-child {
  border-bottom: 2px solid #000;
}

.yellow-black .node-wydarzenie.view-mode-full .date .date-display-single:only-child {
  border-color: #fd0;
}

.node-wydarzenie.view-mode-full .date .date-display-single + .date-display-single::before {
  content: '/';
  display: inline-block;
  margin: 0 .5rem;
}

.node-wydarzenie.view-mode-full .field-name-body .image-caption img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .node-wydarzenie.view-mode-full .field-name-body .image-caption img {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .node-wydarzenie.view-mode-full .field-name-body .caption {
    width: 80%;
  }
}

.node-wydarzenie.view-mode-full .caption {
  color: #666666;
  display: block;
  font-size: 0.875em;
  line-height: 1.1;
  margin: 5px auto;
}

.node-wydarzenie.view-mode-full .group-gallery h2 {
  border-bottom: 4px solid #000;
}

@media (min-width: 1024px) {
  .node-wydarzenie.view-mode-full .group-gallery h2 {
    max-width: calc(33.3333% - 16px);
  }
}

@media (min-width: 480px) {
  .node-wydarzenie.view-mode-full .group-gallery .item-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery {
  margin-bottom: 2em;
}

@media (min-width: 480px) {
  .node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery {
    width: 50%;
  }
  .node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery:nth-child(2n + 1) .group-content {
    margin-right: 12px;
  }
  .node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery:nth-child(2n + 2) .group-content {
    margin-left: 12px;
  }
}

@media (min-width: 1024px) {
  .node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery {
    width: 33.3333%;
  }
  .node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery:nth-child(3n + 1) .group-content {
    margin-right: 16px;
    margin-left: 0;
  }
  .node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery:nth-child(3n + 2) .group-content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .node-wydarzenie.view-mode-full .group-gallery .node-node-gallery-gallery:nth-child(3n + 3) .group-content {
    margin-left: 16px;
    margin-right: 0;
  }
}

.page-404 .main-content,
.page-403 .main-content {
  padding-bottom: 4em;
  text-align: center;
}

.page-404 .action-links,
.page-403 .action-links {
  list-style: none;
  padding: 0;
}

[dir="rtl"] .page-404 .action-links, [dir="rtl"]
.page-403 .action-links {
  padding: 0;
}

.page-file .file-image {
  margin-bottom: 40px;
}

.page-file .file-image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.view-galeria-plakatu .view-content {
  margin: 2em 0;
}

@media (min-width: 480px) {
  .view-galeria-plakatu .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 480px) {
  .view-galeria-plakatu .view-content .views-row {
    margin-bottom: 2em;
    width: 50%;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-odd .content {
    margin-right: 12px;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-even .content {
    margin-left: 12px;
  }
}

@media (min-width: 768px) {
  .view-galeria-plakatu .view-content .views-row {
    width: 33.3333%;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-1 .content, .view-galeria-plakatu .view-content .views-row.views-row-4 .content, .view-galeria-plakatu .view-content .views-row.views-row-7 .content, .view-galeria-plakatu .view-content .views-row.views-row-10 .content, .view-galeria-plakatu .view-content .views-row.views-row-13 .content, .view-galeria-plakatu .view-content .views-row.views-row-16 .content, .view-galeria-plakatu .view-content .views-row.views-row-19 .content, .view-galeria-plakatu .view-content .views-row.views-row-22 .content {
    margin-right: 16px;
    margin-left: 0;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-2 .content, .view-galeria-plakatu .view-content .views-row.views-row-5 .content, .view-galeria-plakatu .view-content .views-row.views-row-8 .content, .view-galeria-plakatu .view-content .views-row.views-row-11 .content, .view-galeria-plakatu .view-content .views-row.views-row-14 .content, .view-galeria-plakatu .view-content .views-row.views-row-17 .content, .view-galeria-plakatu .view-content .views-row.views-row-20 .content, .view-galeria-plakatu .view-content .views-row.views-row-23 .content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-3 .content, .view-galeria-plakatu .view-content .views-row.views-row-6 .content, .view-galeria-plakatu .view-content .views-row.views-row-9 .content, .view-galeria-plakatu .view-content .views-row.views-row-12 .content, .view-galeria-plakatu .view-content .views-row.views-row-15 .content, .view-galeria-plakatu .view-content .views-row.views-row-18 .content, .view-galeria-plakatu .view-content .views-row.views-row-21 .content, .view-galeria-plakatu .view-content .views-row.views-row-24 .content {
    margin-left: 16px;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .view-galeria-plakatu .view-content .views-row {
    width: 25%;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-1 .content, .view-galeria-plakatu .view-content .views-row.views-row-5 .content, .view-galeria-plakatu .view-content .views-row.views-row-9 .content, .view-galeria-plakatu .view-content .views-row.views-row-13 .content, .view-galeria-plakatu .view-content .views-row.views-row-17 .content, .view-galeria-plakatu .view-content .views-row.views-row-21 .content {
    margin-right: 16px;
    margin-left: 0;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-2 .content, .view-galeria-plakatu .view-content .views-row.views-row-6 .content, .view-galeria-plakatu .view-content .views-row.views-row-10 .content, .view-galeria-plakatu .view-content .views-row.views-row-14 .content, .view-galeria-plakatu .view-content .views-row.views-row-18 .content, .view-galeria-plakatu .view-content .views-row.views-row-22 .content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-3 .content, .view-galeria-plakatu .view-content .views-row.views-row-7 .content, .view-galeria-plakatu .view-content .views-row.views-row-11 .content, .view-galeria-plakatu .view-content .views-row.views-row-15 .content, .view-galeria-plakatu .view-content .views-row.views-row-19 .content, .view-galeria-plakatu .view-content .views-row.views-row-23 .content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .view-galeria-plakatu .view-content .views-row.views-row-4 .content, .view-galeria-plakatu .view-content .views-row.views-row-8 .content, .view-galeria-plakatu .view-content .views-row.views-row-12 .content, .view-galeria-plakatu .view-content .views-row.views-row-16 .content, .view-galeria-plakatu .view-content .views-row.views-row-20 .content, .view-galeria-plakatu .view-content .views-row.views-row-24 .content {
    margin-left: 16px;
    margin-right: 0;
  }
}

.view-galeria-plakatu .view-content .content {
  position: relative;
}

.view-galeria-plakatu .view-content .poster {
  position: relative;
}

.view-galeria-plakatu .view-content .poster:hover::after {
  opacity: 1;
}

.yellow-black .view-galeria-plakatu .view-content .poster:hover::after {
  opacity: 0;
}

.view-galeria-plakatu .view-content .poster a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .view-galeria-plakatu .view-content .poster a:focus-visible {
  outline-color: #fd0;
}

.view-galeria-plakatu .view-content .poster a:hover img, .view-galeria-plakatu .view-content .poster a:focus img {
  opacity: .5;
}

.yellow-black .view-galeria-plakatu .view-content .poster a:hover img, .yellow-black .view-galeria-plakatu .view-content .poster a:focus img {
  opacity: .1;
}

.view-galeria-plakatu .view-content .poster a:hover + .glyph, .view-galeria-plakatu .view-content .poster a:focus + .glyph {
  opacity: 1;
}

.view-galeria-plakatu .view-content .poster img {
  display: block;
  margin: 0 auto;
  transition: opacity .3s ease-in-out;
}

.view-galeria-plakatu .view-content .poster .glyph {
  background-image: url("../images/glyph-eye-white.svg");
  background-position: center center;
  background-size: cover;
  height: 111px;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease-in-out;
  width: 221px;
  z-index: 1;
}

.yellow-black .view-galeria-plakatu .view-content .poster .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.view-galeria-plakatu .view-content .poster::after {
  background: linear-gradient(rgba(26, 25, 214, 0.1), rgba(26, 25, 214, 0.5), #1a19d6);
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity .3s ease-in-out;
  width: 100%;
}

.view-galeria-plakatu .view-content .title {
  font-size: calc(1.1875em + 0.6875 * ( (100vw - 22.5em) / 97.5));
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .view-galeria-plakatu .view-content .title {
    font-size: 1.1875em;
  }
}

@media screen and (min-width: 120em) {
  .view-galeria-plakatu .view-content .title {
    font-size: 1.875em;
  }
}

.view-node-gallery-gallery-summaries .view-content {
  margin: 2em 0;
}

@media (min-width: 480px) {
  .view-node-gallery-gallery-summaries .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 480px) {
  .view-node-gallery-gallery-summaries .view-content .views-row {
    margin-bottom: 2em;
    width: 50%;
  }
  .view-node-gallery-gallery-summaries .view-content .views-row.views-row-odd .group-content {
    margin-right: 12px;
  }
  .view-node-gallery-gallery-summaries .view-content .views-row.views-row-even .group-content {
    margin-left: 12px;
  }
}

@media (min-width: 1024px) {
  .view-node-gallery-gallery-summaries .view-content .views-row {
    width: 33.3333%;
  }
  .view-node-gallery-gallery-summaries .view-content .views-row.views-row-1 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-4 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-7 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-10 .group-content {
    margin-right: 16px;
    margin-left: 0;
  }
  .view-node-gallery-gallery-summaries .view-content .views-row.views-row-2 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-5 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-8 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-11 .group-content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .view-node-gallery-gallery-summaries .view-content .views-row.views-row-3 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-6 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-9 .group-content, .view-node-gallery-gallery-summaries .view-content .views-row.views-row-12 .group-content {
    margin-left: 16px;
    margin-right: 0;
  }
}

.page-kalendarium .calendar-day {
  padding-top: 2em;
}

.page-kalendarium .calendar-day__header h2 {
  font-size: calc(2em + 1 * ( (100vw - 22.5em) / 97.5));
  color: #0000fe;
  text-align: center;
}

@media screen and (max-width: 22.5em) {
  .page-kalendarium .calendar-day__header h2 {
    font-size: 2em;
  }
}

@media screen and (min-width: 120em) {
  .page-kalendarium .calendar-day__header h2 {
    font-size: 3em;
  }
}

.page-kalendarium .calendar-day__result {
  text-align: center;
}

.page-kalendarium .calendar-day__result .views-row {
  margin-top: 20px;
  text-align: left;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day__result .views-row {
    display: inline-block;
    width: calc(50% - 4px);
    vertical-align: top;
  }
}

@media (min-width: 1280px) {
  .page-kalendarium .calendar-day__result .views-row {
    width: calc(40% - 4px);
  }
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day__result .views-row .views-row__content {
    margin: 0 20px;
  }
}

.page-kalendarium .calendar-day .spektakl .image__wydarzenie {
  display: none;
}

.page-kalendarium .calendar-day .image {
  display: inline-block;
  max-width: 360px;
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .image {
    margin-right: 1em;
  }
}

.page-kalendarium .calendar-day .image a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .page-kalendarium .calendar-day .image a:focus-visible {
  outline-color: #fd0;
}

.page-kalendarium .calendar-day .image img {
  display: block;
}

.page-kalendarium .calendar-day .links {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .links {
    display: inline-block;
    vertical-align: bottom;
  }
}

@media (min-width: 1680px) {
  .page-kalendarium .calendar-day .links {
    margin-top: 0;
  }
}

.page-kalendarium .calendar-day .read-more {
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .read-more {
    display: block;
  }
}

.page-kalendarium .calendar-day .read-more a {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  font-size: 0.875em;
  font-family: "Inter UI", sans-serif;
  text-transform: uppercase;
}

.page-kalendarium .calendar-day .read-more a:link, .page-kalendarium .calendar-day .read-more a:visited {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.page-kalendarium .calendar-day .read-more a:hover, .page-kalendarium .calendar-day .read-more a:active, .page-kalendarium .calendar-day .read-more a:focus-visible {
  background-color: #0000fe;
  border: 1px solid #0000fe;
  color: #fff;
}

.page-kalendarium .calendar-day .read-more a:focus-visible {
  outline: 2px solid #0000fe;
}

.yellow-black .page-kalendarium .calendar-day .read-more a:link, .yellow-black .page-kalendarium .calendar-day .read-more a:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .page-kalendarium .calendar-day .read-more a:hover, .yellow-black .page-kalendarium .calendar-day .read-more a:active, .yellow-black .page-kalendarium .calendar-day .read-more a:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .page-kalendarium .calendar-day .read-more a:focus-visible {
  outline: 2px solid #fd0;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .read-more a {
    display: block;
  }
}

.page-kalendarium .calendar-day .reservation {
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .reservation {
    display: block;
    margin-top: 20px;
  }
}

.page-kalendarium .calendar-day .reservation a {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  font-size: 0.875em;
  text-transform: lowercase;
}

.page-kalendarium .calendar-day .reservation a:link, .page-kalendarium .calendar-day .reservation a:visited {
  background-color: #0000fe;
  border: 1px solid #0000fe;
  color: #fff;
}

.page-kalendarium .calendar-day .reservation a:hover, .page-kalendarium .calendar-day .reservation a:active, .page-kalendarium .calendar-day .reservation a:focus-visible {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.page-kalendarium .calendar-day .reservation a:focus-visible {
  outline: 2px solid #0000fe;
}

.yellow-black .page-kalendarium .calendar-day .reservation a:link, .yellow-black .page-kalendarium .calendar-day .reservation a:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .page-kalendarium .calendar-day .reservation a:hover, .yellow-black .page-kalendarium .calendar-day .reservation a:active, .yellow-black .page-kalendarium .calendar-day .reservation a:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .page-kalendarium .calendar-day .reservation a:focus-visible {
  outline: 2px solid #fd0;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .reservation a {
    font-size: 1.3125em;
    display: block;
  }
}

.page-kalendarium .calendar-day .premiere {
  color: #0000fe;
  display: block;
  font-size: .75em;
  line-height: 1;
}

.page-kalendarium .calendar-day .title {
  font-size: calc(1.5em + 0.75 * ( (100vw - 22.5em) / 97.5));
  margin: .5em 0 .5em 10px;
  text-align: left;
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .page-kalendarium .calendar-day .title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .page-kalendarium .calendar-day .title {
    font-size: 2.25em;
  }
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .title {
    margin-bottom: 0;
    margin-top: 40px;
    min-height: 90px;
  }
}

.page-kalendarium .calendar-day .title a {
  color: #000;
  text-decoration: none;
}

.page-kalendarium .calendar-day .info {
  background-color: #f2f2f2;
  padding: 10px;
}

.page-kalendarium .calendar-day .info span {
  display: inline-block;
}

.page-kalendarium .calendar-day .info .date {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  font-size: 1.25em;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .info .date {
    font-size: 1.875em;
  }
}

.page-kalendarium .calendar-day .info .underscore {
  background-color: #0000fe;
  display: inline-block;
  height: 2px;
  width: 20px;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .info .underscore {
    height: 6px;
    margin: 0 6px;
    width: 50px;
  }
}

.page-kalendarium .calendar-day .info .pipe {
  background-color: #000;
  height: 36px;
  margin: 0 6px -12px;
  width: 1px;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .info .pipe {
    height: 56px;
    margin: 0 12px -12px;
  }
}

.page-kalendarium .calendar-day .info .day {
  font-size: 1.125em;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .info .day {
    font-size: 1.5em;
  }
}

.page-kalendarium .calendar-day .info .day::first-letter {
  text-transform: uppercase;
}

.page-kalendarium .calendar-day .info .hour {
  font-size: 1.125em;
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .info .hour {
    font-size: 1.5em;
  }
}

.page-kalendarium .calendar-day .info .stage {
  font-size: 1em;
  display: block;
  font-weight: 700;
  margin-top: 10px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .page-kalendarium .calendar-day .info .stage {
    font-size: 1.1875em;
  }
}

@media (min-width: 1800px) {
  .page-kalendarium .calendar-day .info .stage {
    display: inline-block;
    margin-top: 0;
    max-width: 240px;
  }
}

.page-kalendarium .calendar-day__footer {
  display: none;
}

.page-kalendarium.yellow-black .calendar-day__header h2 {
  color: #fd0;
}

.page-kalendarium.yellow-black .calendar-day .read-more a,
.page-kalendarium.yellow-black .calendar-day .reservation a {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.page-kalendarium.yellow-black .calendar-day .read-more a:link, .page-kalendarium.yellow-black .calendar-day .read-more a:visited,
.page-kalendarium.yellow-black .calendar-day .reservation a:link,
.page-kalendarium.yellow-black .calendar-day .reservation a:visited {
  background-color: #1e1e1e;
  border: 1px solid #fd0;
  color: #fd0;
}

.page-kalendarium.yellow-black .calendar-day .read-more a:hover, .page-kalendarium.yellow-black .calendar-day .read-more a:active, .page-kalendarium.yellow-black .calendar-day .read-more a:focus-visible,
.page-kalendarium.yellow-black .calendar-day .reservation a:hover,
.page-kalendarium.yellow-black .calendar-day .reservation a:active,
.page-kalendarium.yellow-black .calendar-day .reservation a:focus-visible {
  background-color: #fd0;
  border: 1px solid #fd0;
  color: #1e1e1e;
}

.page-kalendarium.yellow-black .calendar-day .read-more a:focus-visible,
.page-kalendarium.yellow-black .calendar-day .reservation a:focus-visible {
  outline: 2px solid #fd0;
}

.yellow-black .page-kalendarium.yellow-black .calendar-day .read-more a:link, .yellow-black .page-kalendarium.yellow-black .calendar-day .read-more a:visited, .yellow-black
.page-kalendarium.yellow-black .calendar-day .reservation a:link, .yellow-black
.page-kalendarium.yellow-black .calendar-day .reservation a:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .page-kalendarium.yellow-black .calendar-day .read-more a:hover, .yellow-black .page-kalendarium.yellow-black .calendar-day .read-more a:active, .yellow-black .page-kalendarium.yellow-black .calendar-day .read-more a:focus-visible, .yellow-black
.page-kalendarium.yellow-black .calendar-day .reservation a:hover, .yellow-black
.page-kalendarium.yellow-black .calendar-day .reservation a:active, .yellow-black
.page-kalendarium.yellow-black .calendar-day .reservation a:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .page-kalendarium.yellow-black .calendar-day .read-more a:focus-visible, .yellow-black
.page-kalendarium.yellow-black .calendar-day .reservation a:focus-visible {
  outline: 2px solid #fd0;
}

.page-kalendarium.yellow-black .calendar-day .premiere {
  color: #fd0;
}

.page-kalendarium.yellow-black .calendar-day .title a {
  color: #fd0;
}

.page-kalendarium.yellow-black .calendar-day .title a:hover {
  text-decoration: underline;
}

.page-kalendarium.yellow-black .calendar-day .info {
  background-color: #1e1e1e;
}

.page-kalendarium.yellow-black .calendar-day .info .underscore {
  background-color: #fd0;
}

.page-kalendarium.yellow-black .calendar-day .info .pipe {
  background-color: #fd0;
}

.page-repertuar .page-title {
  text-align: center;
}

.page-repertuar .breadcrumb {
  text-align: center;
}

.view-repertoire.monthly-repertoire {
  /* view-header */
  /* date nav wrapper */
  /* view content */
}

@media (min-width: 1280px) {
  .view-repertoire.monthly-repertoire .layout-18col--with-border::before {
    top: 80px;
    height: calc(100% - 80px);
  }
}

@media (min-width: 1280px) {
  .view-repertoire.monthly-repertoire .title-wrapper {
    margin-top: 80px;
  }
}

.view-repertoire.monthly-repertoire table {
  border-top: 4px solid #000;
}

.yellow-black .view-repertoire.monthly-repertoire table {
  border-color: #fd0;
}

@media (min-width: 480px) {
  .view-artists .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 480px) {
  .view-artists .view-content .views-row {
    margin-left: 2%;
    margin-right: 2%;
    width: 46%;
  }
}

@media (min-width: 1024px) {
  .view-artists .view-content .views-row {
    width: 29.333%;
  }
}

@media (min-width: 1680px) {
  .view-artists .view-content .views-row {
    margin-left: 1%;
    margin-right: 1%;
    width: 23%;
  }
}

@media (min-width: 1800px) {
  .view-artists .view-content .views-row {
    margin-left: 2%;
    margin-right: 2%;
    width: 21%;
  }
}

.view-artists .view-content .content {
  margin: 0 auto 1em;
  position: relative;
}

@media (min-width: 768px) {
  .view-artists .view-content .content {
    margin-bottom: 3em;
  }
}

@media (min-width: 1024px) {
  .view-artists .view-content .content {
    margin-bottom: 4em;
  }
}

@media (min-width: 480px) {
  .view-artists .view-content .content:hover .image img {
    opacity: .1;
  }
}

@media (min-width: 480px) {
  .view-artists .view-content .content:hover .image .glyph {
    display: block;
  }
}

.view-artists .view-content .content:hover h2.title {
  color: #0000fe;
}

.yellow-black .view-artists .view-content .content:hover h2.title {
  color: #fd0;
}

.view-artists .view-content .image {
  float: left;
  margin-right: 2%;
  position: relative;
  width: 50%;
}

@media (min-width: 480px) {
  .view-artists .view-content .image {
    float: none;
    margin-right: 0;
    width: 100%;
  }
}

.yellow-black .view-artists .view-content .image .person-photo-fill {
  background-image: url("../images/person-fill-yellow.svg");
  background-size: cover;
}

.yellow-black .view-artists .view-content .image .person-photo-fill img {
  opacity: 0;
}

.view-artists .view-content .image img {
  display: block;
  transition: opacity .3s ease-in-out;
}

.view-artists .view-content .image .glyph {
  background-image: url("../images/glyph-eye.svg");
  background-size: cover;
  display: none;
  height: 83px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 165px;
}

.yellow-black .view-artists .view-content .image .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.view-artists .view-content h2.title {
  font-size: calc(1.125em + 0.75 * ( (100vw - 22.5em) / 97.5));
  float: right;
  margin: 0;
  overflow: visible;
  text-transform: uppercase;
  width: 48%;
}

@media screen and (max-width: 22.5em) {
  .view-artists .view-content h2.title {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 120em) {
  .view-artists .view-content h2.title {
    font-size: 1.875em;
  }
}

@media (min-width: 480px) {
  .view-artists .view-content h2.title {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    float: none;
    width: 100%;
  }
}

.view-artists .view-content h2.title.node-414 {
  border: 3px solid #000;
  padding: 0 5px;
}

.yellow-black .view-artists .view-content h2.title.node-414 {
  border-color: #fd0;
}

.view-artists .view-content a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  text-indent: -9999px;
}

.view-artists .view-content a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-artists .view-content a:focus-visible {
  outline-color: #fd0;
}

.view-artists .attachment-after {
  padding-top: 2em;
}

@media (min-width: 1024px) {
  .view-artists .attachment-after {
    padding-top: 4em;
  }
}

@media (min-width: 1280px) {
  .view-artists .attachment-after {
    padding-top: 8em;
  }
}

.view-artists .attachment-after h2 {
  font-size: calc(1.1875em + 1.625 * ( (100vw - 22.5em) / 97.5));
  color: gray;
  margin: 0 auto .5em;
  text-transform: lowercase;
  text-align: center;
}

@media screen and (max-width: 22.5em) {
  .view-artists .attachment-after h2 {
    font-size: 1.1875em;
  }
}

@media screen and (min-width: 120em) {
  .view-artists .attachment-after h2 {
    font-size: 2.8125em;
  }
}

@media (min-width: 768px) {
  .view-artists .attachment-after h2 {
    line-height: 1;
    margin-bottom: -.5em;
  }
}

.yellow-black .view-artists .attachment-after h2 {
  color: #fd0;
}

@media (min-width: 768px) {
  .view-artists .attachment-after h2 span {
    background-color: #fff;
    padding: 10px 45px;
  }
}

.yellow-black .view-artists .attachment-after h2 span {
  background-color: #1e1e1e;
}

.view-artists .attachment-after .item-list {
  background-color: #f2f2f2;
  list-style: none;
  margin: 0;
  padding: 1em;
}

@media (min-width: 768px) {
  .view-artists .attachment-after .item-list {
    padding: 4em 3em;
  }
}

.yellow-black .view-artists .attachment-after .item-list {
  background-color: #1e1e1e;
}

.view-artists .attachment-after .item-list li {
  color: gray;
  display: block;
  font-family: "Inter UI", sans-serif;
  font-size: 1.125em;
  font-weight: 700;
  margin: .25em 0;
  text-decoration: none;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .view-artists .attachment-after .item-list li {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .view-artists .attachment-after .item-list li::after {
    background-color: gray;
    content: '';
    display: inline-block;
    height: 16px;
    margin: -2px .25em 0;
    width: 2px;
  }
  .yellow-black .view-artists .attachment-after .item-list li::after {
    background-color: #fd0;
  }
}

.view-artists .attachment-after .item-list li.views-row-last::after {
  content: none;
}

.view-podcasts .view-content .views-row {
  border-bottom: 1px solid #0000fe;
  margin-top: 2em;
  padding-bottom: 1em;
}

.yellow-black .view-podcasts .view-content .views-row {
  border-color: #fd0;
}

.view-podcasts .view-content .group-content {
  position: relative;
}

.view-podcasts .view-content .group-content.active h3.title {
  color: #0000fe;
}

.yellow-black .view-podcasts .view-content .group-content.active h3.title {
  color: #fd0;
}

.view-podcasts .view-content .group-content.active .read-more-label span {
  border-bottom: 1px solid #0000fe;
}

.yellow-black .view-podcasts .view-content .group-content.active .read-more-label span {
  border-color: #fd0;
}

.view-podcasts .view-content h3.title {
  font-size: calc(1.5em + 0.375 * ( (100vw - 22.5em) / 97.5));
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .view-podcasts .view-content h3.title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .view-podcasts .view-content h3.title {
    font-size: 1.875em;
  }
}

.view-podcasts .view-content h3.title a {
  text-decoration: none;
}

.view-podcasts .view-content h3.title a:link {
  color: #000;
}

.view-podcasts .view-content h3.title a:visited {
  color: #000;
}

.view-podcasts .view-content h3.title a:hover {
  color: #0000fe;
}

.view-podcasts .view-content h3.title a:active {
  color: #0000fe;
}

.view-podcasts .view-content h3.title a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-podcasts .view-content h3.title a:link {
  color: #fd0;
}

.yellow-black .view-podcasts .view-content h3.title a:visited {
  color: #fd0;
}

.yellow-black .view-podcasts .view-content h3.title a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-podcasts .view-content h3.title a:active {
  color: #fd0;
}

.yellow-black .view-podcasts .view-content h3.title a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-podcasts .view-content .field-post-date {
  display: inline-block;
}

.view-podcasts .view-content .field-duration {
  display: inline-block;
}

.view-podcasts .view-content .field-duration::before {
  content: '|';
  display: inline-block;
  font-size: 0.875em;
  line-height: 1;
  margin: 0 .5em;
  vertical-align: middle;
}

.view-podcasts .view-content .field-duration .label-inline {
  display: inline-block;
}

.view-podcasts .view-content .read-more-label {
  margin-top: .5em;
}

.view-podcasts .view-content .read-more-label span {
  color: #0000fe;
  display: inline-block;
  font-family: "Inter UI", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  letter-spacing: .075em;
  text-transform: uppercase;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.yellow-black .view-podcasts .view-content .read-more-label span {
  color: #fd0;
}

.view-podcasts .view-content .read-more-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 0;
}

.view-podcasts .view-content .read-more-link:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-podcasts .view-content .read-more-link:focus-visible {
  outline-color: #fd0;
}

.page-views-search-results .main-content .exposed-form-search-results-page {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.page-views-search-results .main-content .exposed-form-search-results-page:before {
  content: "";
  display: table;
}

.page-views-search-results .main-content .exposed-form-search-results-page:after {
  content: "";
  display: table;
  clear: both;
}

.page-views-search-results .main-content .exposed-form-search-results-page .content {
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
  padding-left: 20px;
  padding-right: 20px;
}

[dir="rtl"] .page-views-search-results .main-content .exposed-form-search-results-page .content {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 1280px) {
  .page-views-search-results .main-content .exposed-form-search-results-page .content {
    float: left;
    width: 77.77778%;
    margin-left: 11.11111%;
    margin-right: -100%;
  }
  [dir="rtl"] .page-views-search-results .main-content .exposed-form-search-results-page .content {
    float: right;
    margin-right: 11.11111%;
    margin-left: -100%;
  }
}

.page-views-search-results .main-content .exposed-form-search-results-page .views-exposed-widget {
  padding: 1em 0;
}

.page-views-search-results .main-content .exposed-form-search-results-page .views-exposed-widget .form-item .form-text {
  width: 230px;
}

@media (min-width: 360px) {
  .page-views-search-results .main-content .exposed-form-search-results-page .views-exposed-widget .form-item .form-text {
    width: 270px;
  }
}

@media (min-width: 768px) {
  .page-views-search-results .main-content .exposed-form-search-results-page .views-exposed-widget .form-item .form-text {
    width: 320px;
  }
}

.view-tz-search-results {
  margin-top: 2em;
}

@media (min-width: 768px) {
  .view-tz-search-results {
    margin-top: 4em;
  }
}

.view-tz-search-results .view-header {
  font-size: 1.125em;
  font-weight: 400;
}

.view-tz-search-results .view-content .views-row + .views-row {
  margin-top: 2em;
}

.view-tz-search-results .view-content h2.title a {
  text-decoration: none;
}

.view-tz-search-results .view-content h2.title a:link {
  color: #0000fe;
}

.view-tz-search-results .view-content h2.title a:visited {
  color: #0000fe;
}

.view-tz-search-results .view-content h2.title a:hover {
  color: #000;
}

.view-tz-search-results .view-content h2.title a:active {
  color: #000;
}

.view-tz-search-results .view-content h2.title a:focus-visible {
  color: #000;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-tz-search-results .view-content h2.title a:link {
  color: #fd0;
}

.yellow-black .view-tz-search-results .view-content h2.title a:visited {
  color: #fd0;
}

.yellow-black .view-tz-search-results .view-content h2.title a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-tz-search-results .view-content h2.title a:active {
  color: #fd0;
}

.yellow-black .view-tz-search-results .view-content h2.title a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-tz-search-results .view-content h2.title strong {
  font-weight: 700;
  font-style: italic;
}

.view-tz-search-results .view-content .node-body a {
  display: inline-block;
  margin-left: 4px;
}

.view-tz-search-results .view-content .node-body a:link {
  color: #0000fe;
}

.view-tz-search-results .view-content .node-body a:visited {
  color: #0000fe;
}

.view-tz-search-results .view-content .node-body a:hover {
  color: #000;
}

.view-tz-search-results .view-content .node-body a:active {
  color: #000;
}

.view-tz-search-results .view-content .node-body a:focus-visible {
  color: #000;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-tz-search-results .view-content .node-body a:link {
  color: #fd0;
}

.yellow-black .view-tz-search-results .view-content .node-body a:visited {
  color: #fd0;
}

.yellow-black .view-tz-search-results .view-content .node-body a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-tz-search-results .view-content .node-body a:active {
  color: #fd0;
}

.yellow-black .view-tz-search-results .view-content .node-body a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-tz-search-results .pager {
  margin-top: 2em;
}

@media (min-width: 768px) {
  .view-tz-search-results .pager {
    margin-top: 4em;
  }
}

@media (min-width: 480px) {
  .view-lista-spektakli.lista-spektakli .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

.view-lista-spektakli.lista-spektakli .views-row {
  margin-top: 2em;
}

@media (min-width: 480px) {
  .view-lista-spektakli.lista-spektakli .views-row {
    width: 50%;
  }
  .view-lista-spektakli.lista-spektakli .views-row.views-row-odd .group-content {
    margin-right: 12px;
  }
  .view-lista-spektakli.lista-spektakli .views-row.views-row-even .group-content {
    margin-left: 12px;
  }
}

@media (min-width: 1024px) {
  .view-lista-spektakli.lista-spektakli .views-row {
    margin-top: 3em;
    width: 33.3333%;
  }
  .view-lista-spektakli.lista-spektakli .views-row.views-row-1 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-4 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-7 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-10 .group-content {
    margin-right: 16px;
    margin-left: 0;
  }
  .view-lista-spektakli.lista-spektakli .views-row.views-row-2 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-5 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-8 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-11 .group-content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .view-lista-spektakli.lista-spektakli .views-row.views-row-3 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-6 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-9 .group-content, .view-lista-spektakli.lista-spektakli .views-row.views-row-12 .group-content {
    margin-left: 16px;
    margin-right: 0;
  }
}

.view-lista-spektakli.lista-spektakli .node .group-content {
  position: relative;
}

.view-lista-spektakli.lista-spektakli .node .group-content.active .group-image img {
  opacity: .1;
}

.view-lista-spektakli.lista-spektakli .node .group-content.active .group-image .glyph {
  display: block;
}

.view-lista-spektakli.lista-spektakli .node .group-content.active .title {
  color: #0000fe;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .group-content.active .title {
  color: #fd0;
}

.view-lista-spektakli.lista-spektakli .node .group-content.active .read-more-label span {
  background-color: #0000fe;
  color: #fff;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .group-content.active .read-more-label span {
  background-color: #fd0;
  color: #1e1e1e;
}

.view-lista-spektakli.lista-spektakli .node .group-image {
  position: relative;
}

.view-lista-spektakli.lista-spektakli .node .group-image a {
  display: block;
}

.view-lista-spektakli.lista-spektakli .node .group-image img {
  display: block;
  transition: opacity .3s ease-in-out;
}

.view-lista-spektakli.lista-spektakli .node .group-image .glyph {
  background-image: url("../images/glyph-eye.svg");
  background-position: center center;
  background-size: cover;
  display: none;
  height: 111px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 221px;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .group-image .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.view-lista-spektakli.lista-spektakli .node .title {
  font-size: calc(1.5em + 0.375 * ( (100vw - 22.5em) / 97.5));
  margin-top: 0;
  padding-top: .5em;
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .view-lista-spektakli.lista-spektakli .node .title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .view-lista-spektakli.lista-spektakli .node .title {
    font-size: 1.875em;
  }
}

.view-lista-spektakli.lista-spektakli .node .title a {
  text-decoration: none;
}

.view-lista-spektakli.lista-spektakli .node .title a:link {
  color: #000;
}

.view-lista-spektakli.lista-spektakli .node .title a:visited {
  color: #000;
}

.view-lista-spektakli.lista-spektakli .node .title a:hover {
  color: #0000fe;
}

.view-lista-spektakli.lista-spektakli .node .title a:active {
  color: #0000fe;
}

.view-lista-spektakli.lista-spektakli .node .title a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .title a:link {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .title a:visited {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .title a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .title a:active {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .title a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-lista-spektakli.lista-spektakli .node .field-name-body {
  margin: 1.5rem 0;
}

.view-lista-spektakli.lista-spektakli .node .field-name-body p {
  margin: 0;
}

.view-lista-spektakli.lista-spektakli .node .read-more-label {
  text-align: center;
}

.view-lista-spektakli.lista-spektakli .node .read-more-label span {
  border: 1px solid #0000fe;
  color: #0000fe;
  display: inline-block;
  font-family: "Inter UI", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-transform: uppercase;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .read-more-label span {
  border-color: #fd0;
  color: #fd0;
}

.view-lista-spektakli.lista-spektakli .node .read-more-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 0;
}

.view-lista-spektakli.lista-spektakli .node .read-more-link:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-lista-spektakli.lista-spektakli .node .read-more-link:focus-visible {
  outline-color: #fd0;
}

.view-lista-spektakli.lista-spektakli .view-footer {
  margin-top: 2em;
}

.view-lista-spektakli.lista-spektakli .attachment-after {
  padding-top: 2em;
}

@media (min-width: 1024px) {
  .view-lista-spektakli.lista-spektakli .attachment-after {
    padding-top: 4em;
  }
}

@media (min-width: 1280px) {
  .view-lista-spektakli.lista-spektakli .attachment-after {
    padding-top: 8em;
  }
}

.view-lista-spektakli.lista-spektakli .attachment-after h2 {
  font-size: calc(1.1875em + 1.625 * ( (100vw - 22.5em) / 97.5));
  color: gray;
  margin: 0 auto .5em;
  text-transform: lowercase;
  text-align: center;
}

@media screen and (max-width: 22.5em) {
  .view-lista-spektakli.lista-spektakli .attachment-after h2 {
    font-size: 1.1875em;
  }
}

@media screen and (min-width: 120em) {
  .view-lista-spektakli.lista-spektakli .attachment-after h2 {
    font-size: 2.8125em;
  }
}

@media (min-width: 768px) {
  .view-lista-spektakli.lista-spektakli .attachment-after h2 {
    line-height: 1;
    margin-bottom: -.5em;
  }
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after h2 {
  color: #fd0;
}

@media (min-width: 768px) {
  .view-lista-spektakli.lista-spektakli .attachment-after h2 span {
    background-color: #fff;
    padding: 10px 45px;
  }
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after h2 span {
  background-color: #1e1e1e;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list {
  background-color: #f2f2f2;
  list-style: none;
  margin: 0;
  padding: 1em;
}

@media (min-width: 768px) {
  .view-lista-spektakli.lista-spektakli .attachment-after .item-list {
    padding: 4em 3em;
  }
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after .item-list {
  background-color: #1e1e1e;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li {
  display: block;
  margin: .25em 0;
}

@media (min-width: 768px) {
  .view-lista-spektakli.lista-spektakli .attachment-after .item-list li {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .view-lista-spektakli.lista-spektakli .attachment-after .item-list li::after {
    background-color: gray;
    content: '';
    display: inline-block;
    height: 16px;
    margin: -2px .25em 0;
    width: 2px;
  }
  .yellow-black .view-lista-spektakli.lista-spektakli .attachment-after .item-list li::after {
    background-color: #fd0;
  }
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li.views-row-last::after {
  content: none;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li a {
  font-family: "Inter UI", sans-serif;
  font-size: 1.125em;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:link {
  color: gray;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:visited {
  color: gray;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:hover {
  color: #333333;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:active {
  color: #333333;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:focus-visible {
  color: #333333;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:link {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:visited {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:active {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-lista-spektakli.lista-spektakli .attachment-after .item-list li a:hover {
  text-decoration: underline;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .view-empty {
  text-align: center;
}

@media (min-width: 480px) {
  .view-lista-spektakli.lista-spektakli-archiwalnych .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

.view-lista-spektakli.lista-spektakli-archiwalnych .views-row {
  margin-top: 2em;
}

@media (min-width: 480px) {
  .view-lista-spektakli.lista-spektakli-archiwalnych .views-row {
    width: 50%;
  }
  .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-odd .content {
    margin-right: 12px;
  }
  .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-even .content {
    margin-left: 12px;
  }
}

@media (min-width: 1024px) {
  .view-lista-spektakli.lista-spektakli-archiwalnych .views-row {
    margin-top: 3em;
    width: 33.3333%;
  }
  .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-1 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-4 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-7 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-10 .content {
    margin-right: 16px;
    margin-left: 0;
  }
  .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-2 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-5 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-8 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-11 .content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-3 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-6 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-9 .content, .view-lista-spektakli.lista-spektakli-archiwalnych .views-row.views-row-12 .content {
    margin-left: 16px;
    margin-right: 0;
  }
}

.view-lista-spektakli.lista-spektakli-archiwalnych .content {
  position: relative;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .content.active .poster img {
  opacity: .1;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .content.active .poster .glyph {
  display: block;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .content.active .title {
  color: #0000fe;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .content.active .title {
  color: #fd0;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .content.active .read-more-label span {
  background-color: #0000fe;
  color: #fff;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .content.active .read-more-label span {
  background-color: #fd0;
  color: #1e1e1e;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .poster {
  position: relative;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .poster img {
  display: block;
  margin: 0 auto;
  transition: opacity .3s ease-in-out;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .poster .glyph {
  background-image: url("../images/glyph-eye.svg");
  background-position: center center;
  background-size: cover;
  display: none;
  height: 111px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 221px;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .poster .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.view-lista-spektakli.lista-spektakli-archiwalnych .title {
  font-size: calc(1.5em + 0.375 * ( (100vw - 22.5em) / 97.5));
  margin-top: 0;
  padding-top: .5em;
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .view-lista-spektakli.lista-spektakli-archiwalnych .title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 120em) {
  .view-lista-spektakli.lista-spektakli-archiwalnych .title {
    font-size: 1.875em;
  }
}

.view-lista-spektakli.lista-spektakli-archiwalnych .title a {
  text-decoration: none;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .title a:link {
  color: #000;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .title a:visited {
  color: #000;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .title a:hover {
  color: #0000fe;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .title a:active {
  color: #0000fe;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .title a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .title a:link {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .title a:visited {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .title a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .title a:active {
  color: #fd0;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .title a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .field-name-body {
  margin: 1.5rem 0;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .field-name-body p {
  margin: 0;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .read-more-label {
  text-align: center;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .read-more-label span {
  border: 1px solid #0000fe;
  color: #0000fe;
  display: inline-block;
  font-family: "Inter UI", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-transform: uppercase;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .read-more-label span {
  border-color: #fd0;
  color: #fd0;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .read-more-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 0;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .read-more-link:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-lista-spektakli.lista-spektakli-archiwalnych .read-more-link:focus-visible {
  outline-color: #fd0;
}

.view-lista-spektakli.lista-spektakli-archiwalnych .view-footer {
  margin-top: 2em;
}

@media (min-width: 480px) {
  .view-theatre-team.theatre-team .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 480px) {
  .view-theatre-team.theatre-team .view-content .views-row {
    margin-left: 2%;
    margin-right: 2%;
    width: 46%;
  }
}

@media (min-width: 1024px) {
  .view-theatre-team.theatre-team .view-content .views-row {
    width: 29.333%;
  }
}

@media (min-width: 1680px) {
  .view-theatre-team.theatre-team .view-content .views-row {
    margin-left: 1%;
    margin-right: 1%;
    width: 23%;
  }
}

@media (min-width: 1800px) {
  .view-theatre-team.theatre-team .view-content .views-row {
    margin-left: 2%;
    margin-right: 2%;
    width: 21%;
  }
}

.view-theatre-team.theatre-team .view-content .content {
  margin: 0 auto;
  max-width: 280px;
  position: relative;
}

@media (min-width: 768px) {
  .view-theatre-team.theatre-team .view-content .content {
    margin-bottom: 3em;
  }
}

@media (min-width: 1024px) {
  .view-theatre-team.theatre-team .view-content .content {
    margin-bottom: 4em;
  }
}

.view-theatre-team.theatre-team .view-content .content:hover .image img {
  opacity: .1;
}

.view-theatre-team.theatre-team .view-content .content:hover .image .glyph {
  display: block;
}

.view-theatre-team.theatre-team .view-content .content:hover h2.title {
  color: #0000fe;
}

.yellow-black .view-theatre-team.theatre-team .view-content .content:hover h2.title {
  color: #fd0;
}

.view-theatre-team.theatre-team .view-content .image {
  position: relative;
}

.view-theatre-team.theatre-team .view-content .image img {
  display: block;
  transition: opacity .3s ease-in-out;
}

.view-theatre-team.theatre-team .view-content .image .glyph {
  background-image: url("../images/glyph-eye.svg");
  background-size: cover;
  display: none;
  height: 83px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 165px;
}

.yellow-black .view-theatre-team.theatre-team .view-content .image .glyph {
  background-image: url("../images/glyph-eye-yellow.svg");
}

.view-theatre-team.theatre-team .view-content h2.title {
  font-size: calc(1.3125em + 0.5625 * ( (100vw - 22.5em) / 97.5));
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .view-theatre-team.theatre-team .view-content h2.title {
    font-size: 1.3125em;
  }
}

@media screen and (min-width: 120em) {
  .view-theatre-team.theatre-team .view-content h2.title {
    font-size: 1.875em;
  }
}

.view-theatre-team.theatre-team .view-content h2.title.node-414, .view-theatre-team.theatre-team .view-content h2.title.node-424, .view-theatre-team.theatre-team .view-content h2.title.node-5167 {
  border: 3px solid #000;
  padding: 0 5px;
}

.yellow-black .view-theatre-team.theatre-team .view-content h2.title.node-414, .yellow-black .view-theatre-team.theatre-team .view-content h2.title.node-424, .yellow-black .view-theatre-team.theatre-team .view-content h2.title.node-5167 {
  border-color: #fd0;
}

.view-theatre-team.theatre-team .view-content a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  text-indent: -9999px;
}

.view-theatre-team.theatre-team .view-content a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-theatre-team.theatre-team .view-content a:focus-visible {
  outline-color: #fd0;
}

@media print {
  ul.sf-menu {
    display: none;
    visibility: hidden;
  }
}

[dir="rtl"] ul.sf-menu {
  padding: 0 !important;
}

[dir="rtl"] ul.sf-menu ul {
  padding: 0 !important;
}

.sf-menu,
.sf-menu * {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sf-menu.menu,
.sf-menu.menu ul,
.sf-menu.menu li {
  margin: 0;
}

.sf-hidden {
  left: -99999em !important;
  top: -99999em !important;
}

[dir="rtl"] .sf-hidden {
  left: auto !important;
  right: -99999em !important;
}

.sf-menu.sf-accordion {
  background-color: #000;
  display: none;
  float: left;
  padding-bottom: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.sf-menu.sf-accordion ul,
.sf-menu.sf-accordion li {
  float: left;
  width: 100%;
}

.sf-menu.sf-accordion ul {
  margin: 0;
  padding: 0;
}

.sf-menu.sf-accordion.sf-expanded,
.sf-menu.sf-accordion li.sf-expanded > ul {
  left: auto !important;
  position: relative;
  top: auto !important;
}

.sf-menu.sf-accordion .sf-hidden {
  display: none !important;
}

.sf-menu.sf-accordion a,
.sf-menu.sf-accordion span.nolink {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.75;
  padding: 5px 20px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.sf-menu.sf-accordion a.sf-depth-1,
.sf-menu.sf-accordion span.nolink.sf-depth-1 {
  background-color: #000;
}

.sf-menu.sf-accordion a.sf-depth-2,
.sf-menu.sf-accordion span.nolink.sf-depth-2 {
  background-color: #333333;
}

.sf-menu.sf-accordion a.sf-depth-3,
.sf-menu.sf-accordion span.nolink.sf-depth-3 {
  background-color: #666666;
}

.sf-menu.sf-accordion a.menuparent,
.sf-menu.sf-accordion span.nolink.menuparent {
  position: relative;
}

.sf-menu.sf-accordion a.menuparent::after,
.sf-menu.sf-accordion span.nolink.menuparent::after {
  background-image: url("../images/arrow-nav-mobile.svg");
  background-position: left center;
  background-repeat: no-repeat;
  content: '';
  height: 15px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}

.yellow-black .sf-menu.sf-accordion a.menuparent::after, .yellow-black
.sf-menu.sf-accordion span.nolink.menuparent::after {
  background-position: right center;
}

.yellow-black .sf-menu.sf-accordion a, .yellow-black
.sf-menu.sf-accordion span.nolink {
  color: #fd0;
}

.sf-menu.sf-accordion a.search {
  background-image: url("../images/loupe-white.svg");
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

.yellow-black .sf-menu.sf-accordion a.search {
  background-image: url("../images/loupe-yellow.svg");
}

.sf-menu.sf-accordion li.sf-expanded > a.menuparent::after,
.sf-menu.sf-accordion li.sf-expanded > span.nolink.menuparent::after {
  transform: translateY(-50%) rotate(90deg);
}

.sf-menu.sf-horizontal {
  float: right;
  line-height: 1;
  z-index: 497;
}

.sf-menu.sf-horizontal ul {
  background-color: #000;
  left: 0;
  position: absolute;
  top: -99999em;
  width: 8em;
}

.yellow-black .sf-menu.sf-horizontal ul {
  background-color: #fd0;
}

.sf-menu.sf-horizontal ul li {
  width: 100%;
}

[dir="rtl"] .sf-menu.sf-horizontal ul {
  left: auto;
  right: 0;
}

.sf-menu.sf-horizontal li {
  float: left;
  position: relative;
  z-index: 498;
}

[dir="rtl"] .sf-menu.sf-horizontal li {
  float: right;
}

.sf-menu.sf-horizontal li.active-trail a.sf-depth-1,
.sf-menu.sf-horizontal li.active-trail span.nolink.sf-depth-1 {
  position: relative;
}

.sf-menu.sf-horizontal li.active-trail a.sf-depth-1::after,
.sf-menu.sf-horizontal li.active-trail span.nolink.sf-depth-1::after {
  background-color: #0000fe;
  content: '';
  height: 2px;
  left: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  width: calc(100% - 32px);
}

.yellow-black .sf-menu.sf-horizontal li.active-trail a.sf-depth-1::after, .yellow-black
.sf-menu.sf-horizontal li.active-trail span.nolink.sf-depth-1::after {
  background-color: #fd0;
}

.sf-menu.sf-horizontal li.active-trail li.active-trail a.sf-depth-2,
.sf-menu.sf-horizontal li.active-trail li.active-trail span.nolink.sf-depth-2,
.sf-menu.sf-horizontal li.active-trail li.active-trail li.active-trail a.sf-depth-3,
.sf-menu.sf-horizontal li.active-trail li.active-trail li.active-trail span.nolink.sf-depth-3 {
  background-color: #f2f2f2;
  color: #000;
  text-decoration: line-through;
}

.yellow-black .sf-menu.sf-horizontal li.active-trail li.active-trail a.sf-depth-2, .yellow-black
.sf-menu.sf-horizontal li.active-trail li.active-trail span.nolink.sf-depth-2, .yellow-black
.sf-menu.sf-horizontal li.active-trail li.active-trail li.active-trail a.sf-depth-3, .yellow-black
.sf-menu.sf-horizontal li.active-trail li.active-trail li.active-trail span.nolink.sf-depth-3 {
  background-color: #1e1e1e;
  color: #fd0;
}

.sf-menu.sf-horizontal li.sf-depth-1 a.newsletter {
  background-color: #000;
  color: #fff;
  line-height: 26px;
  margin: 13px 0 0 16px;
  padding: 4px 10px 0;
  text-transform: lowercase;
}

.yellow-black .sf-menu.sf-horizontal li.sf-depth-1 a.newsletter {
  background-color: #fd0;
  color: #1e1e1e;
}

.sf-menu.sf-horizontal li.sf-depth-1 a.newsletter:hover {
  background-color: #0000fe;
}

.sf-menu.sf-horizontal a,
.sf-menu.sf-horizontal span.nolink {
  display: block;
  font-weight: 700;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.sf-menu.sf-horizontal a.sf-depth-1,
.sf-menu.sf-horizontal span.nolink.sf-depth-1 {
  color: #000;
  font-size: 1.25em;
  line-height: 60px;
  padding: 0 8px;
}

@media (min-width: 1280px) {
  .sf-menu.sf-horizontal a.sf-depth-1,
  .sf-menu.sf-horizontal span.nolink.sf-depth-1 {
    padding: 0 16px 0 8px;
  }
}

@media (min-width: 1440px) {
  .sf-menu.sf-horizontal a.sf-depth-1,
  .sf-menu.sf-horizontal span.nolink.sf-depth-1 {
    padding: 0 16px;
  }
}

.sf-menu.sf-horizontal a.sf-depth-1:hover,
.sf-menu.sf-horizontal span.nolink.sf-depth-1:hover {
  color: #666666;
}

.yellow-black .sf-menu.sf-horizontal a.sf-depth-1, .yellow-black
.sf-menu.sf-horizontal span.nolink.sf-depth-1 {
  color: #fd0;
}

.sf-menu.sf-horizontal a.sf-depth-2, .sf-menu.sf-horizontal a.sf-depth-3,
.sf-menu.sf-horizontal span.nolink.sf-depth-2,
.sf-menu.sf-horizontal span.nolink.sf-depth-3 {
  color: #fff;
  font-size: 1em;
  line-height: 1.25;
  padding: 12px 16px 8px;
}

.yellow-black .sf-menu.sf-horizontal a.sf-depth-2, .yellow-black .sf-menu.sf-horizontal a.sf-depth-3, .yellow-black
.sf-menu.sf-horizontal span.nolink.sf-depth-2, .yellow-black
.sf-menu.sf-horizontal span.nolink.sf-depth-3 {
  color: #1e1e1e;
}

.sf-menu.sf-horizontal a.sf-depth-2:hover, .sf-menu.sf-horizontal a.sf-depth-3:hover,
.sf-menu.sf-horizontal span.nolink.sf-depth-2:hover,
.sf-menu.sf-horizontal span.nolink.sf-depth-3:hover {
  background-color: #f2f2f2;
  color: #000;
}

.yellow-black .sf-menu.sf-horizontal a.sf-depth-2:hover, .yellow-black .sf-menu.sf-horizontal a.sf-depth-3:hover, .yellow-black
.sf-menu.sf-horizontal span.nolink.sf-depth-2:hover, .yellow-black
.sf-menu.sf-horizontal span.nolink.sf-depth-3:hover {
  background-color: #1e1e1e;
  color: #fd0;
}

.sf-menu.sf-horizontal a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .sf-menu.sf-horizontal a:focus-visible {
  outline-color: #fd0;
}

.sf-menu.sf-horizontal span.nolink {
  cursor: default;
}

.sf-menu.sf-horizontal span.nolink:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .sf-menu.sf-horizontal span.nolink:focus-visible {
  outline-color: #fd0;
}

.sf-menu.sf-horizontal a.search {
  display: none;
}

.sf-menu.sf-horizontal a.d-none {
  display: none;
}

@media (min-width: 1680px) {
  .sf-menu.sf-horizontal a.d-xxxl-block {
    display: block;
  }
}

@media (min-width: 1680px) {
  .sf-menu.sf-horizontal a.d-xxxl-none {
    display: none;
  }
}

.sf-menu.sf-horizontal li:hover,
.sf-menu.sf-horizontal li.sfHover,
.sf-menu.sf-horizontal li:hover ul,
.sf-menu.sf-horizontal li.sfHover ul {
  z-index: 499;
}

.sf-menu.sf-horizontal li:hover > ul,
.sf-menu.sf-horizontal li.sfHover > ul {
  left: 0;
  top: 60px;
}

[dir="rtl"] .sf-menu.sf-horizontal li:hover > ul, [dir="rtl"]
.sf-menu.sf-horizontal li.sfHover > ul {
  left: auto;
  right: 0;
}

.sf-menu.sf-horizontal li li:hover > ul,
.sf-menu.sf-horizontal li li.sfHover > ul {
  left: 8em;
  top: 0;
}

[dir="rtl"] .sf-menu.sf-horizontal li li:hover > ul, [dir="rtl"]
.sf-menu.sf-horizontal li li.sfHover > ul {
  left: auto;
  right: 8em;
}

.sf-menu.sf-horizontal a.sf-with-ul,
.sf-menu.sf-horizontal span.nolink.sf-with-ul {
  min-width: 1px;
}

.sf-menu.sf-horizontal .sf-sub-indicator {
  display: none;
}

@media (min-width: 1280px) {
  .sf-menu.sf-horizontal .sf-sub-indicator {
    background-image: url("../images/arrow-nav-depth-1.svg");
    display: block;
    height: 15px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    text-indent: -9999px;
    top: calc(50% - 3px);
    transition: transform .1s ease-in-out;
    transform: translateY(-50%);
    width: 8px;
  }
  .yellow-black .sf-menu.sf-horizontal .sf-sub-indicator {
    background-image: url("../images/arrow-nav-depth-1-yellow.svg");
  }
}

[dir="rtl"] .sf-menu.sf-horizontal .sf-sub-indicator {
  left: 0;
  right: auto;
}

.sf-menu.sf-horizontal a:focus > .sf-sub-indicator,
.sf-menu.sf-horizontal a:hover > .sf-sub-indicator,
.sf-menu.sf-horizontal a:active > .sf-sub-indicator,
.sf-menu.sf-horizontal li:hover > a > .sf-sub-indicator,
.sf-menu.sf-horizontal li.sfHover > a > .sf-sub-indicator,
.sf-menu.sf-horizontal span.nolink:hover > .sf-sub-indicator,
.sf-menu.sf-horizontal li:hover > span.nolink > .sf-sub-indicator,
.sf-menu.sf-horizontal li.sfHover > span.nolink > .sf-sub-indicator {
  transform: translateY(-50%) rotate(90deg);
}

[dir="rtl"] .sf-menu.sf-horizontal a:focus > .sf-sub-indicator, [dir="rtl"]
.sf-menu.sf-horizontal a:hover > .sf-sub-indicator, [dir="rtl"]
.sf-menu.sf-horizontal a:active > .sf-sub-indicator, [dir="rtl"]
.sf-menu.sf-horizontal li:hover > a > .sf-sub-indicator, [dir="rtl"]
.sf-menu.sf-horizontal li.sfHover > a > .sf-sub-indicator, [dir="rtl"]
.sf-menu.sf-horizontal span.nolink:hover > .sf-sub-indicator, [dir="rtl"]
.sf-menu.sf-horizontal li:hover > span.nolink > .sf-sub-indicator, [dir="rtl"]
.sf-menu.sf-horizontal li.sfHover > span.nolink > .sf-sub-indicator {
  transform: translateY(-50%) rotate(270deg);
}

.sf-menu.sf-horizontal ul .sf-sub-indicator {
  background-image: url("../images/arrow-nav-depth-2.svg");
  display: block;
  height: 8px;
  overflow: hidden;
  position: absolute;
  right: 2px;
  text-indent: -9999px;
  top: calc(50% - 3px);
  transition: none;
  transform: translateY(-50%);
  width: 15px;
}

.yellow-black .sf-menu.sf-horizontal ul .sf-sub-indicator {
  background-image: url("../images/arrow-nav-depth-2-yellow.svg");
}

.sf-menu.sf-horizontal ul a > .sf-sub-indicator,
.sf-menu.sf-horizontal ul span.nolink > .sf-sub-indicator {
  background-position: top center;
  transform: none;
}

.sf-menu.sf-horizontal ul a:focus > .sf-sub-indicator,
.sf-menu.sf-horizontal ul a:hover > .sf-sub-indicator,
.sf-menu.sf-horizontal ul a:active > .sf-sub-indicator,
.sf-menu.sf-horizontal ul li:hover > a > .sf-sub-indicator,
.sf-menu.sf-horizontal ul li.sfHover > a > .sf-sub-indicator,
.sf-menu.sf-horizontal ul li.active-trail > a > .sf-sub-indicator,
.sf-menu.sf-horizontal ul li.active-trail > span.nolink > .sf-sub-indicator,
.sf-menu.sf-horizontal ul span.nolink:hover > .sf-sub-indicator {
  background-position: bottom center;
  transform: none;
}

.sf-menu.sf-horizontal ul li:hover > span.nolink > .sf-sub-indicator,
.sf-menu.sf-horizontal ul li.sfHover > span.nolink > .sf-sub-indicator {
  transform: none;
}

.region-bottom {
  background-color: #000;
  color: #fff;
  padding-bottom: 50px;
  padding-top: 1em;
  min-height: 110px;
}

@media (min-width: 1280px) {
  .region-bottom {
    min-height: 90px;
    padding-bottom: 0;
  }
}

@media print {
  .region-bottom {
    display: none;
  }
}

.yellow-black .region-bottom {
  color: #fd0;
}

.region-bottom__content p {
  font-size: 0.875em;
  margin-bottom: 0;
  margin-top: 0;
}

.region-bottom__content a {
  font-weight: 400;
  text-decoration: none;
}

.region-bottom__content a:link {
  color: #fff;
}

.region-bottom__content a:visited {
  color: #fff;
}

.region-bottom__content a:hover {
  color: #cccccc;
}

.region-bottom__content a:active {
  color: #cccccc;
}

.region-bottom__content a:focus-visible {
  color: #cccccc;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .region-bottom__content a:link {
  color: #fd0;
}

.yellow-black .region-bottom__content a:visited {
  color: #fd0;
}

.yellow-black .region-bottom__content a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .region-bottom__content a:active {
  color: #fd0;
}

.yellow-black .region-bottom__content a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.region-bottom__content a:hover {
  text-decoration: underline;
}

.region-bottom__content .menu {
  list-style: none;
  margin: 0 0 1em;
  padding: 0;
}

@media (min-width: 1024px) {
  .region-bottom__content .menu {
    float: right;
    margin-bottom: 0;
    text-align: right;
  }
}

.region-bottom__content .menu li {
  display: inline-block;
  font-size: 0.875em;
  margin: 8px 8px 8px 0;
}

@media (min-width: 1024px) {
  .region-bottom__content .menu li {
    margin: 0 5px;
  }
}

.region-bottom__content .menu a.active {
  text-decoration: line-through;
}

.region-bottom__content .menu ul {
  display: none;
}

@media (min-width: 1024px) {
  .region-bottom__content .block-menu {
    float: right;
    max-width: calc(100% - 300px);
  }
}

.region-bottom__content .copyright {
  margin-bottom: 1em;
}

@media (min-width: 1024px) {
  .region-bottom__content .copyright {
    float: left;
    margin-bottom: 0;
  }
}

@media print {
  .region-footer {
    display: none;
    visibility: hidden;
  }
}

.region-footer__content {
  padding-bottom: 2em;
}

.region-footer .block-block:not(:last-child),
.region-footer .block-views:not(:last-child) {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .region-footer .block-block,
  .region-footer .block-views {
    float: left;
    margin-bottom: 0;
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .region-footer .block-block,
  .region-footer .block-views {
    width: 33.3333%;
  }
}

.region-footer .block-block .block__title,
.region-footer .block-views .block__title {
  border-left: 6px solid #000;
  font-size: 1.5em;
  padding-left: 14px;
  text-transform: uppercase;
}

[dir="rtl"] .region-footer .block-block .block__title, [dir="rtl"]
.region-footer .block-views .block__title {
  border-left: 0;
  border-right: 6px solid #000;
  padding-left: 0;
  padding-right: 14px;
}

.yellow-black .region-footer .block-block .block__title, .yellow-black
.region-footer .block-views .block__title {
  border-color: #fd0;
}

[dir="rtl"] .yellow-black .region-footer .block-block .block__title, [dir="rtl"] .yellow-black
.region-footer .block-views .block__title {
  border-color: #fd0;
}

.region-footer .block-block .content {
  margin: 0 20px;
}

.region-footer .block-block .content p {
  margin: 1em 0;
}

.region-footer .block-block .content a[href^='mailto:'] {
  border: 2px solid #0000fe;
  display: inline-block;
  font-weight: 400;
  margin-left: -14px;
  padding: 4px 14px;
  text-decoration: none;
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out, color .3s ease-in-out;
}

.region-footer .block-block .content a[href^='mailto:']:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .region-footer .block-block .content a[href^='mailto:']:focus-visible {
  outline-color: #fd0;
}

.yellow-black .region-footer .block-block .content a[href^='mailto:'] {
  border-color: #fd0;
  color: #fd0;
}

.region-footer .block-block .content a[href^='mailto:']:hover {
  background-color: #0000fe;
  border-color: #0000fe;
  color: #fff;
}

.yellow-black .region-footer .block-block .content a[href^='mailto:']:hover {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.region-footer .block-block .content a.get-ticket {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.region-footer .block-block .content a.get-ticket:link, .region-footer .block-block .content a.get-ticket:visited {
  background-color: #0000fe;
  border: 1px solid #0000fe;
  color: #fff;
}

.region-footer .block-block .content a.get-ticket:hover, .region-footer .block-block .content a.get-ticket:active, .region-footer .block-block .content a.get-ticket:focus-visible {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.region-footer .block-block .content a.get-ticket:focus-visible {
  outline: 2px solid #0000fe;
}

.yellow-black .region-footer .block-block .content a.get-ticket:link, .yellow-black .region-footer .block-block .content a.get-ticket:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .region-footer .block-block .content a.get-ticket:hover, .yellow-black .region-footer .block-block .content a.get-ticket:active, .yellow-black .region-footer .block-block .content a.get-ticket:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .region-footer .block-block .content a.get-ticket:focus-visible {
  outline: 2px solid #fd0;
}

.region-footer .social-links {
  border-bottom: 20px solid #f2f2f2;
  margin: 0 0 1.5em;
}

.yellow-black .region-footer .social-links {
  border-bottom-color: #665800;
}

.region-footer .social-links .content {
  padding-bottom: 1em;
  padding-top: 1em;
}

@media (min-width: 480px) {
  .region-footer .social-links .content {
    padding-bottom: 3em;
    padding-top: 3em;
  }
}

.region-footer .social-links .item-list {
  text-align: center;
}

.region-footer .social-links .item-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.region-footer .social-links .item-list li {
  display: inline-block;
  margin: 1em 25px;
  height: 60px;
  width: 60px;
}

.region-footer .social-links .item-list a {
  font-size: 1.875rem;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  display: block;
  line-height: 60px;
  height: 60px;
  overflow: hidden;
  width: 60px;
  text-align: center;
}

.region-footer .social-links .item-list a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .region-footer .social-links .item-list a:focus-visible {
  outline-color: #fd0;
}

.yellow-black .region-footer .social-links .item-list a {
  background-color: #1e1e1e;
  border: 2px solid #fd0;
  color: #fd0;
}

.region-footer .social-links .item-list a.portal-250-na-65 {
  background-image: url("../images/250-na-65.png");
  background-repeat: no-repeat;
  background-size: 60px 60px;
}

.yellow-black .region-footer .social-links .item-list a.portal-250-na-65 {
  background-image: url("../images/250-na-65-yellow.png");
}

.region-footer .social-links .item-list a.portal-250-na-65:hover {
  background-color: #0000fe;
}

.yellow-black .region-footer .social-links .item-list a.portal-250-na-65:hover {
  background-color: #1e1e1e;
}

.region-footer .social-links .item-list a:hover {
  background-color: #0000fe;
}

.yellow-black .region-footer .social-links .item-list a:hover {
  background-color: #1e1e1e;
}

.region-footer .social-links .item-list a span {
  font-size: 0;
}

.region-footer .social-links .item-list a .fa {
  font-size: 1.875rem;
  vertical-align: middle;
}

.region-footer .view-partnerzy .view-content h2,
.region-footer .view-partnerzy .view-content h3 {
  border-left: 6px solid #000;
  font-weight: 400;
  padding-left: 14px;
  text-transform: uppercase;
}

.yellow-black .region-footer .view-partnerzy .view-content h2, .yellow-black
.region-footer .view-partnerzy .view-content h3 {
  border-left-color: #fd0;
}

[dir="rtl"] .region-footer .view-partnerzy .view-content h2, [dir="rtl"]
.region-footer .view-partnerzy .view-content h3 {
  border-left: 0;
  border-right: 6px solid #000;
  padding-left: 0;
  padding-right: 14px;
}

.region-footer .view-partnerzy .view-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.region-footer .view-partnerzy .view-content li {
  float: left;
  margin: 0 10px 10px 0;
}

[dir="rtl"] .region-footer .view-partnerzy .view-content li {
  float: right;
}

.region-footer .view-partnerzy .view-content a {
  background-color: #fff;
  display: inline-block;
}

.region-footer .view-partnerzy .view-content a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .region-footer .view-partnerzy .view-content a:focus-visible {
  outline-color: #fd0;
}

.region-footer .view-partnerzy .view-content img {
  display: block;
}

.region-footer .view-partnerzy .view-footer .views-row {
  margin: 1.5em 0 0 10px;
}

.region-footer .view-partnerzy .view-footer .views-row + .views-row {
  margin-top: .5em;
}

.region-footer .view-partnerzy .view-footer .views-row p {
  margin: 0;
}

.region-footer .view-partnerzy .view-footer .views-row a {
  background-color: #fff;
  display: inline-block;
}

.region-footer .view-partnerzy .view-footer .views-row a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 0;
}

.yellow-black .region-footer .view-partnerzy .view-footer .views-row a:focus-visible {
  outline-color: #fd0;
}

.region-footer .view-partnerzy .view-footer .views-row img {
  display: block;
  margin: 10px 0;
  max-width: 140px;
}

.region-header-section-second .social-links {
  display: none;
  left: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

[dir="rtl"] .region-header-section-second .social-links {
  left: auto;
  right: 0;
  transform: translate(50%, -50%);
}

@media (min-width: 1366px) {
  .region-header-section-second .social-links {
    display: block;
    left: calc((50% - (1200px / 2)) / 2);
  }
  [dir="rtl"] .region-header-section-second .social-links {
    left: auto;
    right: calc((50% - (1200px / 2)) / 2);
  }
}

@media (min-width: 1440px) {
  .region-header-section-second .social-links {
    left: calc((50% - (1280px / 2)) / 2);
  }
  [dir="rtl"] .region-header-section-second .social-links {
    left: auto;
    right: calc((50% - (1280px / 2)) / 2);
  }
}

@media (min-width: 1680px) {
  .region-header-section-second .social-links {
    left: calc((50% - (1440px / 2)) / 2);
  }
  [dir="rtl"] .region-header-section-second .social-links {
    left: auto;
    right: calc((50% - (1440px / 2)) / 2);
  }
}

@media (min-width: 1800px) {
  .region-header-section-second .social-links {
    left: calc((50% - (1620px / 2)) / 2);
  }
  [dir="rtl"] .region-header-section-second .social-links {
    left: auto;
    right: calc((50% - (1620px / 2)) / 2);
  }
}

.region-header-section-second .social-links .item-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.region-header-section-second .social-links .item-list ul::before {
  background-color: #000;
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;
}

.yellow-black .region-header-section-second .social-links .item-list ul::before {
  background-color: #fd0;
}

.region-header-section-second .social-links .item-list ul li {
  margin-bottom: 30px;
  /* override modules/system/system.theme-rtl.css */
}

.region-header-section-second .social-links .item-list ul li.last {
  margin-bottom: 0;
}

[dir="rtl"] .region-header-section-second .social-links .item-list ul li {
  margin-right: 0;
}

.region-header-section-second .social-links .item-list a {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 40px;
  overflow: hidden;
  width: 40px;
  text-align: center;
}

.region-header-section-second .social-links .item-list a:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .region-header-section-second .social-links .item-list a:focus-visible {
  outline-color: #fd0;
}

.yellow-black .region-header-section-second .social-links .item-list a {
  background-color: #1e1e1e;
  border: 2px solid #fd0;
  color: #fd0;
}

.region-header-section-second .social-links .item-list a.portal-250-na-65 {
  background-image: url("../images/250-na-65.png");
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.yellow-black .region-header-section-second .social-links .item-list a.portal-250-na-65 {
  background-image: url("../images/250-na-65-yellow.png");
}

.region-header-section-second .social-links .item-list a.portal-250-na-65:hover {
  background-color: #0000fe;
}

.region-header-section-second .social-links .item-list a:hover {
  background-color: #0000fe;
}

.yellow-black .region-header-section-second .social-links .item-list a:hover {
  background-color: #1e1e1e;
  color: #fd0;
}

.region-header-section-second .social-links .item-list a span {
  font-size: 0;
}

.region-header-section-second .social-links .item-list a .fa {
  font-size: 20px;
  line-height: 40px;
}

.region-header-section-third {
  display: none;
}

@media (min-width: 1024px) {
  .region-header-section-third {
    display: block;
    position: absolute;
    right: 160px;
    top: 5px;
    z-index: 10;
  }
  [dir="rtl"] .region-header-section-third {
    left: 160px;
    right: auto;
  }
}

@media (min-width: 1366px) {
  .region-header-section-third {
    position: fixed;
    right: calc((50% - (1200px / 2)) / 2);
    top: 50%;
    transform: translate(50%, -50%);
  }
  [dir="rtl"] .region-header-section-third {
    left: calc((50% - (1200px / 2)) / 2);
    right: auto;
    transform: translateX(-50%);
  }
}

@media (min-width: 1440px) {
  .region-header-section-third {
    right: calc((50% - (1280px / 2)) / 2);
  }
  [dir="rtl"] .region-header-section-third {
    left: calc((50% - (1280px / 2)) / 2);
    right: auto;
  }
}

@media (min-width: 1680px) {
  .region-header-section-third {
    right: calc((50% - (1440px / 2)) / 2);
  }
  [dir="rtl"] .region-header-section-third {
    left: calc((50% - (1440px / 2)) / 2);
    right: auto;
  }
}

@media (min-width: 1800px) {
  .region-header-section-third {
    right: calc((50% - (1620px / 2)) / 2);
  }
  [dir="rtl"] .region-header-section-third {
    left: calc((50% - (1620px / 2)) / 2);
    right: auto;
  }
}

.yellow-black .region-header-section-third {
  background-color: #1e1e1e;
}

.region-header-section-third .tz-wcag-block-toggle {
  height: 40px;
  width: 40px;
}

@media (min-width: 1366px) {
  .region-header-section-third .tz-wcag-block-toggle {
    display: none;
    height: 50px;
    width: 50px;
  }
}

.region-header-section-third .tz-wcag-block-toggle button {
  background-color: transparent;
  background-image: url("../images/accessibility-icon.svg");
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 40px auto;
  border: 0;
  display: block;
  height: 40px;
  width: 40px;
}

.region-header-section-third .tz-wcag-block-toggle button:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .region-header-section-third .tz-wcag-block-toggle button:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 1366px) {
  .region-header-section-third .tz-wcag-block-toggle button {
    background-size: 50px auto;
    height: 50px;
    width: 50px;
  }
}

.region-header-section-third .tz-wcag-block-toggle button:hover {
  background-position: center -40px;
}

@media (min-width: 1366px) {
  .region-header-section-third .tz-wcag-block-toggle button:hover {
    background-position: center -50px;
  }
}

.yellow-black .region-header-section-third .tz-wcag-block-toggle button {
  background-position: center -80px;
}

@media (min-width: 1366px) {
  .yellow-black .region-header-section-third .tz-wcag-block-toggle button {
    background-position: center -100px;
  }
}

.region-header-section-third .tz-wcag-block-content {
  display: none;
}

@media (min-width: 1366px) {
  .region-header-section-third .tz-wcag-block-content {
    display: block;
    width: auto;
  }
}

.region-header-section-third .tz-wcag-block-content .block + .block {
  margin-top: 20px;
}

.region-header-section-third .tz-wcag-block-content-inner {
  padding: 20px 10px;
}

@media (min-width: 1366px) {
  .region-header-section-third .tz-wcag-block-content-inner {
    padding: 0;
  }
}

.region-header-section-third .block-tz-sign-language-interpreter .content {
  margin-left: 10px;
}

.region-header-section-third.expanded .tz-wcag-block-content {
  background-color: #fff;
  border: 1px solid #000;
  display: block;
  position: fixed;
  right: 0;
  top: 52px;
  width: 210px;
}

[dir="rtl"] .region-header-section-third.expanded .tz-wcag-block-content {
  left: 0;
  right: auto;
}

.yellow-black .region-header-section-third.expanded .tz-wcag-block-content {
  background-color: #1e1e1e;
  border-color: #fd0;
}

.region-header-section-third.sticky {
  right: 10px;
  transform: translateY(-50%);
}

[dir="rtl"] .region-header-section-third.sticky {
  left: 10px;
  right: auto;
  transform: translateY(-50%);
}

.region-header-section-third.sticky .tz-wcag-block-toggle {
  display: block;
}

.region-header-section-third.sticky .tz-wcag-block-content {
  display: none;
}

.block-textsize .content h2, .block-tz-contrast h2 {
  font-size: 0.75em;
  letter-spacing: .1em;
  margin: 0 0 12px;
  text-transform: uppercase;
}

.header {
  height: 160px;
  position: relative;
  /* /sass/custom/regions/_header-section-second.scss */
  /* /sass/custom/regions/_header-section-third.scss */
}

@media (min-width: 1024px) {
  .header {
    height: 180px;
  }
}

.header .block {
  margin: 0;
}

.header .logo {
  position: relative;
}

@media (min-width: 1024px) {
  .header .logo.sticky {
    background-color: #fff;
    height: 100px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 998;
  }
  .yellow-black .header .logo.sticky {
    background-color: #1e1e1e;
  }
  .header .logo.sticky .header__logo {
    height: 80px;
    margin: 10px 0 0;
    width: auto;
  }
  .header .logo.sticky .header__logo-image {
    height: 80px;
  }
  .header .logo.sticky .js-header-ribbon {
    height: 90px;
    transform: translate(110px, 5px);
    width: 60px;
  }
  [dir="rtl"] .header .logo.sticky .js-header-ribbon {
    transform: translate(-110px, 5px);
  }
}

@media only screen and (min-width: 1025px) {
  .admin-menu .header .logo.sticky {
    top: 28px;
  }
}

.header__logo {
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  transition: height 0.3s ease;
  display: block;
  height: 120px;
  margin: 50px 0 0;
}

.header__logo:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .header__logo:focus-visible {
  outline-color: #fd0;
}

@media (min-width: 1024px) {
  .header__logo {
    height: 100px;
    float: left;
    margin-top: 10px;
  }
}

@media (min-width: 1280px) {
  .header__logo {
    height: 140px;
    margin: 35px 0 0 -40px;
  }
}

@media (min-width: 1680px) {
  .header__logo {
    margin-left: 0;
  }
}

.yellow-black .header__logo {
  background-image: url("../logo-yellow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.yellow-black.i18n-yi .header__logo {
  background-image: url("../logo-jidysz-yellow.png");
}

.header__logo-image {
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  transition: height 0.3s ease;
  display: block;
  height: 120px;
  margin: 0 auto;
  width: auto;
}

@media (min-width: 1024px) {
  .header__logo-image {
    height: 100px;
    margin: 0;
  }
}

@media (min-width: 1280px) {
  .header__logo-image {
    height: 140px;
  }
}

.yellow-black .header__logo-image {
  visibility: hidden;
}

.header .js-header-ribbon {
  -webkit-transition: height 0.3s ease, width 0.3s ease, -webkit-transform 0.3s ease;
  -moz-transition: height 0.3s ease, width 0.3s ease, -moz-transform 0.3s ease;
  transition: height 0.3s ease, width 0.3s ease, transform 0.3s ease;
  background-image: url("../images/black-ribbon.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 90px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(80px, 50px);
  width: 60px;
}

@media (min-width: 1024px) {
  .header .js-header-ribbon {
    left: 0;
    top: 0;
    transform: translate(160px, 10px);
  }
}

@media (min-width: 1280px) {
  .header .js-header-ribbon {
    transform: translate(170px, 30px);
  }
}

@media (min-width: 1680px) {
  .header .js-header-ribbon {
    transform: translate(210px, 30px);
  }
}

.yellow-black .header .js-header-ribbon {
  transform: translate(80px, 0);
}

@media (min-width: 1024px) {
  .yellow-black .header .js-header-ribbon {
    transform: translate(160px, 10px);
  }
}

@media (min-width: 1280px) {
  .yellow-black .header .js-header-ribbon {
    transform: translate(170px, 30px);
  }
}

@media (min-width: 1680px) {
  .yellow-black .header .js-header-ribbon {
    transform: translate(210px, 30px);
  }
}

[dir="rtl"] .header .js-header-ribbon {
  background-image: url("../images/black-ribbon-rtl.png");
  left: auto;
  right: 0;
  transform: translate(-170px, 50px);
}

@media (min-width: 1024px) {
  [dir="rtl"] .header .js-header-ribbon {
    transform: translate(-160px, 10px);
  }
}

@media (min-width: 1280px) {
  [dir="rtl"] .header .js-header-ribbon {
    transform: translate(-210px, 30px);
  }
}

.header__site-name {
  display: none;
}

@media (min-width: 1024px) {
  .header__site-name {
    background-image: url("../images/tatr-zydowski-jidysz.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 55px;
    position: absolute;
    right: 50%;
    text-indent: -9999px;
    transform: translateX(50%);
    top: 35px;
    width: 290px;
  }
  .yellow-black .header__site-name {
    background-image: url("../images/tatr-zydowski-jidysz-yellow.png");
  }
}

.header .region-header-section-first {
  position: absolute;
  right: 0;
  top: 0;
}

[dir="rtl"] .header .region-header-section-first {
  left: 0;
}

.header .block-language-switcher-fallback {
  display: none;
}

@media (min-width: 1024px) {
  .header .block-language-switcher-fallback {
    display: block;
    float: right;
  }
  [dir="rtl"] .header .block-language-switcher-fallback {
    float: left;
  }
}

.header .exposed-form-search-results-page {
  display: none;
  /* /sass/custom/blocks/_exposed-form-search-results-page.scss */
}

@media (min-width: 1024px) {
  .header .exposed-form-search-results-page {
    display: block;
  }
  [dir="rtl"] .header .exposed-form-search-results-page {
    display: none;
  }
}

@media (min-width: 1440px) {
  .header .exposed-form-search-results-page {
    float: right;
    margin-right: 5px;
  }
}

.region-navigation .block-superfish {
  background-color: #000;
  margin: -160px -20px 0;
  position: relative;
  z-index: 9;
}

.admin-menu .region-navigation .block-superfish {
  margin-top: -194px;
}

@media (min-width: 768px) {
  .admin-menu .region-navigation .block-superfish {
    margin-top: -224px;
  }
}

@media (min-width: 1024px) {
  .admin-menu .region-navigation .block-superfish {
    margin-top: -60px;
  }
}

@media (min-width: 1024px) {
  .region-navigation .block-superfish {
    background-color: transparent;
    border-top: 7px solid #000;
    float: right;
    margin: -60px 0 0;
    width: auto;
  }
  .yellow-black .region-navigation .block-superfish {
    border-top-color: #fd0;
  }
  [dir="rtl"] .region-navigation .block-superfish {
    float: left;
  }
}

@media (min-width: 1280px) {
  .region-navigation .block-superfish {
    min-width: calc(50% + 145px);
    width: auto;
  }
}

@media (min-width: 1024px) {
  .region-navigation .block-superfish.sticky {
    background-color: #fff;
    border-top: 0;
    margin: 0;
    min-width: auto;
    position: fixed;
    top: 0;
    z-index: 998;
  }
  .yellow-black .region-navigation .block-superfish.sticky {
    background-color: #1e1e1e;
  }
}

@media only screen and (min-width: 1025px) {
  .admin-menu .region-navigation .block-superfish.sticky {
    top: 29px;
  }
}

.region-navigation .block-superfish .sf-accordion-toggle {
  background-color: #000;
  float: left;
  width: 100%;
}

.region-navigation .block-superfish .sf-accordion-toggle a {
  color: #fff;
  display: block;
  float: right;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.yellow-black .region-navigation .block-superfish .sf-accordion-toggle a {
  color: #fd0;
}

.region-navigation .block-superfish .sf-accordion-toggle a::after {
  background-color: #000;
  content: '\f0c9';
  font-family: "FontAwesome";
  font-size: 30px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 40px;
}

.region-navigation .block-superfish .sf-accordion-toggle a.sf-expanded::after {
  content: '\f00d';
  font-size: 24px;
}

.region-navigation .block-superfish .sf-accordion-toggle a span {
  display: block;
  text-indent: -9999px;
}

.region-navigation .block-superfish .navigation-wcag {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  clear: both;
  display: none;
  padding: 20px 15px;
}

.yellow-black .region-navigation .block-superfish .navigation-wcag {
  border-color: #fd0;
}

.region-navigation .block-superfish .block-language-switcher-fallback {
  display: none;
}

.region-navigation .block-superfish .language-switcher-locale-url {
  background-color: #000;
  padding: 20px;
}

.region-navigation .block-superfish .language-switcher-locale-url li,
.region-navigation .block-superfish .language-switcher-locale-url .language-link {
  border: 0;
}

.region-navigation .block-superfish .language-switcher-locale-url a {
  color: #fff;
  font-weight: 600;
  line-height: 1.5;
  padding: 10px 10px 8px;
}

.yellow-black .region-navigation .block-superfish .language-switcher-locale-url a {
  border-color: #fd0;
  color: #fd0;
}

.region-navigation .block-superfish .language-switcher-locale-url a.active {
  background-color: #0000fe;
  color: #fff;
}

.yellow-black .region-navigation .block-superfish .language-switcher-locale-url a.active {
  background-color: #fd0;
  color: #1e1e1e;
}

.region-navigation .block-superfish .sf-accordion.sf-expanded ~ .navigation-wcag {
  display: flex;
  gap: 40px;
}

.region-navigation .block-superfish .sf-accordion.sf-expanded ~ .block-language-switcher-fallback {
  display: block;
}

.view-frontpage-slider {
  margin: 0 -20px;
  overflow: hidden;
  max-width: 1600px;
}

@media (min-width: 480px) {
  .view-frontpage-slider {
    margin-left: auto;
    margin-right: auto;
  }
}

[dir="rtl"] .view-frontpage-slider {
  margin-bottom: 2em;
}

@media (min-width: 768px) {
  [dir="rtl"] .view-frontpage-slider {
    margin-bottom: 3em;
  }
}

@media print {
  .view-frontpage-slider {
    display: none;
    visibility: hidden;
  }
}

.view-frontpage-slider .slick {
  position: relative;
}

.view-frontpage-slider .slick__slider {
  margin: 0 auto;
}

.view-frontpage-slider .slick__slider .slick-list {
  overflow: visible;
}

.view-frontpage-slider .slick__slide {
  position: relative;
}

.view-frontpage-slider .slick__arrow {
  display: none;
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick__arrow {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.view-frontpage-slider .slick__arrow button {
  background-color: #0000fe;
  background-image: url("../images/arrows-slick.png");
  border: 0;
  height: 50px;
  overflow: hidden;
  pointer-events: auto;
  width: 50px;
  text-indent: -9999px;
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick__arrow button {
    background-color: transparent;
  }
}

.yellow-black .view-frontpage-slider .slick__arrow button {
  background-image: url("../images/arrows-slick-yellow.png");
}

.view-frontpage-slider .slick__arrow button.slick-prev {
  background-position: left top;
  float: left;
}

.view-frontpage-slider .slick__arrow button.slick-next {
  background-position: right top;
  float: right;
}

.view-frontpage-slider .slick__arrow button:hover {
  opacity: .65;
}

.view-frontpage-slider .slick .field-group-div {
  margin: 0;
}

.view-frontpage-slider .slick .group-content {
  position: relative;
}

.view-frontpage-slider .slick .group-content .group-text {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick .group-content .group-text {
    bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .view-frontpage-slider .slick .group-content .group-text {
    bottom: 20px;
  }
}

.view-frontpage-slider .slick .group-content .title-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick .group-content .title-wrapper {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
    text-align: right;
    width: 50%;
  }
}

.view-frontpage-slider .slick .group-content .title-wrapper .title {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  font-size: calc(1.125em + 3.375 * ( (100vw - 22.5em) / 97.5));
  color: #fff;
  margin: 0;
  text-transform: uppercase;
}

@media screen and (max-width: 22.5em) {
  .view-frontpage-slider .slick .group-content .title-wrapper .title {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 120em) {
  .view-frontpage-slider .slick .group-content .title-wrapper .title {
    font-size: 4.5em;
  }
}

.yellow-black .view-frontpage-slider .slick .group-content .title-wrapper .title {
  color: #fd0;
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick .group-content .title-wrapper .title {
    border-right: 6px solid #fff;
    padding: 0 30px 0 20px;
  }
  [dir="rtl"] .view-frontpage-slider .slick .group-content .title-wrapper .title {
    border-right: 0;
  }
  .yellow-black .view-frontpage-slider .slick .group-content .title-wrapper .title {
    border-color: #fd0;
  }
}

.view-frontpage-slider .slick .group-content .text {
  font-size: calc(0.875em + 0.5 * ( (100vw - 22.5em) / 97.5));
  color: #fff;
  font-family: "Inter UI", sans-serif;
  padding: 0 20px 10px;
}

@media screen and (max-width: 22.5em) {
  .view-frontpage-slider .slick .group-content .text {
    font-size: 0.875em;
  }
}

@media screen and (min-width: 120em) {
  .view-frontpage-slider .slick .group-content .text {
    font-size: 1.375em;
  }
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick .group-content .text {
    display: inline-block;
    line-height: 1.1;
    padding: 0;
    text-align: left;
    width: 50%;
  }
}

.yellow-black .view-frontpage-slider .slick .group-content .text {
  color: #fd0;
}

.view-frontpage-slider .slick .group-content .text p {
  margin: 0;
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick .group-content .text p {
    margin-top: 5px;
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick .group-content .text p + p {
    margin-top: 10px;
  }
}

.view-frontpage-slider .slick .group-content .text strong {
  font-weight: 700;
}

@media (min-width: 480px) {
  .view-frontpage-slider .slick .group-content .text strong {
    font-size: calc(1.125em + 0.875 * ( (100vw - 22.5em) / 97.5));
  }
}

@media screen and (min-width: 480px) and (max-width: 22.5em) {
  .view-frontpage-slider .slick .group-content .text strong {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 480px) and (min-width: 120em) {
  .view-frontpage-slider .slick .group-content .text strong {
    font-size: 2em;
  }
}

.view-frontpage-slider .slick .group-content .image {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .view-frontpage-slider .slick .group-content .image {
    margin-top: 0;
  }
}

.view-frontpage-slider .slick .group-content .image img {
  display: block;
}

.view-frontpage-slider .slick .read-more-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 0;
  z-index: 2;
}

.view-frontpage-slider .slick .read-more-link:focus-visible {
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-frontpage-slider .slick .read-more-link:focus-visible {
  outline-color: #fd0;
}

.view-news .view-content {
  border-top: 20px solid #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2em;
}

.yellow-black .view-news .view-content {
  border-top-color: #665800;
}

@media (min-width: 480px) {
  .view-news .views-row {
    width: 50%;
  }
  .view-news .views-row.views-row-odd .group-content {
    margin-right: 10px;
  }
  .view-news .views-row.views-row-even .group-content {
    margin-left: 10px;
  }
}

@media (min-width: 1024px) {
  .view-news .views-row {
    width: 33.3333%;
  }
  .view-news .views-row.views-row-1 .group-content, .view-news .views-row.views-row-4 .group-content, .view-news .views-row.views-row-7 .group-content, .view-news .views-row.views-row-10 .group-content {
    margin-right: 32px;
    margin-left: 0;
  }
  .view-news .views-row.views-row-2 .group-content, .view-news .views-row.views-row-5 .group-content, .view-news .views-row.views-row-8 .group-content, .view-news .views-row.views-row-11 .group-content {
    margin-left: 16px;
    margin-right: 16px;
  }
  .view-news .views-row.views-row-3 .group-content, .view-news .views-row.views-row-6 .group-content, .view-news .views-row.views-row-9 .group-content, .view-news .views-row.views-row-12 .group-content {
    margin-left: 32px;
    margin-right: 0;
  }
}

.view-news .node {
  padding-bottom: 2em;
}

.view-news .view-footer {
  margin-top: 2em;
}

.view-node-gallery-embedded,
.view-person-photos {
  margin: 0 -20px;
  overflow: hidden;
  position: relative;
  max-width: 1640px;
  max-height: 570px;
}

@media (min-width: 480px) {
  .view-node-gallery-embedded,
  .view-person-photos {
    margin-left: auto;
    margin-right: auto;
  }
}

.view-node-gallery-embedded .unslick,
.view-person-photos .unslick {
  text-align: center;
}

.view-node-gallery-embedded .unslick .file-image,
.view-person-photos .unslick .file-image {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.view-node-gallery-embedded .unslick .file-image img,
.view-person-photos .unslick .file-image img {
  display: block;
  margin: 0 auto;
}

.view-node-gallery-embedded .slick__slider,
.view-person-photos .slick__slider {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .view-node-gallery-embedded .slick__slider,
  .view-person-photos .slick__slider {
    width: 600px;
  }
}

@media (min-width: 1280px) {
  .view-node-gallery-embedded .slick__slider,
  .view-person-photos .slick__slider {
    width: 850px;
  }
}

.view-node-gallery-embedded .slick__slider .slick-list,
.view-person-photos .slick__slider .slick-list {
  overflow: visible;
}

.view-node-gallery-embedded .slick__slide,
.view-person-photos .slick__slide {
  position: relative;
}

.view-node-gallery-embedded .slick__slide::before,
.view-person-photos .slick__slide::before {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.view-node-gallery-embedded .slick__slide:not(.slick-current),
.view-person-photos .slick__slide:not(.slick-current) {
  cursor: grab;
}

.view-node-gallery-embedded .slick__slide:not(.slick-current)::before,
.view-person-photos .slick__slide:not(.slick-current)::before {
  opacity: 1;
}

.view-node-gallery-embedded .slick__slide:not(.slick-current):active,
.view-person-photos .slick__slide:not(.slick-current):active {
  cursor: grabbing;
}

.view-node-gallery-embedded .slick__slide:not(.slick-current) a.colorbox,
.view-person-photos .slick__slide:not(.slick-current) a.colorbox {
  pointer-events: none;
}

.view-node-gallery-embedded .slick__arrow,
.view-person-photos .slick__arrow {
  display: none;
  width: 100%;
}

@media (min-width: 768px) {
  .view-node-gallery-embedded .slick__arrow,
  .view-person-photos .slick__arrow {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.view-node-gallery-embedded .slick__arrow button,
.view-person-photos .slick__arrow button {
  background-color: transparent;
  background-image: url("../images/arrows-slick.png");
  border: 0;
  height: 50px;
  overflow: hidden;
  pointer-events: auto;
  width: 50px;
  text-indent: -9999px;
}

.yellow-black .view-node-gallery-embedded .slick__arrow button, .yellow-black
.view-person-photos .slick__arrow button {
  background-image: url("../images/arrows-slick-yellow.png");
}

.view-node-gallery-embedded .slick__arrow button.slick-prev,
.view-person-photos .slick__arrow button.slick-prev {
  background-position: left top;
  float: left;
}

.view-node-gallery-embedded .slick__arrow button.slick-next,
.view-person-photos .slick__arrow button.slick-next {
  background-position: right top;
  float: right;
}

.view-node-gallery-embedded .slick__arrow button.slick-disabled,
.view-person-photos .slick__arrow button.slick-disabled {
  cursor: default;
  opacity: .1;
}

.view-node-gallery-embedded .slick__arrow button.slick-disabled:hover,
.view-person-photos .slick__arrow button.slick-disabled:hover {
  opacity: .1;
}

.view-node-gallery-embedded .slick__arrow button:hover,
.view-person-photos .slick__arrow button:hover {
  opacity: .65;
}

.view-node-gallery-embedded .slide__content img,
.view-person-photos .slide__content img {
  display: block;
}

.view-node-gallery-embedded .caption,
.view-person-photos .caption {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  color: #fff;
  font-size: 0.875em;
  font-style: italic;
  left: 0;
  padding: 2px 5px;
  position: absolute;
}

.yellow-black .view-node-gallery-embedded .caption, .yellow-black
.view-person-photos .caption {
  background-color: #1e1e1e;
  color: #fd0;
}

@media (min-width: 1024px) {
  .view-repertoire .view-content table {
    display: table;
  }
}

.view-repertoire .view-content thead {
  display: none;
}

@media (min-width: 768px) {
  .view-repertoire .view-content thead {
    display: table-header-group;
  }
}

.view-repertoire .view-content thead tr {
  border-bottom: 1px solid #000;
}

.yellow-black .view-repertoire .view-content thead tr {
  border-color: #fd0;
}

.view-repertoire .view-content thead tr th {
  font-size: 0.875em;
  font-weight: 200;
  padding-top: 20px;
  text-align: left;
}

@media print {
  .view-repertoire .view-content thead tr th:nth-child(8) {
    display: none;
  }
}

.view-repertoire .view-content tbody {
  display: block;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody {
    display: table-row-group;
  }
}

.view-repertoire .view-content tbody tr {
  border-bottom: 1px solid #000;
  display: block;
  float: left;
  padding-bottom: 8px;
  padding-top: 8px;
  width: 100%;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody tr {
    display: table-row;
    float: none;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.yellow-black .view-repertoire .view-content tbody tr {
  border-color: #fd0;
}

.view-repertoire .view-content tbody td {
  display: block;
  float: left;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td {
    display: table-cell;
    float: none;
    height: 90px;
    vertical-align: middle;
  }
}

.view-repertoire .view-content tbody td.date-day {
  width: 160px;
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.date-day {
    width: 190px;
  }
}

.view-repertoire .view-content tbody td.date-day .day-month {
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  display: inline-block;
  font-size: 1.5em;
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.date-day .day-month {
    font-size: 1.875em;
  }
}

.view-repertoire .view-content tbody td.date-day .day-month span {
  display: inline-block;
  line-height: 1;
}

.view-repertoire .view-content tbody td.date-day .day-of-week {
  font-size: 1.25em;
  display: inline-block;
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.date-day .day-of-week {
    font-size: 1.5em;
  }
}

.view-repertoire .view-content tbody td.date-day .day-of-week span {
  display: inline-block;
  line-height: 1;
}

.view-repertoire .view-content tbody td.date-day .underscore {
  background-color: #0000fe;
  display: inline-block;
  height: 3px;
  width: 30px;
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.date-day .underscore {
    height: 4px;
    width: 50px;
  }
}

.yellow-black .view-repertoire .view-content tbody td.date-day .underscore {
  background-color: #fd0;
}

.view-repertoire .view-content tbody td.date-hour {
  font-size: 1.25em;
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.date-hour {
    font-size: 1.5em;
    width: 65px;
  }
}

.view-repertoire .view-content tbody td.date-hour span {
  display: inline-block;
  margin-top: 4px;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.date-hour span {
    margin-top: 0;
  }
}

.view-repertoire .view-content tbody td.stage {
  font-size: 1.125em;
  clear: both;
  font-weight: 600;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.stage {
    clear: none;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.stage {
    font-size: 1.1875em;
    min-width: 200px;
  }
}

.view-repertoire .view-content tbody td.stage span {
  display: inline-block;
}

@media (min-width: 480px) {
  .view-repertoire .view-content tbody td.stage span {
    padding-top: 7px;
  }
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.stage span {
    line-height: 1;
  }
}

.view-repertoire .view-content tbody td.title {
  font-size: calc(1.125em + 0.375 * ( (100vw - 20em) / 27.9375));
  font-family: "Inter UI", sans-serif;
  font-weight: 700;
  clear: both;
  line-height: 1.2;
  margin-top: 10px;
  text-transform: uppercase;
}

@media screen and (max-width: 20em) {
  .view-repertoire .view-content tbody td.title {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 47.9375em) {
  .view-repertoire .view-content tbody td.title {
    font-size: 1.5em;
  }
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.title {
    font-size: calc(1.125em + 0.75 * ( (100vw - 48em) / 72));
    clear: none;
    margin-top: 0;
    padding: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 48em) {
  .view-repertoire .view-content tbody td.title {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 768px) and (min-width: 120em) {
  .view-repertoire .view-content tbody td.title {
    font-size: 1.875em;
  }
}

.view-repertoire .view-content tbody td.title p {
  margin: 0;
}

.view-repertoire .view-content tbody td.title .smaller {
  font-size: .85em;
  display: block;
}

.view-repertoire .view-content tbody td.title .smaller a {
  display: block;
}

.yellow-black .view-repertoire .view-content tbody td.title .smaller span {
  color: #fd0 !important;
}

.view-repertoire .view-content tbody td.title a {
  line-height: 1.25;
  text-decoration: none;
}

.view-repertoire .view-content tbody td.title a:link {
  color: #000;
}

.view-repertoire .view-content tbody td.title a:visited {
  color: #000;
}

.view-repertoire .view-content tbody td.title a:hover {
  color: #0000fe;
}

.view-repertoire .view-content tbody td.title a:active {
  color: #0000fe;
}

.view-repertoire .view-content tbody td.title a:focus-visible {
  color: #0000fe;
  outline: 2px solid #0000fe;
  outline-offset: 2px;
}

.yellow-black .view-repertoire .view-content tbody td.title a:link {
  color: #fd0;
}

.yellow-black .view-repertoire .view-content tbody td.title a:visited {
  color: #fd0;
}

.yellow-black .view-repertoire .view-content tbody td.title a:hover {
  color: #fd0;
  text-decoration: underline;
}

.yellow-black .view-repertoire .view-content tbody td.title a:active {
  color: #fd0;
}

.yellow-black .view-repertoire .view-content tbody td.title a:focus-visible {
  color: #fd0;
  outline: 2px solid #fd0;
}

.view-repertoire .view-content tbody td.title a:hover span {
  color: #0000fe !important;
}

.view-repertoire .view-content tbody td.title .premiere,
.view-repertoire .view-content tbody td.title .info-label {
  display: block;
  font-size: .75em;
}

.view-repertoire .view-content tbody td.reservation {
  clear: both;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.reservation {
    clear: none;
    width: 110px;
  }
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.reservation {
    text-align: left;
    width: 160px;
  }
}

.view-repertoire .view-content tbody td.reservation a.get-ticket {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  font-size: 0.875em;
  margin-top: 10px;
}

.view-repertoire .view-content tbody td.reservation a.get-ticket:link, .view-repertoire .view-content tbody td.reservation a.get-ticket:visited {
  background-color: #0000fe;
  border: 1px solid #0000fe;
  color: #fff;
}

.view-repertoire .view-content tbody td.reservation a.get-ticket:hover, .view-repertoire .view-content tbody td.reservation a.get-ticket:active, .view-repertoire .view-content tbody td.reservation a.get-ticket:focus-visible {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.view-repertoire .view-content tbody td.reservation a.get-ticket:focus-visible {
  outline: 2px solid #0000fe;
}

.yellow-black .view-repertoire .view-content tbody td.reservation a.get-ticket:link, .yellow-black .view-repertoire .view-content tbody td.reservation a.get-ticket:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .view-repertoire .view-content tbody td.reservation a.get-ticket:hover, .yellow-black .view-repertoire .view-content tbody td.reservation a.get-ticket:active, .yellow-black .view-repertoire .view-content tbody td.reservation a.get-ticket:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .view-repertoire .view-content tbody td.reservation a.get-ticket:focus-visible {
  outline: 2px solid #fd0;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.reservation a.get-ticket {
    display: table-cell;
    font-size: 1em;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.reservation a.get-ticket {
    min-width: 120px;
  }
}

@media print {
  .view-repertoire .view-content tbody td.reservation {
    display: none;
  }
}

.view-repertoire .view-content tbody td.pipe {
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
  width: 11px;
}

@media (min-width: 1024px) {
  .view-repertoire .view-content tbody td.pipe {
    padding-left: 10px;
    padding-right: 10px;
    width: 21px;
  }
}

.view-repertoire .view-content tbody td.pipe.pipe-3 {
  display: none;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.pipe.pipe-3 {
    display: table-cell;
  }
}

.view-repertoire .view-content tbody td.pipe span {
  background-color: #000;
  display: block;
  height: 24px;
  margin-top: 6px;
  overflow: hidden;
  width: 1px;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.pipe span {
    height: 70px;
    margin-top: 0;
  }
}

.yellow-black .view-repertoire .view-content tbody td.pipe span {
  background-color: #fd0;
}

.view-repertoire .view-content tbody td.premiere-col {
  clear: both;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.premiere-col {
    clear: none;
    margin-top: 0;
  }
}

.view-repertoire .view-content tbody td.info-label-col {
  clear: both;
  line-height: 1.2;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .view-repertoire .view-content tbody td.info-label-col {
    clear: none;
    margin-top: 0;
    padding: 10px;
    text-align: center;
  }
}

.yellow-black .view-repertoire .view-content tbody td.info-label-col span {
  color: #fd0 !important;
}

.view-repertoire .view-content tbody td .premiere {
  color: #0000fe;
  display: inline-block;
  font-size: 1.375em;
  font-weight: 700;
  text-transform: uppercase;
}

.yellow-black .view-repertoire .view-content tbody td .premiere {
  color: #fd0;
}

.view-repertoire .view-content tbody td .info-label {
  color: #0000fe;
  display: inline-block;
  font-size: 1.375em;
  font-weight: 700;
  text-transform: uppercase;
}

.yellow-black .view-repertoire .view-content tbody td .info-label {
  color: #fd0;
}

.view-repertoire .view-footer {
  font-weight: 500;
  margin: 2em 0 1.5em;
}

.view-repertoire .view-footer > *:first-child {
  margin-top: 0;
}

.view-repertoire .view-footer > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .view-repertoire .view-footer {
    font-size: 1.125em;
  }
}

.logged-in .view-repertoire tr {
  position: relative;
}

.logged-in .view-repertoire tr:hover .views-field-edit-node {
  opacity: 1;
}

.logged-in .views-field-edit-node {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.logged-in .views-field-edit-node a {
  display: inline-block;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  font-size: 0.9375em;
  padding: .1em .5em .05em;
}

.logged-in .views-field-edit-node a:link, .logged-in .views-field-edit-node a:visited {
  background-color: #0000fe;
  border: 1px solid #0000fe;
  color: #fff;
}

.logged-in .views-field-edit-node a:hover, .logged-in .views-field-edit-node a:active, .logged-in .views-field-edit-node a:focus-visible {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.logged-in .views-field-edit-node a:focus-visible {
  outline: 2px solid #0000fe;
}

.yellow-black .logged-in .views-field-edit-node a:link, .yellow-black .logged-in .views-field-edit-node a:visited {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

.yellow-black .logged-in .views-field-edit-node a:hover, .yellow-black .logged-in .views-field-edit-node a:active, .yellow-black .logged-in .views-field-edit-node a:focus-visible {
  background-color: #fd0;
  border-color: #fd0;
  color: #1e1e1e;
}

.yellow-black .logged-in .views-field-edit-node a:focus-visible {
  outline: 2px solid #fd0;
}

.view-ticket-thx .view-content {
  font-size: 1.125rem;
}

#backtotop {
  background-color: #0000fe;
  background-image: url("../images/backtotop.png");
  background-position: center center;
  background-repeat: no-repeat;
  border: 0;
  bottom: 10px;
  cursor: pointer;
  display: none;
  height: 50px;
  overflow: hidden;
  padding: 0;
  position: fixed;
  right: 10px;
  text-indent: -9999px;
  width: 50px;
  z-index: 1;
}

.yellow-black #backtotop {
  background-color: #1e1e1e;
  background-image: url("../images/backtotop-yellow.png");
}

@media (min-width: 1440px) {
  #backtotop {
    right: calc((50% - (1280px / 2)) / 2);
    transform: translateX(50%);
  }
}

@media (min-width: 1680px) {
  #backtotop {
    right: calc((50% - (1440px / 2)) / 2);
  }
}

@media (min-width: 1800px) {
  #backtotop {
    right: calc((50% - (1620px / 2)) / 2);
  }
}

#backtotop:hover {
  background-color: #000;
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper {
  left: 0;
  top: 0;
  z-index: 9999;
}

#colorbox,
#cboxWrapper {
  position: absolute;
}

#cboxOverlay {
  background-color: #111;
  height: 100%;
  position: fixed;
  width: 100%;
}

.poster-opened #cboxOverlay {
  background-color: #f2f2f2;
}

.yellow-black #cboxOverlay {
  background-color: #111 !important;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  margin: 50px 0;
  position: relative;
}

#cboxLoadedContent {
  margin: 40px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxLoadedContent img {
  display: block;
  margin: 0 auto;
}

#popup img {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

#cboxTitle {
  color: #cccccc;
  font-size: 14px;
  margin: 0;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}

.poster-opened #cboxTitle {
  color: #333333;
}

.yellow-black #cboxTitle {
  color: #fd0 !important;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  background-color: #111;
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#colorbox {
  outline: 0;
}

#cboxError {
  border: 1px solid #cccccc;
  color: #c00;
  padding: 50px;
}

.yellow-black #cboxError {
  border-color: #fd0 !important;
  color: #fd0 !important;
}

#cboxCurrent {
  color: #666666;
  font-family: "Inter UI", sans-serif;
  font-size: 36px;
  font-weight: 700;
  left: 0;
  position: absolute;
  top: 0;
  text-align: left;
  width: 100%;
}

.yellow-black #cboxCurrent {
  color: #fd0;
}

#cboxLoadingGraphic {
  -webkit-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 6px solid #cccccc;
  border-right: 6px solid #cccccc;
  border-bottom: 6px solid #cccccc;
  border-top: 6px solid #0000fe;
  border-radius: 100%;
  box-sizing: content-box;
  height: 50px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 50px;
  transform: translate(-50%, -50%);
}

.yellow-black #cboxLoadingGraphic {
  border-left-color: #fd0;
  border-right-color: #fd0;
  border-bottom-color: #fd0;
  border-top-color: transparent;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  background: none;
  border: 0;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: #fff;
}

#cboxPrevious,
#cboxNext,
#cboxClose {
  outline: none;
  position: fixed;
  text-indent: -9999px;
}

#cboxPrevious,
#cboxNext {
  background-image: url("../images/arrows-colorbox.png");
  background-repeat: no-repeat;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 43px;
}

#cboxPrevious {
  background-position: top left;
  left: 0;
}

.yellow-black #cboxPrevious {
  background-position: bottom left;
}

@media (min-width: 1280px) {
  #cboxPrevious {
    left: 120px;
  }
}

#cboxPrevious:hover {
  background-position: left -50px;
}

#cboxNext {
  background-position: top right;
  right: 10px;
}

.yellow-black #cboxNext {
  background-position: bottom right;
}

@media (min-width: 1280px) {
  #cboxNext {
    right: 120px;
  }
}

#cboxNext:hover {
  background-position: right -50px;
}

#cboxClose {
  background-image: url("../images/close.png");
  background-position: top left;
  background-repeat: no-repeat;
  display: block;
  height: 50px;
  right: 10px;
  top: 0;
  width: 50px;
}

.yellow-black #cboxClose {
  background-position: bottom left;
}

@media (min-width: 1280px) {
  #cboxClose {
    right: 40px;
    top: 40px;
  }
}

#cboxClose:hover {
  background-position: left -50px;
}

#cboxClose:focus-visible {
  outline: 2px solid #fff;
}

#sliding-popup {
  background-color: #f2f2f2;
  display: block;
  margin: 0;
  padding: 1em 0;
  right: 0;
  width: 100%;
  z-index: 99999;
}

.yellow-black #sliding-popup {
  background-color: #1e1e1e;
  color: #fd0;
}

#sliding-popup .popup-content {
  background-color: #f2f2f2;
  border: 0 none;
  display: block;
  margin: 0 auto;
  padding: 0;
}

.yellow-black #sliding-popup .popup-content {
  background-color: #1e1e1e;
  color: #fd0;
}

.sliding-popup-bottom {
  position: fixed;
}

.sliding-popup-top {
  position: relative;
}

#popup-buttons {
  margin-top: 1em;
  text-align: center;
}

@media (min-width: 768px) {
  #popup-buttons {
    float: right;
    margin-top: 0;
    width: 40%;
    text-align: right;
  }
}

#popup-buttons button {
  background-color: #0000fe;
  border: 1px solid #cccccc;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin: 0 0 10px;
  padding: .35em .5em .25em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.yellow-black #popup-buttons button {
  background-color: #1e1e1e;
  border-color: #fd0;
  color: #fd0;
}

#popup-buttons button:hover {
  background-color: #fff;
  border: 1px solid #0000fe;
  color: #0000fe;
}

.yellow-black #popup-buttons button:hover {
  background-color: #fd0;
  border-color: #1e1e1e;
  color: #1e1e1e;
}

#popup-text {
  color: #000;
}

.yellow-black #popup-text {
  color: #fd0;
}

@media (min-width: 768px) {
  #popup-text {
    float: left;
    width: 60%;
  }
}

#popup-text h2,
#popup-text p {
  color: #000;
  display: block;
  margin: 0;
}

.yellow-black #popup-text h2, .yellow-black
#popup-text p {
  color: #fd0;
}

#popup-text button {
  background-color: transparent;
  border: 0;
  color: #0000fe;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  padding: 0;
  text-decoration: underline;
}

.yellow-black #popup-text button {
  color: #fd0;
}

span.ext {
  display: inline-block;
  padding-left: 4px;
}

span.ext::after {
  content: '\f08e';
  display: block;
  font-family: "FontAwesome";
  font-size: .875em;
}

span.mailto {
  display: inline-block;
  padding-left: 4px;
}

span.mailto::after {
  content: '\f003';
  display: block;
  font-family: "FontAwesome";
  font-size: .875em;
}
