// Responsive 18-column grid
//
// Markup: layout-18col.twig
//
// Style guide: layouts.layout-18col

.layout-18col,
%layout-18col {
  // We set the context to flow, so that this container can be used in most
  // places in the HTML, i.e. it must be nested inside something that has
  // padding.
  @include zen-grid-container($context: flow);

  &__col-1,
  &__col-2,
  &__col-3 {
    @include zen-new-row(both);
    @include zen-grid-item-base();
    @include zen-grid-item(1, 1);
  }

  @include zen-respond-to(m) {
    @include zen-grid-container($context: flow);
  }

  @include zen-respond-to(xl) {
    &__col-1 {
      @include zen-new-row(both);
      @include zen-grid-item(2, 1);
    }

    &__col-2 {
      @include zen-new-row(none);
      @include zen-grid-item(14, 3);
    }

    &__col-3 {
      @include zen-new-row(none);
      @include zen-grid-item(2, 16);
    }
  }

  // Place last so it can override the padding of grid items.
  &__grid-item-container {
    @include zen-grid-container($context: grid-item);
  }
}
