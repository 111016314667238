.page-title {
  @include fluid-type(24px, 48px);
  @include typeface(heading);
  border-top: 7px solid color(blue);
  color: color(brand);
  margin: 0;
  overflow-wrap: break-word;
  padding-top: .25em;
  text-align: center;
  text-transform: uppercase;
  @include respond-to(m) {
    border-top: 10px solid color(blue);
  }

  .yellow-black & {
    border-top-color: color(yellow);
    color: color(yellow);
  }

  & + .contextual-links-wrapper {
    margin-top: $layout-swap-height * .6;
  }
}
