/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper {
  left: 0;
  top: 0;
  z-index: 9999;
}

#colorbox,
#cboxWrapper {
  position: absolute;
}

#cboxOverlay {
  background-color: #111;
  height: 100%;
  position: fixed;
  width: 100%;

  .poster-opened & {
    background-color: color(grey-extra-light);
  }

  .yellow-black & {
    background-color: #111 !important;
  }
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  margin: 50px 0;
  position: relative;
}

#cboxLoadedContent {
  margin: 40px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  img {
    display: block;
    margin: 0 auto;
  }
}

#popup {
  img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#cboxTitle {
  color: color(grey-light);
  font-size: 14px;
  margin: 0;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;

  .poster-opened & {
    color: color(grey-extra-dark);
  }

  .yellow-black & {
    color: color(yellow) !important;
  }
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  background-color: #111;
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#colorbox {
  outline: 0;
}

#cboxError {
  border: 1px solid color(grey-light);
  color: color(red);
  padding: 50px;
  .yellow-black & {
    border-color: color(yellow) !important;
    color: color(yellow) !important;
  }
}

#cboxCurrent {
  color: color(grey-dark);
  font-family: $inter-ui;
  font-size: 36px;
  font-weight: $font-weight-bold;
  left: 0;
  position: absolute;
  top: 0;
  text-align: left;
  width: 100%;
  .yellow-black & {
    color: color(yellow);
  }
}

#cboxLoadingGraphic {
  -webkit-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 6px solid color(grey-light);
  border-right: 6px solid color(grey-light);
  border-bottom: 6px solid color(grey-light);
  border-top: 6px solid color(brand);
  border-radius: 100%;
  box-sizing: content-box;
  height: 50px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 50px;
  transform: translate(-50%, -50%);

  .yellow-black & {
    border-left-color: color(yellow);
    border-right-color: color(yellow);
    border-bottom-color: color(yellow);
    border-top-color: transparent;
  }
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  background: none;
  border: 0;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: color(white);
}

#cboxPrevious,
#cboxNext,
#cboxClose {
  outline: none;
  position: fixed;
  text-indent: -9999px;
}

#cboxPrevious,
#cboxNext {
  background-image: url('../images/arrows-colorbox.png');
  background-repeat: no-repeat;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 43px;
}

#cboxPrevious {
  background-position: top left;
  left: 0;

  .yellow-black & {
    background-position: bottom left;
  }

  @include respond-to(xl) {
    left: 120px;
  }

  &:hover {
    background-position: left -50px;
  }
}

#cboxNext {
  background-position: top right;
  right: 10px;

  .yellow-black & {
    background-position: bottom right;
  }

  @include respond-to(xl) {
    right: 120px;
  }

  &:hover {
    background-position: right -50px;
  }
}

#cboxClose {
  background-image: url('../images/close.png');
  background-position: top left;
  background-repeat: no-repeat;
  display: block;
  height: 50px;
  right: 10px;
  top: 0;
  width: 50px;

  .yellow-black & {
    background-position: bottom left;
  }

  @include respond-to(xl) {
    right: 40px;
    top: 40px;
  }

  &:hover {
    background-position: left -50px;
  }

  &:focus-visible {
    outline: 2px solid color(white);
  }
}
