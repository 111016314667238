.node-advanced-page {
  &.view-mode-full {
    .title {
      @include fluid-type(28px, 60px);
      @include respond-to(m) {
        margin-top: 0;
        padding-top: .25em;
        position: relative;
        width: 60%;

        &::before {
          background-color: color(brand);
          content: '';
          height: 6px;
          left: 0;
          position: absolute;
          top: 0;
          width: 280px;

          .yellow-black & {
            background-color: color(yellow);
          }
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 800px;
  }

  &__translation-links {
    .item-list {
      list-style: none;
      margin: 1.5em 0;
      padding: 0;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 5px;

        a {
          @include link(color(brand), color(brand), color(black));
          @include block-link-focus(0);
          display: block;
          font-family: $inter-ui;
          font-size: em(14px);
          font-weight: $font-weight-medium;
          padding: 0 5px;
          text-decoration: underline;
          text-transform: uppercase;
          @include respond-to(xs) {
            font-size: em(16px);
          }
        }
      }
    }
  }
}

.advanced-content-section {
  padding-top: 2em;
  @include respond-to(l) {
    padding-top: 4em;
  }

  &__menu-link {
    &:focus-visible {
      outline: none;
    }
  }

  &__title {
    line-height: 1;
    margin: 0 0 1em;
    position: relative;
    @include respond-to(l) {
      font-size: em(45px);
    }

    &::before {
      background-color: color(brand);
      content: '';
      display: block;
      height: 4px;
      margin: 0 0 .5em;
      width: 40%;
      @include respond-to(m) {
        height: 6px;
        left: 0;
        position: absolute;
        top: 50%;
        width: 100%;
      }

      .yellow-black & {
        background-color: color(yellow);
      }
    }

    span {
      background-color: color(white);
      line-height: 1.2;
      padding: 0 20px 0 0;
      position: relative;
      z-index: 1;

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
      }
    }
  }

  &__body {
    @include fluid-type(18px, 20px);
  }

  &__attachments {
    @extend %group-attachments;
  }

  &__logos-section {
    .logo {
      display: inline-block;
      margin: 0 0 20px;
      vertical-align: middle;

      img {
        display: block;
        margin: 10px;
        max-height: 60px;
        width: auto;
      }
    }
  }
}
