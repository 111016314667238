.field-name-body,
.field-body,
.sections,
.advanced-content-section__body,
.node-a11y {
  a {
    @include link(color(brand), color(brand), color(black));
    font-weight: $font-weight-regular;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
