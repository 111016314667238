.region-bottom {
  background-color: color(black);
  color: color(white);
  padding-bottom: 50px;
  padding-top: 1em;
  min-height: 110px;
  @include respond-to(xl) {
    min-height: 90px;
    padding-bottom: 0;
  }
  @media print {
    display: none;
  }

  .yellow-black & {
    color: color(yellow);
  }

  &__content {
    p {
      font-size: em(14px);
      margin-bottom: 0;
      margin-top: 0;
    }

    a {
      @include link(color(white), color(white), color(grey-light));
      font-weight: $font-weight-regular;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .menu {
      list-style: none;
      margin: 0 0 1em;
      padding: 0;
      @include respond-to(l) {
        float: right;
        margin-bottom: 0;
        text-align: right;
      }

      li {
        display: inline-block;
        font-size: em(14px);
        margin: 8px 8px 8px 0;
        @include respond-to(l) {
          margin: 0 5px;
        }
      }

      a {
        &.active {
          text-decoration: line-through;
        }
      }

      // prevent displaying second level
      ul {
        display: none;
      }
    }

    .block-menu {
      @include respond-to(l) {
        float: right;
        max-width: calc(100% - 300px);
      }
    }

    .copyright {
      margin-bottom: 1em;
      @include respond-to(l) {
        float: left;
        margin-bottom: 0;
      }
    }
  }
}
