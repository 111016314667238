.page-file {
  .file-image {
    margin-bottom: 40px;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}
