// This page is displayed only when user click "open element in new tab/window"
// kalendarium/miesiac/YYYY-MM-DD
.page-kalendarium {
  .calendar-day {
    padding-top: 2em;
    &__header {
      h2 {
        @include fluid-type(32px, 48px);
        color: color(brand);
        text-align: center;
      }
    }
    &__result {
      text-align: center;
      .views-row {
        margin-top: 20px;
        text-align: left;
        @include zen-respond-to(m) {
          display: inline-block;
          width: calc(50% - 4px);
          vertical-align: top;
        }
        @include zen-respond-to(xl) {
          width: calc(40% - 4px);
        }
        .views-row__content {
          @include zen-respond-to(m) {
            margin: 0 20px;
          }
        }
      }
    }
    .spektakl {
      .image__wydarzenie {
        display: none;
      }
    }
    .image {
      display: inline-block;
      max-width: 360px;
      vertical-align: bottom;
      @include zen-respond-to(m) {
        margin-right: 1em;
      }
      a {
        @include block-link-focus;
      }
      img {
        display: block;
      }
    }
    .links {
      margin-top: 20px;
      @include zen-respond-to(m) {
        display: inline-block;
        vertical-align: bottom;
      }
      @include zen-respond-to(xxxl) {
        margin-top: 0;
      }
    }
    .read-more {
      display: inline-block;
      vertical-align: middle;
      @include zen-respond-to(m) {
        display: block;
      }
      a {
        @include link-button();
        font-size: em(14px);
        font-family: $inter-ui;
        text-transform: uppercase;
        @include zen-respond-to(m) {
          display: block;
        }
      }
    }
    .reservation {
      display: inline-block;
      vertical-align: middle;
      @include zen-respond-to(m) {
        display: block;
        margin-top: 20px;
      }
      a {
        @include link-button(color(white), color(brand), color(brand), color(white));
        font-size: em(14px);
        text-transform: lowercase;
        @include zen-respond-to(m) {
          font-size: em(21px);
          display: block;
        }
      }
    }
    .premiere {
      color: color(brand);
      display: block;
      font-size: .75em;
      line-height: 1;
    }
    .title {
      @include fluid-type(24px, 36px);
      margin: .5em 0 .5em 10px;
      text-align: left;
      text-transform: uppercase;
      @include zen-respond-to(m) {
        margin-bottom: 0;
        margin-top: 40px;
        min-height: 90px;
      }
      a {
        color: color(black);
        text-decoration: none;
      }
    }
    .info {
      background-color: color(grey-extra-light);
      padding: 10px;
      span {
        display: inline-block;
      }
      .date {
        @include typeface(heading);
        font-size: em(20px);
        @include zen-respond-to(m) {
          font-size: em(30px);
        }
      }
      .underscore {
        background-color: color(brand);
        display: inline-block;
        height: 2px;
        width: 20px;
        @include zen-respond-to(m) {
          height: 6px;
          margin: 0 6px;
          width: 50px;
        }
      }
      .pipe {
        background-color: color(black);
        height: 36px;
        margin: 0 6px -12px;
        width: 1px;
        @include zen-respond-to(m) {
          height: 56px;
          margin: 0 12px -12px;
        }
      }
      .day {
        font-size: em(18px);
        @include zen-respond-to(m) {
          font-size: em(24px);
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
      .hour {
        font-size: em(18px);
        font-family: $inter-ui;
        font-weight: $font-weight-regular;
        @include zen-respond-to(m) {
          font-size: em(24px);
        }
      }
      .stage {
        font-size: em(16px);
        display: block;
        font-weight: $font-weight-bold;
        margin-top: 10px;
        text-transform: uppercase;
        @include zen-respond-to(m) {
          font-size: em(19px);
        }
        @include zen-respond-to(xxxxl) {
          display: inline-block;
          margin-top: 0;
          max-width: 240px;
        }
      }
    }
    &__footer {
      display: none;
    }
  }

  &.yellow-black {
    .calendar-day {
      &__header {
        h2 {
          color: color(yellow);
        }
      }
      .read-more,
      .reservation {
        a {
          @include link-button(color(yellow), color(text-bg-contrast-yb), color(text-bg-contrast-yb), color(yellow), color(yellow), color(yellow));
        }
      }
      .premiere {
        color: color(yellow);
      }
      .title {
        a {
          color: color(yellow);
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .info {
        background-color: color(text-bg-contrast-yb);
        .underscore {
          background-color: color(yellow);
        }
        .pipe {
          background-color: color(yellow);
        }
      }
    }
  }
}
