.go-to-list {
  margin-top: 2em;
  text-align: right;

  a {
    @include link(color(brand), color(brand), color(black));
    font-size: em(18px);
    border-bottom: 4px solid color(brand);
    font-weight: $font-weight-semi-bold;
    padding-bottom: 2px;
    text-decoration: none;

    .yellow-black & {
      border-color: color(yellow);
    }

    &:hover {
      border-color: color(black);

      .yellow-black & {
        border-color: color(yellow);
        text-decoration: none;
      }
    }
  }
}
