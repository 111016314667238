// The height of the navigation container.
// $layout-swap-height: 3 * $base-line-height;
$layout-swap-height: 60px;

// Layout swap
//
// Uses absolute positioning and selective padding to make 2 elements appear to
// swap places in the visual order while maintaining their original HTML source
// order.
//
// Markup: layout-swap.twig
//
// Style guide: layouts.layout-swap

.layout-swap,
%layout-swap {
  position: relative;

  // Move all the children down to make room for the "top" element.
  padding-top: ($layout-swap-height / 3) * 2;
  @include zen-respond-to(l) {
    padding-top: $layout-swap-height;
  }

  .front & {
    padding-top: 0;
    @include zen-respond-to(l) {
      padding-top: $layout-swap-height;
    }
  }

  &__bottom {
    @include zen-respond-to(l) {
      margin-top: -($layout-swap-height / 3);

      .front & {
        margin-top: -(($layout-swap-height * 2) / 3);
      }
    }
  }

  &__top {
    position: absolute;
    top: 0;
    height: ($layout-swap-height / 3) * 2;
    width: 100%;
    @include zen-respond-to(l) {
      height: $layout-swap-height;
    }

    .front & {
      height: 0;
      @include zen-respond-to(l) {
        height: $layout-swap-height;
      }
    }
  }
}
