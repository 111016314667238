.node-frontpage {
  .field-name-body {
    border: 2px solid color(blue);
    margin-bottom: 1em;
    padding: 0 1em;
    .yellow-black & {
      border-color: color(yellow);
    }
  }
}
