.region-header-section-third {
  display: none;
  @include zen-respond-to(l) {
    display: block;
    position: absolute;
    right: 160px;
    top: 5px;
    z-index: 10;
    @include rtl() {
      left: 160px;
      right: auto;
    }
  }
  @include breakpoint(1366px) {
    position: fixed;
    right: calc((50% - (#{map-get($max-width, xl)} / 2)) / 2);
    top: 50%;
    transform: translate(50%, -50%);
    @include rtl() {
      left: calc((50% - (#{map-get($max-width, xl)} / 2)) / 2);
      right: auto;
      transform: translateX(-50%);
    }
  }
  @include zen-respond-to(xxl) {
    right: calc((50% - (#{map-get($max-width, xxl)} / 2)) / 2);
    @include rtl() {
      left: calc((50% - (#{map-get($max-width, xxl)} / 2)) / 2);
      right: auto;
    }
  }
  @include zen-respond-to(xxxl) {
    right: calc((50% - (#{map-get($max-width, xxxl)} / 2)) / 2);
    @include rtl() {
      left: calc((50% - (#{map-get($max-width, xxxl)} / 2)) / 2);
      right: auto;
    }
  }
  @include zen-respond-to(xxxxl) {
    right: calc((50% - (#{map-get($max-width, xxxxl)} / 2)) / 2);
    @include rtl() {
      left: calc((50% - (#{map-get($max-width, xxxxl)} / 2)) / 2);
      right: auto;
    }
  }

  .yellow-black & {
    background-color: color(text-bg-contrast-yb);
  }

  .tz-wcag-block-toggle {
    height: 40px;
    width: 40px;
    @include breakpoint(1366px) {
      display: none;
      height: 50px;
      width: 50px;
    }

    button {
      @include block-link-focus;
      background-color: transparent;
      background-image: url('../images/accessibility-icon.svg');
      background-position: center 0;
      background-repeat: no-repeat;
      background-size: 40px auto;
      border: 0;
      display: block;
      height: 40px;
      width: 40px;
      @include breakpoint(1366px) {
        background-size: 50px auto;
        height: 50px;
        width: 50px;
      }

      &:hover {
        background-position: center -40px;
        @include breakpoint(1366px) {
          background-position: center -50px;
        }
      }

      .yellow-black & {
        background-position: center -80px;
        @include breakpoint(1366px) {
          background-position: center -100px;
        }
      }
    }
  }

  .tz-wcag-block-content {
    display: none;
    @include breakpoint(1366px) {
      display: block;
      width: auto;
    }

    .block {
      & + .block {
        margin-top: 20px;
      }
    }
  }

  .tz-wcag-block-content-inner {
    padding: 20px 10px;
    @include breakpoint(1366px) {
      padding: 0;
    }
  }

  .block-tz-sign-language-interpreter {
    .content {
      margin-left: 10px;
    }
  }

  // only screen width >= 1280 && < 1366
  // on mobile view 'wcag' blocks are in 'block-superfish'
  &.expanded {
    .tz-wcag-block-content {
      background-color: color(white);
      border: 1px solid color(black);
      display: block;
      position: fixed;
      right: 0;
      top: 52px;
      width: 210px;
      @include rtl() {
        left: 0;
        right: auto;
      }

      .yellow-black & {
        background-color: color(text-bg-contrast-yb);
        border-color: color(yellow);
      }
    }
  }

  // only screen width >= 1366
  &.sticky {
    right: 10px;
    transform: translateY(-50%);
    @include rtl() {
      left: 10px;
      right: auto;
      transform: translateY(-50%);
    }

    .tz-wcag-block-toggle {
      display: block;
    }

    .tz-wcag-block-content {
      display: none;
    }
  }
}
