// Skip link
//
// To make the link completely hidden until a user tabs to the link, combine it
// with the `visually-hidden` component.
//
// :focus - Focus styling.
//
// Markup: skip-link.twig
//
// Style guide: navigation.skip-link

.skip-link,
%skip-link {
  display: block;
  padding: .25em 0;
  text-align: center;

  &:link,
  &:visited {
    background-color: color(skip-link-bg);
    color: color(skip-link);
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    span {
      outline: 2px solid color(white);

      .yellow-black & {
        outline-color: color(yellow);
      }
    }

  }

  span {
    display: inline-block;
    font-size: em(20px);
    font-weight: $font-weight-semi-bold;
    padding: .25em .5em 0;
    text-transform: uppercase;
  }

  &__wrapper {
    margin: 0;

    &:focus-within {
      background-color: color(skip-link-bg);
      padding: .25em 0;
    }

    @media print {
      display: none;
    }
  }
}
